const colors = {
  'reguard-success': '#01bd94',
  'reguard-error': '#d33c57',
  'reguard-inactive1': '#95a1b4',
  'reguard-inactive2': '#dbe1ea',
  'reguard-wintergreen': '#01bd94',
  'reguard-wintergreen-shade': '#008575',
  'reguard-wintergreen-tint': '#6fd9be',
  'reguard-indigo': '#1e2547',
  'reguard-indigo-shade': '#141c29',
  'reguard-indigo-tint': '#4b506d',
  'reguard-violet': '#4757d1',
  'reguard-violet-tint': '#a1a9e5',
  'reguard-pearl': '#e1e9f5',
  'reguard-pearl-shade': '#c5ccd6',
  'reguard-pearl-tint': '#f7f9fc',
  'reguard-onyx': '#1E2547',
  'reguard-slate': '#242f40',
  'reguard-gray': '#F7F9FC',
  'reguard-darkgray': '#DBE1EA',
  'reguard-orange': '#ED7D2B',
};

export default colors;
