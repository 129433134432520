import { endpointConstructor } from '../index.js';

export type PlansEndpoints = ReturnType<typeof plansEndpoints>;

const plansEndpoints = () => {
  const { endpoint } = endpointConstructor('/plans');
  return {
    getPlans: endpoint('GET'),
    getPlansForMerchant: endpoint('GET', '/merchant'),
    getPlanById: endpoint('GET', '/:id'),
    getPdfTermsBySku: endpoint('GET', '/terms/:sku'),
  };
};

export default plansEndpoints;
