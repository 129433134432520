import { actions } from './sections/actions';
import { errors } from './sections/errors';
import { navigation } from './sections/navigation';

export const es = {
  errors,
  navigation,
  actions,
  'sign_in': 'Iniciar sesión',
  'related': 'relacionado',

  'merchants_contracts': 'Contratos de Comerciantes',
  'merchants_contracts_description':
    'Cree y monitoree contratos ingresados manualmente',
  'merchants_contracts_add': 'Agregar contrato',
  'merchants_contracts_add_description': 'Agregar un nuevo contrato',
  'your_cost': 'Su costo',

  'WRONG_LOGIN':
    'Su correo electrónico o contraseña es incorrecto, por favor intente de nuevo',
  'INVALID_EMAIL':
    'Por favor ingrese su correo electrónico en el formato correcto',
  'BLANK_PASSWORD': 'Se requiere contraseña',
  'MISSING_EMAIL':
    'Por favor ingrese el mismo correo que utilizó al momento de la compra',
  'INVALID_CODE': 'Código de verificación inválido',
  'INVALID_NAME': 'El nombre debe tener al menos 3 caracteres',
  'BLANK_ORDER': 'Se requiere el número de la orden',
  'INVALID_PHONE':
    'Por favor ingrese el número telefónico en el formato correcto',
  'BLANK_REPEAT_PASSWORD': 'Por favor ingrese la nueva contraseña nuevamente',
  'PASSWORDS_MISMATCH': 'Por favor asegúrese que sus contraseñas coincidan',
  'INVALID_NEW_PASSWORD':
    'Su contraseña debe tener al menos 8 caracteres y contener un caracter de cada uno de los siguientes: 1. Mayúscula (A-Z) 2. Minúscula (a-z) 3. Número (0-9) 4. Especial (!, %, @, #, etc.)',
  'CLAIM_BEFORE_ACTIVATION':
    'Desafortunadamente, este daño ocurrió antes de que se activara su plan de protección, Póngase en contacto con el servicio de atención al cliente si cree que se trata de un error.',
  'ERROR_SUBMITTING_CLAIM': 'Error al enviar reclamo',
  'INVALID_DAMAGE_DATE': 'La fecha del daño no puede ser en el futuro',
  'BLANK_DAMAGE_DATE': 'Se requiere la fecha del daño',
  'BLANK_DAMAGE_AREA': 'Se requiere el área dañada',
  'BLANK_DAMAGE_LOCATION': 'Se requiere la ubicación del daño',
  'INVALID_DAMAGE_DESCRIPTION':
    'La descripción del daño debe contener al menos 25 caracteres',
  'BLANK_DAMAGE_DESCRIPTION': 'Se requiere la descripción del daño',
  'T00_FEW_IMAGES': 'Por favor subir un mínimo de 5 imágenes',
  'T00_MANY_IMAGES': 'Intentó subir mas de 12 imágenes',
  'BLANK_ADDRESS': 'Se requiere la primera línea de la dirección',
  'LEADING_SPACES_ADDRESS': 'No se permiten espacios al inicio',
  'LEADING_SPACES_CITY': 'No se permiten espacios al inicio',
  'BLANK_POSTAL_CODE': 'Se requiere el código postal',
  'TOO_FEW_ZIP': 'El código postal debe contener 5 dígitos',
  'INVALID_ZIP': 'Por favor ingrese un código postal válido',
  'ZIP_STATE_MISMATCH': 'El código postal no corresponde al estado',
  'INVALID_CURRENT_PASSWORD':
    'Algo salió mal al cambiar la contraseña: Contraseña inválida',
  'EQUAL_PASSWORDS':
    'Algo salió mal al cambiar la contraseña: La nueva contraseña debe ser diferente a la anterior',
  'BLANK_TEMPORARY_PASSWORD': 'Se requiere una contraseña temporal',
  'INVALID_TEMPORARY_PASSWORD':
    'Por favor ingrese una contraseña temporal válida',
  'BLANK_NEW_PASSWORD': 'Se requiere una nueva contraseña',
  'TOO_MANY_REQUESTS': 'Demasiados intentos para este usuario',
  'PRODUCT_NOT_LISTED_HERE': 'No veo mi producto en la lista',
  'FILE_CLAIM': 'Presentar una reclamación',
  'CHECK_CLAIM_STATUS': 'Verificar el estado de la reclamación',
  'GENERAL_QUESTION': 'Pregunta general',
  'NO_PRODUCT_PURCHASES': 'Sin compras de productos',
  'USER_NOT_SET': 'Usuario no configurado',
  'NO_MATCHING_PRODUCT': 'No hay productos coincidentes',
  'YES': 'Sí',
  'NO': 'No',
  'GOOD_BYE': '¡Adiós!',
  'FAQ': 'Preguntas frecuentes',
  'CUSTOMER_SERVICE': 'Servicio al cliente',
  'PROVIDED_NAME_NOT_MATCHES_OUR_RECORDS':
    'El nombre proporcionado no coincide con nuestros registros.',
  'SORRY_PROVIDED_CODE_NOT_MATCHES_OUR_RECORDS':
    'Lo siento, el código que proporcionaste no coincide con nuestros registros.',
  'PLEASE_ENTER_SIX_DIGIT_CODE_SENT_TO_YOU':
    'Ingresa el código de 6 dígitos que se te envió.',
  'NOT_SET_BOT_AUTHTOKEN':
    'No se pudo establecer el token de autenticación del bot. Error:',
  'USER_KEY_CANNOT_BE_NULL': 'La clave de usuario no puede ser nula',
  'ERROR_WHILE_VERIFYING_CODE': 'Error al verificar el código:',
  'NOT_RETRIEVE_USER': 'No se pudo recuperar al usuario:',
  'REACHOUT_TO_CUSTOMER_SERVICE_FOR_MORE_ASSISTANCE':
    'Comunícate con el servicio al cliente para obtener más ayuda.',
  'ERROR_FINDING_USER_BY_EMAIL':
    'Error al encontrar al usuario por correo electrónico. correo electrónico',
  'INVALID_EMAIL_OR_PASSWORD': 'Correo electrónico o contraseña incorrectos.',
  'ERROR_FINDING_USER_BY_AUTH0ID_USERID':
    'Error al encontrar al usuario por ID de Auth0. ID de usuario',
  'NO_SESSION': 'No hay sesión',
  'ERROR_FINDING_USER_BY_EMAIL_OR_PHONE':
    'Error al encontrar al usuario por correo electrónico o número de teléfono. argumentos',
  'INVALID_CREDENTIALS': 'Credenciales incorrectas',
  'ERROR_FINDING_CUSTOMER_BY_ID':
    'Error al encontrar al cliente por ID. ID de cliente',
  'ERROR_WHILE_RESETTING_VERIFICATION':
    'Error al restablecer la verificación. customerWithVerification?.id',
  'INVALID_USER': '¡No se puede encontrar al usuario!',
  'INVALID_USER_ROLE': '¡No se puede encontrar el rol del usuario!',
  'NOT_AUTHENTICATED': 'No autenticado',
  'SOMETHING_WRONG_WHILE_LOGGING_OUT_USER':
    'Algo salió mal al cerrar sesión del usuario:',
  'ERROR_FINDING_CONTRACT': 'Error al encontrar el contrato. argumentos',
  'INVALID_CONTRACT_INFO': 'Información de contrato no válida',
  'ERROR_FINDING_USER_BY_CUSTOMERID':
    'Error al encontrar al usuario por ID de cliente. ID de cliente',
  'ERROR_FINDING_ORDER_BY_ORDER_NUMBER':
    'Error al encontrar el pedido por número de pedido. número de pedido',
  'INVALID_CUSTOMER': 'Cliente no encontrado',
  'CUSTOMER_EMAIL_EXISTS': '¡El cliente ya tiene un correo electrónico!',
  'SOMETHING_WRONG_WHEN_SENDING_EMAIL_VERIFICATION_CODE':
    'Algo salió mal al enviar el código de verificación por correo electrónico:',
  'SOMETHING_WRONG_WHEN_SENDING_SMS_VERIFICATION_CODE':
    'Algo salió mal al enviar el código de verificación por SMS:',
  'EMAIL_SENT': 'Correo electrónico enviado',
  'USER_NOT_EXIST': 'Usuario no encontrado',
  'USER_EMAIL_EXISTS': 'El correo electrónico del usuario ya existe',
  'USER_NOT_FOUND_OR_NOT_FIND_USER_ROLE':
    'Usuario no encontrado o no se puede encontrar el rol del usuario',
  'SOMETHING_WRONG_WHILE_LOGGING_IN_MERCHANT':
    'Algo salió mal al iniciar sesión en el comerciante',
  'SOMETHING_WRONG_LOGGING_OUT_USER':
    'Algo salió mal al cerrar sesión del usuario',
  'FAILED_GETTING_USER_ROLES': 'Error al obtener roles de usuario',
  'UNAUTHORIZED_OR_NO_SESSION': 'No autorizado o sin sesión',
  'ERROR_FETCHING_USER_BY_EMAIL':
    'Error al obtener al usuario por correo electrónico. correo electrónico:',
  'EMAIL_OR_PHONE_REQUIRED':
    'Se necesita al menos el correo electrónico o el número de teléfono',
  'SOMETHING_WRONG_WHILE_GETTING_USER_BY_EMAIL_OR_PHONE':
    'Algo salió mal al obtener al usuario por correo electrónico o número de teléfono:',
  'ERROR_VALIDATING_PHONE':
    'Error al validar el número de teléfono. número de teléfono:',
  'ERROR_SENDING_SMS': 'Error al enviar SMS. número de teléfono:',
  'BLANK_VERIFIACTION_CODE': '¡Código de verificación faltante!',
  'ERROR_FINDING_CUSTOMER_BY_EMAIL':
    'Error al encontrar al cliente por correo electrónico. correo electrónico:',
  'ERROR_REQUESTING_VERIFICATION_BY_EMAIL':
    'Error al solicitar la verificación por correo electrónico. correo electrónico:',
  'ERROR_SENDING_VERIFICATION_EMAIL_SMS':
    'Error al enviar el mensaje de correo electrónico de verificación. correo electrónico:',
  'ERROR_FINDING_CUSTOMER_BY_EMAIL_WITH_VERIFICATION':
    'Error al encontrar al cliente por correo electrónico con verificación. correo electrónico:',
  'BLANK_CONTRACT_ID_PARAM': '¡Falta el parámetro ID de contrato!',
  'ERROR_FINDING_CONTRACT_BY_CONTRACT_ID':
    'Error al encontrar el contrato por ID de contrato. ID de contrato:',
  'BLANK_CLAIM_ID': '¡Falta el ID de reclamación!',
  'ERROR_FINDING_CLAIM_BY_PCMI_CLAIM_ID':
    'Error al encontrar la reclamación por ID de reclamación de PCMI. ID de reclamación:',
  'USERID_REQUIRED': 'El ID de usuario es obligatorio',
  'ERROR_GETTING_CUSTOMER_ADDRESS_BY_USERID':
    'Error al obtener la dirección del cliente por ID de usuario. ID de usuario:',
  'ERROR_FINDING_CLAIM_BY_ID':
    'Error al obtener la reclamación por ID. ID de reclamación:',
  'DRAFT_CLAIM_NOT_FOUND':
    'No se encontró la reclamación en borrador con este ID de reclamación',
  'FAILED_GETTING_CUSTOMER_PURCHASES_BY_ID':
    'Error al obtener las compras del cliente con ID',
  'FAILED_GETTING_CONVERSATIONAL_TOKEN':
    'Error al obtener el token de conversación',
  'CUSTOMER_WITH_EMAIL_NOT_FOUND':
    'No se encontró al cliente con este correo electrónico',
  'SOMETHING_WRONG_WHILE_GETTING_USER_BY_CUSTOMER_KEY':
    'Algo salió mal al encontrar al usuario por clave de cliente.',
  'FAILED_GETTING_CUSTOMER_PURCHASES_FOR_CUSTOMERID':
    'Error al obtener las compras del cliente para este ID de cliente.',
  'MISSING_UNCHANGED_OR_INVALID_PHONE':
    'Número de teléfono faltante, sin cambios o inválido.',
  'USER_WITH_PHONE_NOT_EXIST':
    'No se encontró al usuario con este número de teléfono.',
  'SOMETHING_WRONG_SENDING_SMS_OR_REQUESTING_PHONE_CHANGE_FROM_BOT':
    'Algo salió mal al enviar el SMS o al solicitar un cambio de número de teléfono desde el chatbot.',
  'MISSING_OR_UNCHANGED_EMAIL': 'Correo electrónico faltante o sin cambios.',
  'USER_WITH_EMAIL_NOT_EXIST':
    'No se encontró al usuario con este correo electrónico.',
  'SOMETHING_WRONG_SENDING_EMAIL_CODE':
    'Algo salió mal al enviar el código de correo electrónico.',
  'MISSING_OR_EXPIRED_CODE': 'Código faltante o EXPIRADO.',
  'USER_NOT_FOUND_OR_USER_WITH_PHONE_NOT_EXIST_OR_CHANGE_REQUEST_NOT_FOUND':
    'Usuario no encontrado o usuario con este número de teléfono no encontrado o solicitud de cambio no encontrada.',
  'SOMETHING_WRONG_WHILE_VERIFYING_PHONE_CODE':
    'Algo salió mal al verificar el código de teléfono.',
  'ERROR_WHILE_FINDING_CLAIM_BY_PCMI_CLAIM_ID':
    'Error al encontrar la reclamación por ID de reclamación de PCMI.',
  'CALLER_MUST_BE_MERCHANT': '¡El solicitante debe ser un comerciante!',
  'MISSING_REQUIRED_FIELDS': 'Campos requeridos faltantes.',
  'ERROR_FETCHING_MERCHANT_BY_PCMI_DEALER_GROUP':
    'Error al obtener el comerciante por pcmiDealerGroup. solicitante:',
  'ERROR_LOADING_CONTRACTS': 'Error al cargar los contratos.',
  'ERROR_GENERATING_ERROR_FILE': 'Error al generar el archivo de error.',
  'INVALID_PARAMETERS': 'Parámetros no válidos.',
  'ERROR_GETTING_PURCHASE_BY_ORDER_NUMBER_AND_MERCHANT':
    'Error al obtener la compra por número de pedido y comerciante. información:',
  'INVALID_ORDER': '¡Pedido no encontrado!',
  'SOMETHING_WRONG_CREATING_CONTRACTS_AND_ORDERS':
    'Algo salió mal al crear el contrato y los pedidos.',
  'SOMETHING_WRONG_WHILE_FETCHING_CONTRACTS_AND_ORDERS':
    'Algo salió mal al obtener el contrato y los pedidos.',
  'SOMETHING_WRONG_WHILE_GETTING_CLAIMS':
    'Algo salió mal al obtener las reclamaciones:',
  'CLAIM_NOT_FOUND': 'Reclamación no encontrada:',
  'ERROR_GETTING_CLAIM_BY_ID_FROM_DB':
    'Error al obtener la reclamación por ID de reclamación de la base de datos. ID de reclamación:',
  'ERROR_WHILE_MAPPING': 'Error al mapear.',
  'ERROR_WHILE_FETCHING_CUSTOMER_BY_ID_FROM_DB':
    'al obtener el cliente por ID de la base de datos. ID de cliente:',
  'ERROR_WHILE_LOADING_PRODUCT_PURCHASE_FROM_DB':
    'Error al cargar la compra del producto desde la base de datos. compraDeProducto:',
  'ERROR_WHILE_LOADING_DAMAGE_CAUSE_FROM_DB':
    'Error al cargar la causa del daño desde la base de datos. causaDelDaño:',
  'ERROR_WHILE_LOADING_DAMAGE_LOCATION_FROM_DB':
    'Error al cargar la ubicación del daño desde la base de datos. ubicaciónDelDaño:',
  'ERROR_WHILE_LOADING_DAMAGE_AREA_FROM_DB':
    'Error al cargar el área del daño desde la base de datos. áreaDelDaño:',
  'ERROR_WHILE_LOADING_ADDRESS_FROM_DB':
    'Error al cargar la dirección desde la base de datos. dirección:',
  'ERROR_WHILE_SAVING_CLAIM': 'Error al guardar la reclamación. reclamación:',
  'ERROR_WHILE_MAPPING_RESPONSE_FIELDS':
    'Error al mapear los campos de respuesta.',
  'CLAIM_NOT_EXIST_UNDER_DRAFT_STATUS':
    '¡La reclamación no está en estado de borrador!',
  'DRAFT_CLAIM_IS_INCOMPLETE': '¡La reclamación en borrador está incompleta!',
  'ERROR_FETCHING_ACTIVE_CLAIMS_COUNTS_FROM_DB':
    'Error al obtener el recuento de reclamaciones activas desde la base de datos.',
  'ANOTHER_PLAN_EXIST_FOR_SAME_PRODUCT':
    '¡Se encontró otra reclamación activa para el mismo producto!',
  'ERROR_WHILE_FETCHING_USER_BY_ID':
    'Error al obtener el usuario por ID. ID de usuario:',
  'ERROR_COMMUNICATING_WITH_PCMI_OR_PCRS':
    'Error de comunicación con PCMI/PCRS. Por favor, contacta al soporte.',
  'CLAIM_NOT_FOUND_BY_ID':
    'No se pudo obtener la reclamación por ID. ID de reclamación:',
  'ADD_CLAIM_PHOTOS': 'addClaimPhotos: No hay fotos para guardar.',
  'ERROR_FETCHING_CUSTOMER_BY_ID':
    'Error al encontrar al cliente por ID. ID de usuario:',
  'INVALID_USER_CUSTOMER': '¡Cliente de usuario no encontrado!',
  'ERROR_FETCHING_DRAFT_CLAIM':
    'Error al obtener la reclamación en borrador. ID de reclamación:',
  'ERROR_SAVING_CLAIM_OR_UPLOADING_OR_SAVING_PHOTOS':
    'Error al guardar la reclamación o subir/guardar fotos.',
  'SOMETHING_WRONG_WHEN_SAVING_THE_CLAIM_FOR_THIS_CUSTOMER_ID':
    'Algo salió mal al guardar la reclamación para este ID de cliente y este ID de reclamación.',
  'CLAIM_OR_USER_NOT_FOUND': 'Reclamación o usuario no encontrado.',
  'SOMETHING_WRONG_WHEN_SUBMITTING_CLAIM_FOR_THIS_CUSTOMER_ID':
    'Algo salió mal al enviar la reclamación para este ID de cliente y este ID de reclamación.',
  'ERROR_UPLOADING_OR_SAVING_CLAIM_PHOTOS':
    'Error al cargar/guardar las fotos de la reclamación.',
  'SOMETHING_WRONG_SAVING_CLAIM_PHOTOS_FOR_CUSTOMER_AND_CLAIM_ID':
    'Algo salió mal al guardar las fotos de la reclamación para este ID de cliente y este ID de reclamación.',
  'MISSING_CUSTOMER_OR_MERCHANT_ID': 'Faltan los ID de cliente o comerciante.',
  'SOMETHING_WRONG_WHILE_GETTING_CLAIM_BY_ID':
    'Algo salió mal al obtener la reclamación por este ID.',
  'DRAFT_WITH_CLAIM_ID_FOR_CUSTOMER_NOT_FOUND_OR_INVALID_CUSTOMER':
    'No se encontró el borrador con este ID de reclamación para este cliente o no se encontró el cliente.',
  'SOMETHING_WRONG_WHILE_REMOVING_DRAFT_CLAIM_FOR_CLAIM_ID':
    'Algo salió mal al eliminar el borrador de reclamación para este ID de reclamación.',
  'MISSING_CUSTOMER_Id': '¡ID de cliente faltante!',
  'SOMETHING_WRONG_FETCHING_CONTRACTS_FOR_CUSTOMER_Id':
    'Algo salió mal al obtener los contratos para el ID de cliente:',
  'SOMETHING_WRONG_GETTING_CONTRACTS_FOR_CUSTOMER_ID':
    'Algo salió mal al obtener los contratos para este ID de cliente.',
  'DOMAIN_TYPE_REQUIRED': '¡El tipo de dominio es obligatorio!',
  'DOMAIN_TYPE_NOT_ALLOWED':
    'Tipo de dominio no permitido. Dominios disponibles:',
  'ERROR_GETTING_DAMAGE_CAUSES': 'Error al obtener las causas de daño.',
  'PRODUCT_PURCHASE_ID_OR_DRAFT_CLAIM_ID_REQUIRED_FOR_DAMAGE_CAUSE':
    '¡Se requiere el ID de compra de producto o el ID de reclamación en borrador para la causa del daño!',
  'DOMAIN_TYPE_REQUIRED_OR_NOT_ALLOWED_OR_PRODUCT_PURCHASE_ID_OR_DRAFT_CLAIM_ID_REQUIRED_FOR_DAMAGE_CAUSE':
    'Se requiere el tipo de dominio o no está permitido o se requiere el ID de compra de producto o el ID de reclamación en borrador para la causa del daño.',
  'FAILED_GETTING_TRANSLATION_FILE':
    'No se pudo obtener el archivo de traducción.',
  'FAILED_GETTING_TRANSLATION_FILE_DATA':
    'No se pudo obtener los datos del archivo de traducción.',
  'ERROR_FINDING_MERCHANT_BY_MERCHANT_ID':
    'Error al encontrar el comerciante por ID de comerciante. ID de comerciante:',
  'MERCHANT_NOT_EXIST': 'El comerciante no existe.',
  'ERROR_SAVING_MERCHANT': 'Error al guardar el comerciante. comerciante:',
  'ERROR_FETCHING_MERCHANT': 'Error al encontrar los comerciantes.',
  'DATABASE_ERROR_WHILE_GETTING_ALL_MERCHANT':
    'Error de la base de datos al obtener todos los comerciantes.',
  'USER_IS_NOT_LINKED_TO_CUSTOMER':
    'El usuario no está vinculado a un cliente.',
  'MERCHANT_ID_REQUIRED': '¡Se requieren los ID de comerciante!',
  'ERROR_WHILE_FETCHING_PLANS_FOR_MERCHANT':
    'Error al obtener los planes para el comerciante. ID de comerciante:',
  'PLAN_NOT_FOUND': '¡Plan no encontrado!',
  'ERROR_WHILE_GETTING_PURCHASES_FOR_CUSTOMER':
    'Error al obtener las compras para el ID de cliente. ID de cliente:',
  'ERROR_WHILE_INITIALIZING_MERCHANT_CONFIGURATION':
    'Error al inicializar la configuración del comerciante:',
  'DB_ERROR_GETTING_REPORTS_CONTRACT_DATA':
    'Error de base de datos al obtener los datos de los contratos de informes.',
  'INVALID_TOKEN': '¡Token no válido!',
  'NO_MERCHANT_LINKED_TO_THIS_USER':
    'No hay comerciantes vinculados a este usuario.',
  'ERROR_FINDING_MERCHANT_BY_ID':
    'Error al encontrar los comerciantes por IDs. IDs de comerciante:',
  'ERROR_FINDING_TALENT_USER_BY_EMAIL':
    'Error al encontrar el usuario de talento por correo electrónico. correo electrónico:',
  'SOMETHING_WRONG_CREATING_USER_IN_TALENTLMS':
    'Algo salió mal al crear el usuario en TalentLMS:',
  'BAD_REQUEST': 'SOLICITUD INCORRECTA',
  'PRODUCT_HAS_STRUCTURAL_DAMAGE': 'El producto tiene daños estructurales.',
  'PRODUCT_HAS_SURFACE_DAMAGE': 'El producto tiene daños en la superficie.',
  'PRODUCT_HAS_COSMETIC_DAMAGE': 'El producto solo tiene daños cosméticos.',
  'PRODUCT_WORK_SOMETIMES_BECAUSE_OF_DAMAGE':
    'El producto funciona solo a veces debido a daños.',
  'PRODUCT_HAS_DAMAGE_FROM_ACCUMULATION':
    'El producto tiene daños por acumulación.',
  'PRODUCT_HAS_DAMAGE_BECAUSE_OF_PREV_MODIFICATION':
    'El producto tiene daños debido a modificaciones anteriores.',
  'USER_WITH_SAME_EMAIL_ALREADY_EXIST':
    '¡Ya existe un usuario con el mismo correo electrónico!',
  'MAKE_SURE_ENTERING_SAME_EMAIL':
    'Asegúrate de ingresar el mismo correo electrónico.',
  'SOMETHING_WRONG_WHILE_GETTING_USER_BY_EMAIL_OR_PHONE_OR_EMAIL_OR_PHONE_REQUIRED':
    'Algo salió mal al obtener al usuario por correo electrónico o número de teléfono: Se necesita al menos el correo electrónico o el número de teléfono.',
  'ADDRESS_NOT_VALID_FOR_CUSTOMER':
    '¡La dirección no es válida para el cliente!',
  'MISSING_ADDRESS_ID': '¡Falta el ID de dirección!',
  'OLD_AND_NEW_PASSWORD_MATCHES':
    '¡La contraseña antigua y la nueva contraseña son iguales!',
  'VENDOR_NOT_FOUND': 'No se pudo encontrar al proveedor.',
  'USER_NOT_EXISTS': 'El usuario no existe.',
  'VERIFICATION_NOT_REQUESTED': '¡Verificación no solicitada!',
  'EXPIRED_CODE': '¡CÓDIGO EXPIRADO!',
  'SELECT_DIFFERENT_EMAIL_ADDRESS':
    '¡Elige una dirección de correo electrónico diferente!',
  'PHONE_NOT_UPDATED': '¡El número de teléfono no ha cambiado!',
  'NO_CUSTOMER_FOUND_FOR_THIS_EMAIL':
    'No se encontró al cliente para este correo electrónico.',
  'ERROR_FETCHING_AUTH0_USER_BY_EMAIL':
    'Error al obtener el usuario de Auth0 por correo electrónico. correo electrónico:',
  'USER_NOT_ALLOWED': 'Usuario no permitido',
  'ERROR_WHILE_SENDING_ACCOUNT_CREATION_EMAIL':
    'Error al enviar el correo electrónico de creación de cuenta. dbUser:',
  'ERROR_FETCHING_MERCHANT_BY_PCMI_PRICING_GROUP':
    'Error al obtener el comerciante por grupo de precios de PCMI. proveedor:',
  'ERROR_CHECKING_IF_USER_ALREADY_EXIST':
    'Error al verificar si el usuario ya existe. usuario:',
  'ERROR_WHILE_FETCHING_USER_FROM_AUTH0':
    'Error al obtener el usuario de Auth0. correo electrónico:',
  'ERROR_WHILE_CREATING_MERCHANT_USER':
    'Error al crear usuario de comerciante. usuario:',
  'ERROR_WHILE_SENDING_MERCHANT_ONBOARDING_EMAIL':
    'Error al enviar el correo electrónico de incorporación de comerciante. correo electrónico:',
  'ERROR_WHILE_RESETTING_CUSTOMER_VERIFICATION':
    'Error al restablecer la verificación del cliente. ID de cliente:',
  'FAILED_TO_CHANGE_PASSWORD': 'Error al cambiar la contraseña',
  'ERROR_RESETTING_VERIFICATION_CODE':
    'Error al restablecer la verificación. correo electrónico:',
  'USER_ERROR': 'Error de usuario',
  'USER_NOT_EXIST_ON_DB':
    'No se puede encontrar al usuario en la base de datos.',
  'ERROR_UPDATING_CURRENT_PASSWORD':
    'Error al cambiar la contraseña actual. nombre de usuario:',
  'ERROR_WHILE_SETTING_VERIFICATION_CODE':
    'Error al establecer el código de verificación. ID de usuario:',
  'ERROR_SENDING_RESET_PASSWORD_EMAIL':
    'Error al enviar el correo electrónico de restablecimiento de contraseña. correo electrónico:',
  'ERROR_CHECKING_IF_CUSTOMER_ADDRESS_ALREADY_EXIST':
    'Error al verificar si ya existe la dirección del cliente. Parámetros:',
  'ERROR_UPDATING_CUSTOMER_ADDRESS':
    'Error al actualizar la dirección del cliente. nuevaDirección:',
  'CUSTOMER_NOT_FOUND_FOR_THIS_USER':
    'No se puede encontrar un cliente para este usuario.',
  'ERROR_FETCHING_CUSTOMER_ADDRESS_BY_ID':
    'Error al obtener la dirección del cliente por ID. ID de cliente:',
  'ADDRESS_EXISTS': 'Dirección ya agregada.',
  'ERROR_ADDING_CUSTOMER_ADDRESS':
    'Error al agregar la dirección del cliente. parámetros:',
  'MISSING_NEW_EMAIL': '¡Falta el nuevo correo electrónico!',
  'ERROR_FETCHING_USER_BY_ID':
    'Error al encontrar el usuario por ID. ID de usuario:',
  'ERROR_WHILE_SETTING_VERIFICATION':
    'Error al establecer el código de verificación. ID de usuario:',
  'EMAIL_NOT_UPDATED': '¡El correo electrónico no ha cambiado!',
  'DB_CHANGE_EMAIL_ERROR':
    'Error al cambiar el correo electrónico en la base de datos.',
  'MISSING_NEW_PHONE': '¡Falta el nuevo número de teléfono!',
  'ERROR_SENDING_SMS_PHONE': 'Error al enviar el SMS. número de teléfono:',
  'ERROR_UPDATING_PHONE_NUMBER':
    'Error al actualizar el número de teléfono. datos:',
  'ADMIN_ROLE_NOT_CONFIGURED': 'El rol de administrador no está configurado.',
  'ADDRESS_NOT_EXIST': 'Dirección no encontrada',
  'SOMETHING_WRONG_WHILE_FETCHING_ADDRESS_ON_MAPBOX':
    'Algo salió mal al buscar la dirección en Mapbox: para ID:',
  'ERROR_FINDING_AUTH0_USER_BY_EMAIL':
    'Error al encontrar el usuario de Auth0 por correo electrónico. correo electrónico:',
  'ERROR_DELETING_USER_BY_EMAIL':
    'Error al eliminar el usuario por correo electrónico. correo electrónico:',
  'ERROR_DELETING_AUTH0_USER_BY_EMAIL':
    'Error al eliminar el usuario de Auth0 por correo electrónico. correo electrónico:',
  'INVALID_CUSTOMER_TOKEN': '¡Token de cliente no válido!',
  'ERROR_FETCHING_CUSTOMER_BY_EMAIL_OR_TOKEN':
    'Error al encontrar el cliente por correo electrónico o token. datos:',
  'ERROR_SENDING_VERIFICATION_EMAIL':
    'Error al enviar el correo electrónico de verificación. correo electrónico:',
  'ERROR_CHECKING_EMAIL_VERIFICATION_TIMING':
    'Error al verificar el momento de verificación por correo electrónico. ID de cliente:',
  'ERROR_UPDATING_EMAIL':
    'Error al actualizar el correo electrónico. ID de cliente:',
  'ERROR_SENDING_VERIFICATION_MESSAGE_EMAIL':
    'Error al enviar el correo electrónico de mensaje de verificación. correo electrónico:',
  'SOMETHING_WRONG_GETTING_ALL_USERS_FROM_DB':
    'Algo salió mal al obtener todos los usuarios de la base de datos',
  'USER_WITH_EMAIL_EXISTS_OR_VERIFICATION_NOT_REQUESTED':
    'Ya existe un usuario con el mismo correo electrónico o no se solicitó la verificación',
  'CUSTOMER_NOT_FOUND_FOR_EMAIL':
    'No se encontró al cliente para este correo electrónico',
  'SOMETHING_WRONG_RESETTING_EMAIL_VERIFICATION_OR_CREATING_USER':
    'Algo salió mal al restablecer la verificación por correo electrónico o al crear un nuevo usuario',
  'SOMETHING_WRONG_SETTING_PASSWORD':
    'Algo salió mal al establecer la contraseña',
  'SOMETHING_WRONG_SETTING_MERCHANT_PASSWORD':
    'Algo salió mal al establecer la contraseña del comerciante',
  'INTERNAL_SERVER_ERROR_SENDING_EMAIL':
    'Error interno del servidor al enviar el correo electrónico',
  'SOMETHING_WRONG_CHANGING_PASSWORD':
    'Algo salió mal al cambiar la contraseña',
  'INVALID_ADDRESS_FOR_CUSTOMER': 'Dirección no válida para el cliente',
  'SOMETHING_WRONG_UPDATING_CUSTOMER_ADDRESS':
    'Algo salió mal al actualizar la dirección del cliente',
  'CUSTOMER_NOT_FOUND_FOR_USER': 'No se encontró al cliente para este usuario',
  'SOMETHING_WRONG_ADDING_CUSTOMER_ADDRESS':
    'Algo salió mal al agregar la dirección del cliente',
  'PREF_LANGUAGE_UPDATED_SUCCESSFULLY':
    'Idioma preferido actualizado correctamente',
  'SOMETHING_WRONG_ADDING_CUSTOMER_LANGUAGE':
    'Algo salió mal al agregar el idioma del cliente',
  'SOMETHING_WRONG_REQUESTING_EMAIL_CHANGE':
    'Algo salió mal al solicitar el cambio de correo electrónico',
  'VERIFICATION_NOT_REQUESTED_OR_INVALID_EMAIL_OR_EXPIRED_CODE':
    'Verificación no solicitada o correo electrónico no válido o código expirado',
  'INVALID_EMAIL_OR_INVALID_CODE_VERIFICATION_NOT_REQUESTED_OR_EXPIRED_CODE':
    'Correo electrónico no válido o código no válido, o verificación no solicitada o código expirado',
  'VENDOR_NOT_FOUND_OR_USER_NOT_CREATED':
    'No se pudo encontrar al proveedor o no se pudo crear el usuario',
  'SOMETHING_WRONG_CREATING_MERCHANT_USER':
    'Algo salió mal al crear el usuario del comerciante',
  'MISSING_VERIFICATION_CODE_OR_EMAIL_NOT_CHANGED':
    'Falta el código de verificación o no',
  'DB_EMAIL_CHANGE_ERROR_OR_AUTH0_EMAIL_CHANGE_ERROR':
    'Error al cambiar el correo electrónico de la base de datos o error al cambiar el correo electrónico de Auth0',
  'SOMETHING_WRONG_CHANGING_EMAIL':
    'Ocurrió un error al cambiar el correo electrónico',
  'PHONE_NOT_CHANGED_OR_VERIFICATION_CODE_VALIDATION_FAILED':
    'El número de teléfono no ha sido cambiado o la validación del código de verificación ha fallado',
  'SOMETHING_WRONG_CHANGING_PHONE_AFTER_VERIFICATION':
    'Ocurrió un error al cambiar el número de teléfono después de la verificación',
  'SOMETHING_WRONG_UPDATING_USER_ROLE':
    'Ocurrió un error al actualizar el rol del usuario',
  'USER_WITH_ID_EXIST': 'Usuario encontrado con este ID',
  'SOMETHING_WRONG_FINDING_USER_ADDRESS':
    'Ocurrió un error al encontrar las direcciones del usuario',
  'USER_WITH_ID_NOT_EXIST': 'Usuario con este ID no encontrado',
  'NOT_PERMITTED_TO_DELETE_USER': 'No se permite eliminar a este usuario',
  'USER_WITH_EMAIL_NOT_EXIST_OR_NO_MERCHANT_RELATED':
    'Usuario con este correo electrónico no encontrado o no está relacionado con ningún comerciante',
  'SOMETHING_WRONG_DELETING_USER': 'Ocurrió un error al eliminar al usuario',
  'UNAUTHORIZED': 'No autorizado',
  'MISSING_CLAIM_OR_CLAIM_NOT_IN_DRAFT_STATUS_OR_DRAFT_CLAIM_INCOMPLETE_OR_ANOTHER_ACTIVE_PLAN_FOUND_FOR_SAME_PRODUCT':
    'Falta el ID de reclamación o la reclamación no está en estado de borrador o la reclamación de borrador está incompleta o se ha encontrado otra reclamación activa para el mismo producto',
  'BLANK_FIELDS': '¡Campos faltantes!',
  'INVALID_EXTERNAL_ID': '¡ID externo no válido! Debe ser una cadena UUID',
  'ERROR_GETTING_CONTRACT_BY_ID':
    'Error al obtener el contrato por ID. contractExternalId:',
  'INVALID_CONTRACT': '¡Contrato no encontrado!',
  'ERROR_UPDATING_CONTRACT_WEBHOOK':
    'Error al actualizar el webhook del contrato. contractExternalId:',
  'ERROR_TRIGGERING_CONTRACT_NOTIFICATION':
    'Error al activar las notificaciones del contrato. contractExternalId:',
  'ERROR_UPDATING_PRODUCT_SEQUENCE':
    'Error al actualizar el número de secuencia del producto. contractExternalId:',
  'ERROR_LOADING_FILE_VIA_BASE64':
    'Error al cargar el archivo desde la URL en base 64. archivo:',
  'ERROR_GETTING_CLAIM_BY_ID':
    'Error al obtener la reclamación por ID. claimId:',
  'ERROR_EXECUTING_MANAGE_CLAIM_STATUS_UPDATE':
    'Error al ejecutar manageClaimStatusUpdate. args:',
  'ERROR_EXECUTING_MANAGE_CLAIM_CREATION_FROM_PCMI':
    'Error al ejecutar manageClaimCreationFromPCMI. args:',
  'BLANK_PRODUCT_INFO': '¡Información del producto faltante!',
  'BLANK_FAILURE_INFO': '¡Información de falla faltante!',
  'BLANK_ADDRESS_DATA': '¡Datos de dirección faltantes!',
  'BLANK_CUSTOMER_IDENTIFICATION_DATA':
    '¡Datos de identificación del cliente (correo electrónico o teléfono) faltantes!',
  'CONTACT_NOT_ACTIVE': '¡El contrato no está activo!',
  'PRODUCT_NOT_EXIST': '¡Producto no encontrado!',
  'DAMAGE_CAUSE_NOT_FOUND': '¡Causa de daño no encontrada!',
  'CUSTOMER_NOT_SAME_OF_CONTACT': '¡El cliente no coincide con el contrato!',
  'PRODUCT_CONDITION_NOT_FOUND':
    '¡Condición del producto (Estado de funcionalidad de PCMI) no encontrada!',
  'ERROR_FINDING_PAGINATED_USER': 'Error al encontrar usuarios paginados.',
  'ERROR_MAPPING_USER_WITH_ROLE': 'Error al asignar un rol a un usuario.',
  'ERROR_GETTING_CUSTOMER_BY_EMAIL_WITH_VERIFICATION':
    'Error al obtener el cliente por correo electrónico con verificación. correo electrónico:',
  'ERROR_CREATING_USER': 'Error al crear el usuario. args:',
  'CREATED_TALENTLMS_USER': 'Usuario de talentLMS creado',
  'ERROR_CREATING_TALENT_USER': 'Error al crear usuario de talent. usuario:',
  'ERROR_GETTING_USER_BY_EMAIL_WITH_VERIFICATION':
    'Error al encontrar usuario por correo electrónico con verificación. correo electrónico:',
  'ERROR_UPDATING_PREF_LANGUAGE': 'Error al actualizar PrefLanguage. params:',
  'PREFERRED_LANGUAGE_UPDATED_SUCCESSFULLY':
    'Idioma preferido actualizado correctamente',
  'ERROR_SENDING_VERIFICATION_CODE_EMAIL':
    'Error al enviar el correo electrónico de código de verificación. correo electrónico:',
  'ERROR_FINDING_USER_BY_ID_WITH_VERIFICATION':
    'Error al encontrar usuario por ID con verificación. userId:',
  'AUTH0_CHANGE_ERROR': 'Error al cambiar el correo electrónico de Auth0',
  'NOT_POSSIBLE_TO_CHANGE_EMAIL':
    'No fue posible cambiar el correo electrónico:',
  'ERROR_REQUESTING_VERIFICATION':
    'Error al solicitar la verificación. params:',
  'SMS_SERVICE_FAILURE': 'Fallo en el servicio de SMS',
  'ERROR_GETTING_AUTH0_ROLES': 'Error al obtener los roles de Auth0.',
  'ERROR_TRYING_ASSIGNING_AUTH0_ROLES':
    'Error al intentar asignar un rol de Auth0. datos:',
  'ERROR_GETTING_USER_BY_CUSTOMERID':
    'Error al obtener el usuario por customerId. customerId:',
  'CUSTOMER_NOT_FOUND_WITH_EMAIL':
    'requestVerifyEmail: No se encontró ningún cliente con el correo electrónico',
  'ERROR_RESETTING_VERIFICATION':
    'Error al restablecer la verificación. customerId:',
  'ERROR_AUTH0_USER_BY_EMAIL':
    'Error al encontrar usuario de Auth0 por correo electrónico. correo electrónico:',
  'MERCHANT_NOT_FOUND_FOR_EMAIL':
    'requestVerifyEmailMerchant: No se encontró usuario de comerciante para el correo electrónico',
  'CONTACT_NOT_FOUND_FOR_CUSTOMER_WITH_ID':
    'sendAccountCreationMessage: No se encontró contrato para el cliente con ID',
  'CONTACT_DOES_NOT_HAVE_PCMI_CONTACT':
    'sendAccountCreationMessage: El contrato no tiene un número de contrato de PCMI adjunto',
  'BLANK_PHONE': '¡Falta el número de teléfono!',
  'ERROR_GETTING_USER_BY_PHONE':
    'Error al obtener el usuario por número de teléfono. número de teléfono:',
  'REGUARD_VERIFICATION_CODE': 'Código de verificación de teléfono de Reguard:',
  'BLANK_EMAIL': '¡Falta el correo electrónico!',
  'ERROR_FINDING_CUSTOMER_WITH_VERIFICATION':
    'Error al encontrar el cliente con verificación. customerId:',
  'SOMETHING_WRONG_SENDING_SMS': 'Ocurrió un error al enviar el SMS:',
  'GREETINGS_HELP': 'Hola, ¿cómo puedo ayudarte?',
  'RIGHT_PLACE':
    '¡Bien! Estás en el lugar correcto para presentar una reclamación.',
  'PRIVACY_SERIOUSLY': 'Nos tomamos tu privacidad en serio.',
  'MATCHES_INFORMATION':
    'Si tu {{KEY_TYPE}} coincide con la información que tenemos registrada para tu compra de Reguard, recibirás un código de verificación.',
  'SELECT_PRODUCT':
    'Por favor, selecciona el producto para el cual deseas presentar una reclamación hoy.',
  'FILED_ALREADY':
    'Parece que ya has presentado una reclamación para este producto.',
  'ONCE_RESOLVED':
    'Podrás presentar una nueva reclamación para este producto una vez que se resuelva tu reclamación actual.',
  'TAP_DRAFT':
    'Haz clic a continuación para empezar. Puedes cerrar y guardar un borrador de tu reclamación en tu portal en cualquier momento.',
  'THANKS_FILLING':
    'Gracias por presentar una reclamación con Reguard. Revisaremos detenidamente los detalles y te responderemos pronto.',
  'ONCE_DECISION':
    'Una vez tomada una decisión, recibirás instrucciones adicionales por correo electrónico.',
  'DRAFT_SAVED':
    'Se ha guardado un borrador de tu reclamación en tu portal. Puedes iniciar sesión para completar tu reclamación en cualquier momento dentro de los próximos 30 días.',
  'DRAFT_DISCARDED': 'Tu borrador ha sido descartado.',
  'SORRY_CODE': 'Lamentamos que no hayas recibido un código.',
  'OTHER_AUTHENTICATE':
    '¿Te gustaría intentar un nuevo método de autenticación?',
  'FOUND_ACCOUNT': 'Buenas noticias, {{USER_NAME}}, encontré tu cuenta.',
  'FOUND_ACTIVE_CLAIMS':
    'Buenas noticias, {{USER_NAME}}, encontré {{ACTIVE_CLAIMS}} reclamaciones activas en tu cuenta.',
  'START_FILING': 'Empieza a presentar tu reclamación',
  'CLAIM_SUBMITTED_TEXT': 'Reclamación enviada',
  'CLAIM_SAVED_DRAT_TEXT': 'Borrador guardado',
  'CLAIM_DISCARDED_DRAFT_TEXT': 'Borrador descartado',
  'ACCOUNT_NOTREGISTERED':
    'Parece que no tienes una cuenta registrada con Reguard.',
  'CREATE_PASSWORD': 'Crea una contraseña para presentar una reclamación.',
  'TAP_PASSWORD': 'Haz clic a continuación para crear una contraseña.',
  'SELECT_DRAFT': 'Selecciona una reclamación en borrador para editar',
  'COMPLETE_DRAFT': 'Completa tu reclamación en borrador',
  'NEW_PASSWORD': 'Crear contraseña',
  'UNABLE_LOCATE_CONTRACT':
    'Lamentablemente, no pude encontrar tu cuenta utilizando tu ID de contrato. Tu ID de contrato consta de 8 dígitos con números y letras.',
  'UNABLE_LOCATE_EMAIL':
    'Lamentablemente, no pude encontrar tu cuenta utilizando tu correo electrónico.',
  'UNABLE_LOCATE_PHONE':
    'Lamentablemente, no pude encontrar tu cuenta utilizando tu número de teléfono.',
  'NO_CONTRACTID':
    'Si no tienes tu ID de contrato, puedes proporcionar el correo electrónico o el número de teléfono registrado en tu plan de protección de Reguard.',
  'NO_EMAIL':
    'Si no tienes tu correo electrónico, puedes proporcionar el ID de contrato o el número de teléfono registrado en tu plan de protección de Reguard.',
  'NO_PHONE':
    'Si no tienes tu número de teléfono, puedes proporcionar el ID de contrato o el correo electrónico registrado en tu plan de protección de Reguard',
  'NO_PLANS':
    'Lamento, actualmente no tienes ningún plan de protección activo con Reguard.',
  'EXPIRED_PLAN':
    'Lamento, tu plan de protección de Reguard {{PCMI_ID}} expiró el {{EXPIRED_DATE}}.',
  'ANYTHING_HELP': '¿Hay algo más en lo que pueda ayudarte hoy?',
  'THANKS_REGUARD':
    'Gracias por elegir Reguard. Siempre estamos aquí para ayudarte. ¡Que tengas un buen día!',
  'VISIT_FAQ':
    'Por favor, visita nuestra página de preguntas frecuentes o ponte en contacto con el servicio al cliente para obtener ayuda adicional.',
  'TAB_FAQ':
    'Haz clic a continuación para ver nuestra página de preguntas frecuentes.',
  'TAP_CSERVICE':
    'Haz clic a continuación para ponerse en contacto con el servicio al cliente.',
  'CONTINUE_TEXT': 'Inténtalo de nuevo',
  'RESTART_TEXT': 'Reiniciar',
  'RESTART_INPROGRESS_TEXT': 'Reiniciando...',
  'QUIT_TEXT': 'Salir',
  'ERROR_TEXT': 'Algo salió mal',
  'ERROR_HANDLER_OPTIONS':
    'Por favor, inténtalo de nuevo. Elige una de las siguientes opciones:',
  'RESENT_CODE': 'Te envié un código nuevamente. ¿Lo recibiste?',
  'UNABLE_FIND_CLAIM': 'Lamento no poder encontrar tu reclamación.',
  'FOUND_CLAIM_ID': 'Esto es lo que encontré:',
  'RIGHT_PLACE_STATUS':
    '¡Bien, estás en el lugar correcto para verificar el estado de tu reclamación!',
  'GET_STARTED_CHATBOT_COPY_PLACEHOLDERS':
    'Ingresa un ID de contrato, un número o un correo electrónico.',
  'GET_STARTED_CHATBOT_COPY_WITH_PLACEHOLDERS':
    'Para comenzar, ¿puedo tener tu ID de contrato, o el correo electrónico o el número de teléfono que utilizaste para registrarte con Reguard?',
  'NOT_RECEIVE_CHATBOT_COPY_PLACEHOLDERS':
    'Ingresa el código de 6 dígitos que acabamos de enviar.',
  'NOT_RECEIVE_CHATBOT_COPY_WITH_PLACEHOLDERS':
    'Si no recibiste un código, por favor avísame y lo enviaré nuevamente.',
  'MAY_AUTH_CHATBOT_COPY_PLACEHOLDERS':
    'Ingresa el correo electrónico o el ID de contrato o el número de teléfono para autenticar.',
  'MAY_AUTH_CHATBOT_COPY_WITH_PLACEHOLDERS':
    '¿Puedo tener tu correo electrónico o ID de contrato o número de teléfono para autenticar?',
  'NAME_REGISTERED_CHATBOT_COPY_PLACEHOLDERS':
    'Por favor, proporciona tu nombre completo.',
  'NAME_REGISTERED_CHATBOT_COPY_WITH_PLACEHOLDERS':
    '¿Puedo tener el nombre registrado en tu plan de protección de Reguard?',
  'INVALID_NAMES_CHATBOT_COPY_PLACEHOLDERS':
    'Por favor, proporciona tu nombre completo.',
  'INVALID_NAMES_CHATBOT_COPY_WITH_PLACEHOLDERS':
    'Nombre no válido. Por favor, ingresa el nombre y apellido.',
  'AUTH_PHONE_CHATBOT_COPY_PLACEHOLDERS':
    'Ingresa el código de 6 dígitos que acabamos de enviar.',
  'AUTH_PHONE_CHATBOT_COPY_WITH_PLACEHOLDERS':
    '¿Puedo tener tu número de teléfono para autenticar?',
  'NOT_UNDERSTOOD_CHATBOT_COPY_WITH_PLACEHOLDERS':
    'Ingresa el correo electrónico o el ID de contrato o el número de teléfono para autenticar.',
  'NOT_UNDERSTOOD_CHATBOT_COPY_PLACEHOLDERS':
    'Lo siento, no estoy seguro de haber entendido eso. Para ubicar tu cuenta, necesito tu correo electrónico, número de teléfono o ID de contrato.',
  'DONT_KNOW_CLAIMID_CHATBOT_COPY_PLACEHOLDERS':
    '¿Puedo tener tu ID de contrato, o el correo electrónico o el número de teléfono que utilizaste para registrarte con Reguard?',
  'DONT_KNOW_CLAIMID_CHATBOT_COPY_WITH_PLACEHOLDERS':
    'Ingresa un ID de reclamación.',
  'PLEASE_CODE_CHATBOT_COPY_PLACEHOLDERS':
    'No sé cuál es mi ID de reclamación.',
  'PLEASE_CODE_CHATBOT_COPY_WITH_PLACEHOLDERS':
    'Ingresa el código de 6 dígitos que acabamos de enviar.',
  'THANKS_REGARD':
    'Por favor, ingresa el código temporal de 6 dígitos que se envió.',
  'GET_STARTED_CLAIM_CHATBOT_COPY_WITH_PLACEHOLDERS':
    'Gracias por elegir Reguard. Siempre estamos aquí para ayudarte. ¡Que tengas un buen día!',
  'GET_STARTED_CLAIM_CHATBOT_COPY_PLACEHOLDERS':
    'Para comenzar, ¿puedo tener tu ID de reclamación? Tu ID de reclamación es un código de [7] dígitos que comienza con [SO].',
  'MAY_CONTRACT_EMAIL_PHONE_CHATBOT_COPY_WITH_PLACEHOLDERS':
    'Para comenzar, ¿puedo tener tu ID de reclamación? Tu ID de reclamación es un código de [7] dígitos que comienza con [SO].',
  'MAY_CONTRACT_EMAIL_PHONE_CHATBOT_COPY_PLACEHOLDERS':
    'Ingresa un ID de contrato, un número o un correo electrónico.',
  'AUTH_OPTIONS_GENERIC':
    'De acuerdo, ¿puedo tener tu ID de contrato, o el correo electrónico o el número de teléfono que utilizaste para registrarte con Reguard?',
  'ROLE_NOT_EXIST': '¿Puedo tener tu...?',
  'RESTRICTED': 'Rol no encontrado',
  'ERROR_FINDING_USER_BY_AUTH0ID': 'Restringido',
  'ERROR_FINDING_USER_ROLES': 'Error al encontrar usuario por auth0id. auth0id',
  'SALE_NOT_FOUND': 'Error al',
  'BLANK_TOKEN': 'Token faltante',
  'TOKEN_VERIFICATION_FAILED': 'Error de verificación de token.',
  'VALID_BLANK_TOKEN': 'Token válido faltante.',
  'INVALID_CALLER': 'Llamante inválido.',
  'INVALID_ENVIRONMENT': 'Entorno inválido.',
  'ERROR_DECODING_TOKEN': 'Error al decodificar el token.',
  'ERROR_REQUESTING_VERIFICATION_CUSTOMERID':
    'Error al solicitar la verificación. customerId:',
  'ERROR_WHILE_FINDING_USER_BY_EMAIL':
    'Error al encontrar usuario por correo electrónico. email:',
  'CONTRACT_WITH_PCMI_ID_NOT_FOUND_OR_MISSING_CONTRACT_ID_PARAMS':
    'No se encontró contrato con este ID de PCMI o falta el parámetro de ID de contrato.',
  'SOMETHING_WRONG_GETTING_CONTRACT_BY_PCMI_ID':
    'Algo salió mal al obtener el contrato por ID de PCMI.',
  'SOMETHING_WRONG_GETTING_USER_ADDRESSES':
    'Algo salió mal al obtener las direcciones del usuario.',
  'ERROR_SAVING_STGCONTRACTS': 'Error al guardar stgContracts. llamante:',
  'ERROR_GENERATING_SUCCESS_FILE': 'Error al generar el archivo de éxito.',
  'ERROR_UPLOADING_PCMI_CONTRACT_IMPORT_FILE':
    'Error al cargar el archivo de importación del contrato PCM.',
  'CUSTOMER_ID_REQUIRED': 'Se requiere el ID del cliente.',
  'ERROR_GETTING_THE_CLAIM_BY_ID_FROM_DB':
    'Error al obtener la reclamación por ID de la base de datos. claimId',
  'ERROR_GETTING_CUSTOMER_BY_ID_FROM_DB':
    'Error al obtener el cliente por ID de la base de datos.',
  'CLAIM_NOT_EXIST': '¡Reclamación no encontrada!',
  'ADDCLAIMPHOTOS_CLAIM_DOES_NOT_EXIST':
    'addClaimPhotos - La reclamación no existe.',
  'ERROR_DELETING_CLAIM_WITH_ID': 'Error al eliminar la reclamación con ID:',
  'PRODUCT_PURCHASE_ID_REQUIRED': 'Se requiere el ID de compra del producto.',
  'OPENED_CLAIM_FOR_PRODUCTPURCHASEID':
    'Existe una reclamación abierta para productPurchaseId.',
  'SAVECLAIMPHOTOS_ERROR_UPLOADING_PHOTOS':
    'saveClaimPhotos - Error al cargar fotos.',
  'VERIFICATION_CODE_VALIDATION_FAILED':
    'Error de validación del código de verificación.',
  'CODE_WAS_NOT_SENT_PREVIOUSLY': 'El código no se envió previamente.',
  'CHANGE_REQUEST_NOT_FOUND': '¡Solicitud de cambio no encontrada!',
  'ERROR_GETTING_PLANS_FOR_CUSTOMER':
    'Error al obtener los planes para el cliente. customerId:',
  'ERROR_GETTING_PLAN_FOR_MERCHANT':
    'Error al obtener el plan para el comerciante. planId:',
  'DATABASE_ERROR_GETTING_REPORTS_SALES_DATA':
    'Error de base de datos al obtener datos de ventas de informes.',
  'DATABASE_ERROR_GETTING_REPORTS_CLAIMS_DATA':
    'Error de base de datos al obtener datos de reclamaciones de informes.',
  'DATABASE_ERROR_GETTING_UNKNOWN_SALES_DATA':
    'Error de base de datos al obtener datos de ventas desconocidos.',
  'ERROR_WHEN_GETTING_AZURE_AD_ACCESS_TOKEN':
    'Error al obtener el token de acceso de Azure AD.',
  'FAILED_TO_SEND_SMS': '¡Error al enviar el SMS!',
  'PHONE_NUMBER_MUST_BE_ALL_NUMERIC_10_CHARACTERS_LONG_STRING':
    '¡El número de teléfono debe ser una cadena numérica de 10 caracteres!',
  'CUSTOMER_EXISTS': '¡Este cliente ya existe!',
  'CANNOT_FIND_MERCHANT_WITH_CODE':
    'No se puede encontrar el comerciante con el código.',
  'CANNOT_FIND_STORE_FOR_MERCHANT':
    'No se puede encontrar una tienda para este comerciante.',
  'CANNOT_FIND_PROTECTION_PLAN_FOR_MERCHANT':
    'No se puede encontrar un plan de protección para este comerciante.',
  'CANNOT_FIND_PRODUCTS_FOR_MERCHANT_PLAN_COMBINATION':
    'No se pueden encontrar productos para esta combinación de comerciante-plan:',
  'FAILED_CREATING_CONTRACT': 'Error al crear el contrato.',
  'CANNOT_SEND_EMAIL': 'No se puede enviar el correo electrónico.',
  'EMAIL_OR_PHONE_NO_REQUIRED':
    '¡Se requiere correo electrónico o número de teléfono!',
  'CUSTOMER_WITH_SAME_EMAIL_ALREADY_EXISTS':
    'Ya existe un cliente con el mismo correo electrónico.',
  'FAILED_TO_SEND_INVITATION_EMAIL':
    'No se pudo enviar el correo electrónico de invitación a.',
  'MISSING_FIELDS': 'Campos faltantes.',
  'CONTRACT_NOT_EXIST': 'Contrato no encontrado.',
  'SOME_DESCRIPTION': 'Alguna descripción.',
  'MISSING_OR_INVALID_FIELDS': 'Campos faltantes o inválidos.',
  'CONTRACT_NOT_FOUND_OR_NOT_ACTIVE_OR_PRODUCT_NOT_FOUND_OR_DAMAGE_CAUSE_NOT_FOUND':
    'Contrato no encontrado, no está activo, producto no encontrado o causa de daño no encontrada.',
  'SOMETHING_WRONG_WHEN_UPDATING_OR_CREATING_A_CLAIM':
    'Algo salió mal al actualizar o crear una reclamación.',
  'ERROR_GETTING_CONTRACTS': '¡Error al obtener contratos!',
  'MERCHANT_NOT_FOUND': '¡Comerciante no encontrado!',
  'ERROR_SAVING_MERCHANT_USER': 'Error al guardar el usuario del comerciante.',
  'ERROR_GETTING_SUBCATEGORIES': '¡Error al obtener subcategorías!',
  'TIER_NOT_EXIST': '¡El nivel no existe!',
  'ERROR_GETTING_DEALER_INFO': '¡Error al obtener detalles del distribuidor!',
  'ERROR_GETTING_STORE_INFO': '¡Error al obtener detalles de la tienda!',
  'CANNOT_FIND_DEFAULT_PRODUCT_SUBCATEGORY_WITH_CODE':
    'No se puede encontrar la subcategoría predeterminada del producto con el código:',
  'CANNOT_GET_ACCESS_TOKEN': '¡No se puede obtener el token de acceso!',
  'CANNOT_GET_REFRESH_TOKEN': '¡No se puede obtener el token de actualización!',
  'SOMETHING_WRONG': 'Algo salió mal.',
  'FAILURE_DATE_MUST_BE_WITHIN_CONTRACT_EFFECTIVE_AND_EXPIRATION_DATE':
    'La fecha de falla debe estar dentro del rango de vigencia y vencimiento del contrato.',
  'CANNOT_CREATE_CONTRACT': '¡No se puede crear el contrato!',
  'CANNOT_ADD_NOTES_TO_CONTRACT': '¡No se pueden agregar notas al contrato!',
  'CANNOT_UPDATE_PRODUCTS_IN_CONTRACT':
    '¡No se pueden actualizar los productos en el contrato!',
  'CANNOT_UPDATE_CONTACT_IN_CONTRACT':
    '¡No se puede actualizar el contacto en el contrato!',
  'CANNOT_GET_CLAIMS': '¡No se pueden obtener las reclamaciones!',
  'CANNOT_GET_CLAIM_DETAILS':
    '¡No se pueden obtener los detalles de la reclamación! número de reclamación:',
  'CANNOT_CREATE_CLAIM': '¡No se puede crear la reclamación!',
  'CANNOT_ADD_NOTES_TO_CLAIM': '¡No se pueden agregar notas a la reclamación!',
  'CANNOT_GET_SUBCATEGORIES': '¡No se pueden obtener las subcategorías!',
  'CANNOT_GET_FAILURE_TYPES': '¡No se pueden obtener los tipos de falla!',
  'CANNOT_GET_DEALERS': '¡No se pueden obtener los distribuidores!',
  'CANNOT_GET_DEALER_DETAILS':
    '¡No se pueden obtener los detalles del distribuidor!',
  'CANNOT_GET_DEALER_STORES':
    '¡No se pueden obtener las tiendas del distribuidor!',
  'CANNOT_GET_DEALER_STORE_INFO':
    '¡No se pueden obtener los detalles de la tienda del distribuidor!',
  'INVALID_LOGIN': 'Inicio de sesión inválido.',
  'CONTRACT_NOT_FOUND': '¡Contrato no encontrado!',
  'CONTRACT_NOT_ACTIVE': '¡El contrato no está activo!',
  'PRODUCT_NOT_EXISTS': '¡Producto no encontrado!',
  'DAMAGE_CAUSE_NOT_EXIST': '¡Causa de daño no encontrada!',
  'CUSTOMER_NOT_THE_SAME_OF_CONTRACT':
    '¡El cliente no es el mismo que el del contrato!',
  'INVALID_EXTERNAL_ID_MUST_BE_A_UUID_STRING':
    '¡ID externo no válido! Debe ser una cadena UUID.',
  'UPDATING_MS_TOKEN': 'Actualizando el token de acceso de MS.',
  'ERROR_GETTING_CUSTOMER_BY_MAIL':
    'Error al obtener el cliente por correo electrónico. correo electrónico:',
  'ERROR_OCCURRED_PROCESSING_PHOTOS_TO_CREATE':
    'Se produjo un error al procesar las fotos para crear. URL:',
  'ERROR_GETTING': 'Error al obtener.',
  'ERROR_FINDING_USER_BY_EMAIL_WITH_VERIFICATION':
    'Error al encontrar al usuario por correo electrónico con verificación. correo electrónico:',
  'ERROR_FINDING_USER_BY_EMAIL_USERID':
    'Error al encontrar al usuario por correo electrónico. ID de usuario:',
  'CANNOT_FIND_USER_ON_DB':
    'No se puede encontrar al usuario en la base de datos.',
  'MERCHANT_WITH_MAIL_NOT_EXIST':
    'No se encontró el comerciante con este correo electrónico.',
  'SOMETHING_WRONG_IN_REQUESTING_PHONE_NUMBER_CHANGE_OR_WHEN_SENDING_SMS':
    'Algo salió mal al solicitar el cambio de número de teléfono o al enviar un SMS.',
  'NO_MERCHANT_RELATED': '¡No relacionado con el comerciante!',
  'NOT_ALLOWED_TO_DELETE_THIS_USER':
    '¡No tienes permiso para eliminar a este usuario!',
  'FAILED_TO_SEND_MAILS': 'Error al enviar correos electrónicos.',
  'MAY_I_HAVE_YOUR_KEY_TO_AUTHENTICATE':
    '¿Podría proporcionarme {{keyList}} para autenticarse?',
  'THIS_DAMAGE_HAPPENED_BEFORE_YOUR_PROTECTION_PLAN':
    'Desafortunadamente, ¡este daño ocurrió antes de que se activara su plan de protección! Si cree que esto es un error, comuníquese con el servicio al cliente.',

  // Financial Report UI
  'financial_report': 'Informe Financiero',
  'top_performing_store_quantity':
    'Cantidad de la tienda con el mejor rendimiento.',
  'top_performing_store_sales': 'Ventas de la tienda con el mejor rendimiento.',
  'lowest_performing_store_quantity':
    'Cantidad de la tienda con el rendimiento más bajo.',
  'lowest_performing_store_sales':
    'Ventas de la tienda con el rendimiento más bajo.',
  'total_stores_without_sales': 'Total de Tiendas Sin Ventas',
  'fpp_gross_revenue': 'Ingresos Brutos de FPP',
  'fpp_units_sold': 'Unidades Vendidas de FPP',
  'fpp_total_sales': 'Ventas Totales de FPP',

  'YES_CREATE_PASSWORD': 'Sí, crear una contraseña.',
  'NO_FILE_MY_CLAIM_LATER': 'No, presentar mi reclamación más tarde.',
  'START_A_NEW_CLAIM': 'Iniciar una nueva reclamación.',
  'ERROR_RETRIEVING_CLAIM_LINKED_PRODUCT':
    'Hubo un error al recuperar el ID de reclamación vinculado al producto.',
  'FINISH_MY_CLAIM': 'Finalizar mi reclamación.',
  'START_NEW_CLAIM': 'Iniciar una nueva reclamación.',
  'MAY_I_HAVE_YOUR_Contract ID or email or phone number_TO_AUTHENTICATE':
    '¿Podría proporcionarme su número de contrato, correo electrónico o número de teléfono para autenticarse?',
  'MAY_I_HAVE_YOUR_Contract ID or email_TO_AUTHENTICATE':
    '¿Podría proporcionarme su número de contrato o correo electrónico para autenticarse?',
  'MAY_I_HAVE_YOUR_Contract ID or phone number_TO_AUTHENTICATE':
    '¿Podría proporcionarme su número de contrato o número de teléfono para autenticarse?',
  'MAY_I_HAVE_YOUR_email or phone number_TO_AUTHENTICATE':
    '¿Podría proporcionarme su correo electrónico o número de teléfono para autenticarse?',
  'MAY_I_HAVE_YOUR_Contract ID_TO_AUTHENTICATE':
    '¿Podría proporcionarme su número de contrato para autenticarse?',
  'MAY_I_HAVE_YOUR_email_TO_AUTHENTICATE':
    '¿Podría proporcionarme su correo electrónico para autenticarse?',
  'MAY_I_HAVE_YOUR_phone number_TO_AUTHENTICATE':
    '¿Podría proporcionarme su número de teléfono para autenticarse?',
  'welcome': 'Bienvenido/a, {{name}}.',
  'home': 'Inicio',
  'my_plans': 'Mis Planes',
  'my_plan': 'Mi plan',
  'my_claims': 'Mis Reclamaciones',
  'open_claims': 'Reclamaciones Abiertas',
  'inactive_protection_plans': 'Planes de Protección Inactivos',
  'active_protection_plans': 'Planes de Protección Activos',
  'expired_protection_plans': 'Planes de Protección Expirados',
  'file_a_claim': 'Presentar una Reclamación',
  'account': 'Cuenta',
  'terms_of_use': 'Términos de Uso',
  'help': 'Ayuda',
  'account_settings': 'Configuración de la Cuenta',
  'log_out': 'Cerrar Sesión',
  'draft_claims': 'Borradores de Reclamaciones',
  'why_is_my_plan_inactive': '¿Por qué mi plan está inactivo?',
  'your_plan_will_be_automatically_activated_once_your_first_item_is_delivered':
    'Su plan se activará automáticamente una vez que se entregue su primer artículo.',
  'draft': 'Borrador',
  'under_review': 'En Revisión',
  'approved': 'Aprobado',
  'in_progress': 'En Progreso',
  'closed': 'Cerrado',
  'claim_id': 'ID de Reclamación:',
  'last_edited_date': 'Fecha de Última Edición:',
  'view_details': 'Ver detalles',
  'finish_claim': 'Finalizar reclamación',
  'inactive': 'Inactivo',
  'active': 'Activo',
  'days_left': ': {{daysUntilExpiration}} días restantes',
  'view_claim': 'Ver reclamación',
  'reguard_all_rights_reserved_reguard_the_reguard_logo_the_tag_design_are_trademarks_of_reguard_and_its_affiliated_companies':
    'Reguard. Todos los derechos reservados. REGUARD, el logotipo de REGUARD, el diseño de la etiqueta son marcas comerciales de Reguard y sus empresas afiliadas.',
  'log_in': 'Iniciar sesión',
  'email': 'Correo electrónico',
  'business_email': 'Correo electrónico empresarial',
  'password': 'Contraseña',
  'remember_me': 'Recuérdame durante 30 días.',
  'please_enter_email_with_right_format':
    'Ingrese un correo electrónico con el formato correcto.',
  'email_is_required': 'Se requiere correo electrónico.',
  'password_is_required': 'Se requiere contraseña.',
  'i_forgot_my_password': 'Olvidé mi contraseña.',
  'your_email_or_password_is_incorrect':
    'Su correo electrónico o contraseña es incorrecta.',
  'please_try_again': 'Por favor, inténtelo de nuevo.',
  'please_try_again_in_a_few_minutes_there_was_an_error_logging_you_in':
    'Por favor, inténtelo de nuevo en unos minutos, hubo un error al iniciar sesión.',
  "haven't_set_a_password": '¿No ha establecido una contraseña?',
  'set_a_password_now': 'Establezca una contraseña ahora.',
  'i_did_not_provide_my_email_at_point-of-purchase':
    'No proporcioné mi correo electrónico al momento de la compra.',
  'my_account': 'Mi Cuenta',
  'profile': 'Perfil',
  'name': 'Nombre',
  'mobile_number': 'Número de Teléfono Móvil',
  'my_addresses': 'Mis Direcciones',
  'add_new_address': 'Agregar Nueva Dirección',
  'language': 'Idioma',
  'select_language': 'Seleccionar Idioma',
  'add_phone_number': 'Agregar número de teléfono',
  'update_your_email_address': 'Actualizar su dirección de correo electrónico.',
  'this_address_is_used_to_log_in_your_reguard_account_and_help_service_your_claims':
    'Esta dirección se utiliza para iniciar sesión en su cuenta de Reguard y ayudar a atender sus reclamaciones.',
  'your_phone_number': 'su número de teléfono.',
  'this_contact_information_will_be_used_to_help_service_your_claims':
    'Esta información de contacto se utilizará para ayudar a atender sus reclamaciones.',
  'update_your_address': 'Actualizar su dirección.',
  'this_address_will_be_used_to_help_service_your_claim':
    'Esta dirección se utilizará para ayudar a atender su reclamación.',
  'select_your_language': 'Seleccionar su idioma',
  'this_will_be_used_in_navigating_your_website_experience':
    'Esto se utilizará para navegar por su experiencia en el sitio web.',
  'email_verified': 'Correo electrónico verificado',
  'your_email_has_been_updated': 'Su correo electrónico ha sido actualizado.',
  'phone_number_verified': 'Número de teléfono verificado',
  'your_phone_number_has_been_updated':
    'Su número de teléfono ha sido actualizado.',
  'address_saved': '¡Dirección guardada!',
  'your_address_has_been_saved': 'Su dirección ha sido guardada.',
  'language_preference_saved': '¡Preferencia de idioma guardada!',
  'your_language_preference_has_been_set_to':
    'Se ha establecido su preferencia de idioma en {{value}}.',
  'update': 'Actualizar',
  'add': 'Agregar',
  'add_a_new_address': 'Agregar una nueva dirección.',
  'please_enter_the_verification_code_we_just_sent_to':
    'Por favor, ingrese el código de verificación que acabamos de enviar a {{value}}',
  'i_would_like_to_receive_marketing/promotional_communications_from_reguard':
    'Me gustaría recibir comunicaciones de marketing/promocionales de Reguard.',
  'send_email': 'Enviar correo electrónico',
  'save_changes': 'Guardar cambios',
  'cancel': 'Cancelar',
  'the_postal_code_does_not_match_the_state':
    'El código postal no coincide con el estado.',
  'please_insert_a_valid_postal_code':
    'Por favor ingrese un código postal válido.',
  'address_line_1_is_required': 'La línea de dirección 1 es obligatoria.',
  'cannot_be_more_than_50_characters': 'No puede tener más de 50 caracteres.',
  'no_leading_spaces_allowed': 'No se permiten espacios al principio.',
  'city_is_required': 'La ciudad es obligatoria.',
  'postal_code_is_required': 'El código postal es obligatorio.',
  'postal_code_should_have_5_digits': 'El código postal debe tener 5 dígitos.',
  'state_is_required': 'El estado es obligatorio.',
  'address_line_1': 'Línea de dirección 1',
  'enter_an_address': 'Ingrese una dirección',
  'apartment_unit_suite_or_floor': 'Apartamento, unidad, suite o piso #',
  'optional': 'Opcional',
  'city': 'Ciudad',
  'state': 'Estado',
  'postal_code': 'Código Postal',
  'save': 'Guardar',
  'changes': 'cambios',
  'address': 'dirección',
  'current_password': 'Contraseña actual',
  'new_password': 'Contraseña nueva',
  'confirm_new_password': 'Confirmar contraseña nueva',
  'your_password_has_been_saved': '¡Su contraseña ha sido guardada!',
  'password_must_be_at_least_8_characters_long_and_contain_one_character_from_each_of_the_following':
    'La contraseña debe tener al menos 8 caracteres y contener un carácter de cada uno de los siguientes:',
  'uppercase_(a,_z)': 'Mayúsculas (A, Z)',
  'lowercase_(a,_z)': 'Minúsculas (a, z).',
  'numeric_(0-9)': 'Numérico (0-9).',
  'special_(!,_%,_@,_#,_etc.)': 'Carácter especial (!, %, @, #, etc.)',
  'both_passwords_need_to_be_the_same': 'Ambas contraseñas deben ser iguales.',
  'my_protection_plans': 'Mis Planes de Protección',
  'claims': 'Reclamaciones',
  'closed_claims': 'Reclamaciones Cerradas',
  'do_you_wish_to_delete_the_draft': '¿Desea eliminar el borrador?',
  'this_action_cannot_be_undone': 'Esta acción no se puede deshacer.',
  "why_can't_i_file_a_claim": '¿Por qué no puedo presentar una reclamación?',
  'all_your_protection_plans_are_expired':
    'Todos sus planes de protección han expirado.',
  'all_your_plans_are_expired': 'Todos sus planes han expirado.',
  'you_can_file_a_new_claim_once_your_current_ones_are_resolved':
    'Puede presentar una nueva reclamación una vez que se resuelvan las actuales.',
  'delete_claim': 'Eliminar reclamación',
  'you_have_no_prior_claims_please_click_"file_a_claim"_to_file':
    'No tiene reclamaciones anteriores, haga clic en "Presentar una reclamación" para presentar una.',
  'loading': 'Cargando...',
  'choose_a_product_to_file_a_claim':
    'Seleccione un producto para presentar una reclamación.',
  'expired': 'Expirado',
  'description': 'Descripción',
  'merchant': 'Comerciante',
  'mattress_plan_icon': 'Ícono del Plan de Colchón',
  'outdoor_plan_icon': 'Ícono del Plan de Exteriores',
  'rug_plan_icon': 'Ícono del Plan de Alfombra',
  'furniture_plan_icon': 'Ícono del Plan de Muebles',
  'view_terms': 'Ver términos',
  'active_claim': 'Reclamación Activa',
  'coverage_highlights': 'Aspectos Destacados de Cobertura',
  'previous_claim': 'Reclamación Anterior',
  'you_can_file_a_claim_once_your_protection_plan_is_active':
    'Puede presentar una reclamación una vez que su plan de protección esté activo.',
  'please_refer_to': 'Consulte los',
  'terms_and_conditions': 'términos y condiciones',
  'for_full_list_of_coverages': 'para ver la lista completa de coberturas.',
  'damage': 'Daño',
  'plan_type': 'Tipo de plan',
  'plan_term': 'Plazo del plan',
  'contract': 'Contrato',
  'protection_plan_details': 'Detalles del Plan de Protección',
  'plan_details': 'Detalles del plan',
  'customer_service_will_carefully_review_your_claim':
    'El servicio al cliente revisará cuidadosamente tu reclamación.',
  'if_approved_you_will_receive_further_instruction_by_email':
    'Si es aprobada, recibirás instrucciones adicionales por correo electrónico.',
  'reguard_will_carefully_review_your_claim_and_get_back_to_you_soon':
    '¡Reguard revisará cuidadosamente tu reclamación y se pondrá en contacto contigo pronto!',
  'what_to_expect_next': '¿Qué esperar a continuación?',
  'summary': 'Resumen',
  'damage_details': 'Detalles del daño',
  'you_don’t_have_any_open_claims': 'No tienes reclamaciones abiertas',
  'upload_photos': 'Cargar fotos',
  'review_your_submission': 'Revisar tu envío',
  'verify_contact_info': 'Verificar información de contacto',
  'delete': 'Eliminar',
  'save_as_draft': 'Guardar como borrador',
  'discard_updates': 'Descartar actualizaciones',
  'remove': 'Eliminar',
  'n_of_n': '{{first}} de {{last}}',
  'date_of_damage_is_required': '*¡Se requiere la fecha del daño!',
  'date_of_damage_cannot_be_in_the_future':
    'La fecha del daño no puede ser en el futuro',
  'location_of_damage_is_required': '*¡Se requiere la ubicación del daño!',
  'damage_area_is_required': '*¡Se requiere el área del daño!',
  'cause_of_damage_is_required': '*¡Se requiere la causa del daño!',
  'description_of_damage_is_required': '*¡Se requiere la descripción del daño!',
  'current_password_is_required': 'Se requiere la contraseña actual',
  'confirm_password_is_required': 'Se requiere confirmar la contraseña',
  'claim_photo_is_required': 'Se requiere la foto de la reclamación',
  'address_is_required': 'Se requiere la dirección',
  'password_must_be_at_least_8_characters':
    'La contraseña debe tener al menos 8 caracteres',
  'new_password_must_be_at_least_8_characters':
    'La nueva contraseña debe tener al menos 8 caracteres',
  'confirm_password_must_be_at_least_8_characters':
    'La confirmación de contraseña debe tener al menos 8 caracteres',
  'temporary_password_must_be_at_least_6_characters':
    'La contraseña temporal debe tener al menos 6 caracteres',
  'please_upload_a_minimum_of_5_images':
    'Por favor, carga un mínimo de 5 imágenes',
  'first_name_must_be_at_least_3_characters':
    'El nombre debe tener al menos 3 caracteres',
  'first_name_is_required': 'Se requiere el nombre',
  'last_name_must_be_at_least_3_characters':
    'El apellido debe tener al menos 3 caracteres',
  'last_name_is_required': 'Se requiere el apellido',
  'order_number_is_required': 'Se requiere el número de orden',
  'phone_number_is_required': 'Se requiere el número de teléfono',
  'is_not_in_correct_format': 'No tiene el formato correcto',
  'first_name': 'Nombre',
  'last_name': 'Apellido',
  'order_number': 'Número de orden',
  'phone_number': 'Número de teléfono',
  'continue': 'Continuar',
  'new_password_is_required': 'Se requiere una nueva contraseña',
  "your_password_doesn't_meet_the_requirements":
    'Tu contraseña no cumple con los requisitos.',
  'please_repeat_the_new_password': 'Por favor, repite la nueva contraseña',
  'please_make_sure_your_passwords_match':
    'Por favor, asegúrate de que tus contraseñas coincidan.',
  'temporary_password_is_required': 'Se requiere una contraseña temporal',
  'photos_uploaded': '{{length}} fotos cargadas',
  'account_not_found_error':
    'Lamentablemente, no pudimos encontrar la cuenta que proporcionaste en nuestros registros. Por favor, verifica la información de tu orden o envíanos un correo electrónico a {{email}}',
  'resend_code_in': 'Reenviar código en {{time}} segundos.',
  'create_new_password': 'Crear nueva contraseña',
  'enter_a_new_password': 'Ingresa una nueva contraseña',
  'temporary_password': 'Contraseña temporal',
  'type_your_password': '¡Ingresa tu contraseña!',
  'repeat_new_password': 'Repite la nueva contraseña',
  'repeat_your_password_here': '¡Repite tu contraseña aquí!',
  'save_password': 'Guardar contraseña',
  'enter_your_email_to_get_started':
    'Ingresa tu correo electrónico para comenzar',
  'to_set_or_reset_your_password_enter_email':
    'Para establecer o restablecer tu contraseña, ingresa el correo electrónico que utilizaste para comprar el plan y te enviaremos un código.',
  'Make_sure_that_you_are_entering_the_same_email_that_was_used_when_purchasing_your_Reguard_plan':
    'Asegúrate de ingresar el mismo correo electrónico que se utilizó al comprar tu plan de Reguard.',
  'unsubscribe_successful': 'Cancelación exitosa',
  'successfully_unsubscribed':
    'Tu correo electrónico se ha cancelado correctamente de esta lista.',
  'token_must_have_6_digits': 'El token debe tener 6 dígitos',
  'token_must_have_only_numbers': 'El token solo debe contener números',
  'token_not_valid': 'El token no es válido',
  'want_to_save_draft': '¿Deseas guardar un borrador?',
  'save_your_draft_and_complete_later':
    'Guarda tu borrador y completa más tarde.',
  'product_name': 'Nombre del producto',
  'purchase_date': 'Fecha de compra',
  'next': 'Siguiente',
  'prev': 'Anterior',
  'description_of_damage': 'Descripción del daño',
  'suggested_topics': 'Temas sugeridos:',
  'what_happened': '¿Qué sucedió?',
  'how_did_it_happen': '¿Cómo ocurrió?',
  'attempts_to_fix_clean_damage': '¿Hubo intentos de reparar/limpiar el daño?',
  'product_serial_number': '¿Cuál es el número de serie de tu producto?',
  'describe_damage_to_product': 'Describe el daño al producto.',
  'description_of_damage_required': '*Descripción del daño',
  'product_condition': 'Condición del producto',
  'product_condition_required': '*¡Se requiere la condición del producto!',
  'photos_of_damage': 'Fotos del daño',
  'suggested_photos': 'Fotos sugeridas:',
  'photo_of_product_label': 'Foto de la etiqueta del producto',
  'photo_of_item': 'Foto del artículo',
  'close_up_photo_of_damage': 'Una foto de cerca del daño',
  'photo_of_product_serial_number': 'Foto del número de serie del producto',
  'max_photos_reached': 'Has intentado subir más de 12 fotos',
  'remove_photo_confirmation': '¿Deseas eliminar esta foto?',
  'uploading': 'Subiendo...',
  'done': 'Hecho',
  'edit_photos': 'Editar fotos',
  'photos': 'FOTOS',
  'verified': 'Verificado',
  'unverified': 'Sin Verificar',
  'add_5_12_pictures': 'Agregar de 5 a 12 imágenes',
  'date_of_damage': 'Fecha del daño',
  'select': 'Seleccionar...',
  'location_of_damage': 'Ubicación del daño',
  'damage_area': 'Área del daño',
  'cause_of_damage': 'Causa del daño',
  'your_service_address': 'Tu dirección de servicio',
  'select_service_address':
    'Selecciona la dirección que Reguard debe utilizar para atender tu reclamación.',
  'please_select_service_address': '*¡Selecciona una dirección de servicio!',
  'your_contact_information': 'Tu información de contacto',
  'contact_phone_number':
    'Reguard puede comunicarse con este número de teléfono para ayudar con tu reclamación.',
  'please_add_phone_number': 'Agrega un número de teléfono, por favor',
  'edit_phone_number': 'Editar número de teléfono',
  'add_telephone_number': '*¡Agrega un número de teléfono!',
  'what_do_i_need_to': '¿Qué necesito?',
  'an_accident_happened_in_the_last_30_days':
    'Un accidente ocurrió en los últimos 30 días.',
  'an_event_happened_in_the_last_30_days':
    'Un evento ocurrió en los últimos 30 días.',
  'you_can_upload_photos_of_the_damage': 'Puedes subir fotos del daño.',
  'submit': 'Enviar',
  'submit_all': 'Enviar Todos',
  'save_and_close': 'Guardar y cerrar',
  "we_couldn't_find_the_page_that_you_requested":
    'No pudimos encontrar la página que solicitaste.',
  'there_is_an_error': 'Hay un error.',
  'development_in_progress': 'Desarrollo en progreso...',
  'see_less': 'Ver menos',
  'see_more': 'Ver más',
  'reguards_customer_service_team_will_review_your_claim_information_and_photos_to_determine_the_best_resolution':
    'El equipo de Servicio al Cliente de Reguard revisará la información y las fotos de tu reclamación para determinar la mejor solución.',
  'a_trusted_local_service_provider_will_reach_out_to_schedule_an_on_site_inspection':
    'Un proveedor de servicios local confiable se pondrá en contacto contigo para programar una inspección en el lugar.',
  'after_assessing_your_claim_in_person_they_will_decide_how_to_best_fix_the_damage':
    'Después de evaluar tu reclamación en persona, decidirán la mejor forma de reparar el daño.',
  'the_merchant_you_purchased_the_product_from_will_process_your_reimbursement_in_line_with_the_terms_and_conditions_applicable_to_your_state':
    'El comerciante donde compraste el producto procesará tu reembolso de acuerdo con los términos y condiciones aplicables a tu estado.',
  'the_merchant_you_purchased_your_product_from_will_ship_you_a_new_product_as_soon_as_possible':
    'El comerciante donde compraste el producto te enviará un nuevo producto lo antes posible.',
  'if_your_original_product_is_no_longer_available_you_will_receive_a_store_credit_for_the_same_purchase_price':
    'Si tu producto original ya no está disponible, recibirás un crédito de tienda por el mismo precio de compra.',
  'please_note_once_reguard_fully_replaces_your_item_your_original_protection_plan_will_be_void':
    'Ten en cuenta que una vez que Reguard reemplace completamente tu artículo, tu plan de protección original quedará anulado.',
  'included_within_the_kit_are_instructions_for_successful_stain_removal':
    'El kit incluye instrucciones para eliminar las manchas con éxito.',
  'you_should_also_refer_to_the_directions_on_how_to_best_take_care_of_the_type_of_product_you_own':
    'También debes consultar las indicaciones sobre cómo cuidar mejor el tipo de producto que tienes.',
  'status': 'Estado:',
  'something_went_wrong': 'Algo salió mal al cargar las fotos.',
  'submitted_photos': 'Fotos enviadas',
  'no_photos_submitted': 'No se han enviado fotos',
  'load_all': 'Cargar todas',
  'see_claim_details': 'Ver detalles de la reclamación',
  'choose': 'Elegir',
  'for_merchants': 'para Comerciantes',
  'claim_status': 'Estado de la reclamación',
  'claim_details': 'Detalles de la reclamación',
  'contract_id': 'ID del contrato',
  'date_of_submission': 'Fecha de envío',
  'failure_notes': 'Notas de fallo',
  'no_photos_added': 'No se han añadido fotos',
  'verify_information': 'Verifica tu información, por favor',
  'enter_information':
    'Por favor, ingresa la información que proporcionaste al momento de la compra para que podamos localizar tu pedido.',
  'sorry': '¡Lo sentimos!',
  'back': 'Atrás',
  'code_sent': '¡Código enviado!',
  'session_expired': 'Session Expired. Please login.',
  'resend_code': 'Reenviar código',
  'valid_email_format':
    'Por favor, ingresa un formato válido: john.doe@example.com',
  'valid_phone_format': 'Por favor, ingresa un formato válido: 999-999-9999',
  'dashboard': 'Panel de control',
  'warranty_plans': 'Planes de garantía',
  'reports': 'Informes',
  'view_all_claims': 'Ver todas las reclamaciones',
  'back_to_verify_contact_info':
    'Volver para verificar la información de contacto',
  'photos_title': 'Fotos',
  'verify_email': 'Verifica tu dirección de correo electrónico',
  'reviewing_claim':
    'Estamos revisando tu reclamación y te responderemos pronto.',
  'claim_approved':
    '¡Felicitaciones, tu reclamación ha sido aprobada! Ahora está siendo procesada por el equipo de Servicio al Cliente de Reguard.',
  'in_home_repair':
    'Después de revisar cuidadosamente tu reclamación, el equipo de Servicio al Cliente de Reguard ha determinado que la reparación en el hogar es la mejor solución.',
  'reimbursement':
    'Después de revisar cuidadosamente tu reclamación, el equipo de Servicio al Cliente de Reguard ha determinado que el reembolso de tu(s) artículo(s) es la mejor solución.',
  'replacement':
    'Después de revisar cuidadosamente tu reclamación, el equipo de Servicio al Cliente de Reguard ha determinado que la reposición de tus artículos es la mejor solución.',
  'cleaning_kit':
    'Después de revisar cuidadosamente tu reclamación, el equipo de Servicio al Cliente de Reguard ha determinado que enviarte un kit de limpieza es la mejor solución.',
  'is_mandatory': 'es obligatorio',
  'add_your_phone_number': 'Agrega tu número de teléfono.',
  'add_your_address': 'Agrega tu dirección.',
  'back_to_chatbot': 'Volver al chatbot',
  'learn_more_about_reguard_privacy_policy':
    'Obtén más información sobre la Política de Privacidad de Reguard',
  'Crayon, ink and marker': 'Crayón, tinta y marcador',
  'reguard_chat_recording_and_history':
    'Reguard puede grabar el siguiente chat con fines de control de calidad y entrenamiento. También guardamos el historial de tu chat y lo relacionamos con las compras.',
  'resend_it': 'reenviarlo.',
  'restart_chat': 'Reiniciar chat.',
  'claim_saved_draft': 'Borrador guardado',
  'claim_discarded_draft': 'Borrador descartado',
  'claim_submitted': 'Reclamación enviada',
  'password_saved': 'Contraseña guardada',
  'plan_highlights': 'Aspectos destacados del plan',
  'coverage': '{{duración}} {{periodo}} de cobertura',
  'categories': 'Categorías',
  'settings': 'Configuraciones',
  'user_management': 'Gestión de usuarios',
  'protection_plans': 'Planes de protección',
  'training_portal': 'Portal de entrenamiento',
  'active_training_portal': 'Portal de entrenamiento activo',
  'training_profile_not_found': 'No se encontró el perfil de entrenamiento',
  'you_need_to_ask_an_admin_to_activate_your_training_account':
    'Necesitas pedirle a un administrador que active tu cuenta de entrenamiento',
  'user_permissions': 'Permisos de usuario',
  'all_users': 'Todos los usuarios',
  'Checking, cracking, bubbling, peeling of finish, or lifting of veneers (from a specific incident)':
    'Checking, cracking, bubbling, peeling of finish, or lifting of veneers (from a specific incident)',
  'search_name_email_role_phone_number':
    'Buscar nombre, correo electrónico, rol, número de teléfono...',
  'updated_at': 'Actualizado en',
  'role': 'Rol',
  'no_record_found': 'No se encontraron registros',
  'back_to_home': 'Volver a inicio',
  'your_password_has_been_successfully_set':
    'Tu contraseña se ha configurado correctamente.',
  'password_set': '¡Contraseña configurada!',
  'All over': 'En todo el producto',
  'Left arm': 'Brazo izquierdo',
  'Right arm': 'Brazo derecho',
  'Both arms': 'Ambos brazos',
  'Back cushion': 'Cojín de respaldo',
  'Bed post': 'Poste de cama',
  'Console': 'Consola',
  'Crossbar / Stretcher': 'Barra cruzada / Travesaño',
  'Deck area': 'Área de la plataforma',
  'Door': 'Puerta',
  'Drawer': 'Cajón',
  'Electrical part': 'Parte eléctrica',
  'Footboard / Rest': 'Pie de cama / Descanso',
  'Frame': 'Marco',
  'Headboard / Rest': 'Cabecera / Descanso',
  'Inside back': 'Interior de respaldo',
  'Leg': 'Pata',
  'Lock': 'Cerradura',
  'Mechanism': 'Mecanismo',
  'Motor': 'Motor',
  'Outside back': 'Exterior de respaldo',
  'Pull handle': 'Asa de extracción',
  'Remote control': 'Control remoto',
  'Seat bench': 'Banco de asiento',
  'Seat cushion': 'Cojín de asiento',
  'Shelf': 'Estante',
  'Side rails': 'Rieles laterales',
  'Springs': 'Resortes',
  'Throw pillow': 'Almohada decorativa',
  'Top': 'Superficie superior',
  'Wedge': 'Cuña',
  'Transit': 'Tránsito',
  'Assembly': 'Montaje',
  'Movement between residences': 'Movimiento entre residencias',
  'Redelivery': 'Reentrega',
  'Storage or while in storage': 'Almacenamiento o durante el almacenamiento',
  'Delivery': 'Entrega',
  'Normal residential usage': 'Uso residencial normal',
  'Unknown': 'Desconocido',
  'Human and pet bodily fluids': 'Fluidos corporales humanos y de mascotas',
  'Food and beverage': 'Alimentos y bebidas',
  'Facial cosmetics': 'Cosméticos faciales',
  'account_number': 'Número de cuenta.',
  'Nail polish': 'Esmalte de uñas',
  'Grease and oil': 'Grasa y aceite',
  'Paint and dye': 'Pintura y tinte',
  'Crayon': 'Tinta y marcador, Crayón, tinta y marcador',
  'Breakage of mechanisms': 'Rotura de mecanismos',
  'Grass and mud': 'Hierba y barro',
  'Dye transfer (color from blue jeans or newspaper)':
    'Transferencia de tintes (color de jeans azules o periódico)',
  'Household heat marks and burns': 'Marcas y quemaduras por calor en el hogar',
  'Punctures and rips (excluding seams and pet damage)':
    'Perforaciones y desgarros (excluyendo costuras y daños de mascotas)',
  'Fading from the sun': 'Decoloración por el sol',
  'Failure of motors and associated components':
    'Fallo de motores y componentes asociados',
  'Checking':
    'Agrietamiento, ampollamiento, desprendimiento de acabado o despegue de chapas (por un incidente específico)',
  'Glass breakage': 'Rotura de cristales',
  'Scratch or gouge (penetrating through surface)':
    'Rasguños o muescas (que penetran en la superficie)',
  'Sofa': 'Sofá',
  'Futons': 'Futones',
  'Ottomans': 'Otomanas',
  'Living room sets': 'Juegos de sala',
  'Love seats': 'Sillones de dos plazas',
  'Sectional sofas': 'Sofás seccionales',
  'Reclining furniture': 'Muebles reclinables',
  'Recliners': 'Reclinadores',
  'Coffee tables': 'Mesas de centro',
  'End & side tables': 'Mesas auxiliares y laterales',
  'TV stands': 'Soportes para TV',
  'Sofa & loveseat sets': 'Juegos de sofá y sillón de dos plazas',
  'Coffee & end table sets': 'Juegos de mesa de café y auxiliares',
  'Accent chairs': 'Sillas de acento',
  'Rugs': 'Tapetes',
  'Water or beverage marks or rings': 'Marcas o anillos de agua o bebidas',
  'Rips, tears, punctures, burns and singe marks':
    'Desgarros, perforaciones, quemaduras y marcas de quemaduras, desgarros, perforaciones, quemaduras y marcas de quemaduras',
  'Glass chipping': 'Rotura o agrietamiento, astillado o rotura de cristales',
  'Glass chipping, breakage or cracking':
    'Astillas, roturas o grietas en el vidrio',
  "Breakage of mechanisms (upon expiration of the manufacturer's warranty), Including parts and labor":
    'Incluyendo piezas y mano de obra, Rotura de mecanismos (al expirar la garantía del fabricante), Incluyendo piezas y mano de obra, Soldaduras y uniones ensambladas',
  'Outdoor sectionals': 'Seccionales de exterior',
  'Outdoor sofas & Loveseats': 'Sofás y sillones de exterior',
  'Patio chairs': 'Sillas de patio',
  'Lounge chairs': 'Tumbonas',
  'Outdoor benches': 'Bancos de exterior',
  'Adirondack chairs': 'Sillas Adirondack',
  'Outdoor rocking chairs': 'Sillas mecedoras de exterior',
  'Conversation sets': 'Conjuntos de conversación',
  'Outdoor seating sets': 'Conjuntos de asientos de exterior',
  'Outdoor dining sets': 'Conjuntos de comedor de exterior',
  'Outdoor bar sets': 'Conjuntos de bar de exterior',
  'Outdoor bistro sets': 'Conjuntos de bistro de exterior',
  'Outdoor dining chairs': 'Sillas de comedor de exterior',
  'Outdoor dining tables': 'Mesas de comedor de exterior',
  'Motors': 'Motores',
  'Mechanisms (lndlnlng redlnlng, heating and vibrating)':
    'Mecanismos (incluyendo reclinación, calefacción y vibración)',
  'Electrical components (Including motors and wiring)':
    'Componentes eléctricos (incluyendo motores y cableado)',
  'Controllers': 'Controladores',
  'Power surge': 'Sobretensión eléctrica',
  "Parts and labor included (upon expiration of manufacturer's warranty)":
    'Piezas y mano de obra incluidas (al expirar la garantía del fabricante)',
  'Twin': 'Individual',
  'Twin XL': 'Individual XL',
  'Full': 'Doble',
  'Queen': 'Reina',
  'King': 'Rey',
  'California king': 'Rey de California',
  'Crib': 'Cuna',
  'Memory foam': 'Espuma de memoria',
  'Mattress in a box': 'Colchón en una caja',
  'Innerspring': 'Muelles internos',
  'Hybrid': 'Híbrido',
  'Air mattresses & foldable beds': 'Colchones de aire y camas plegables',
  'Mirror loss of slivering or chipping':
    'Pérdida de espejo por desprendimiento o astillado',
  'Welds and assembled joints': 'Soldaduras y uniones ensambladas',
  'account_number_required': 'Se requiere el número de cuenta',
  'creation_date': 'Fecha de Creación',
  'customer_email': 'Correo electrónico del cliente',
  'customer_email_required': 'Se requiere el correo electrónico del cliente',
  'customer_first_name': 'Nombre del cliente',
  'customer_first_name_required': 'Se requiere el nombre del cliente',
  'customer_information': 'Información del cliente',
  'customer_last_name': 'Apellido del cliente',
  'customer_last_name_required': 'Se requiere el apellido del cliente',
  'delivery_date': 'Fecha de entrega',
  'item_amount': 'Cantidad del artículo',
  'item_amount_required': 'Se requiere la cantidad del artículo',
  'item_id_required': 'Se requiere el ID del artículo',
  'market': 'Mercado',
  'no_results': 'No hay resultados para mostra',
  'order_submitted': '¡Orden enviada!',
  'product_description': 'Descripción del producto',
  'product_description_required': 'Se requiere la descripción del producto',
  'product_name_required': 'Se requiere el nombre del producto',
  'product_price': 'Precio del producto',
  'product_sku': 'SKU del producto',
  'product_vendor': 'Proveedor del producto',
  'protection_plan_price': 'Precio del plan de protección',
  'quantity': 'Cantidad',
  'region': 'Región',
  'sales_date': 'Fecha de venta',
  'sales_date_required': 'Se requiere la fecha de venta',
  'ship_address': 'Dirección de envío',
  'ship_address_required': 'Se requiere la dirección de envío',
  'ship_city': 'Ciudad de envío',
  'ship_city_required': 'Se requiere la ciudad de envío',
  'ship_state_required': 'Se requiere el estado de envío',
  'ship_zip': 'Código postal de envío',
  'ship_zip_required': 'Se requiere el código postal de envío',
  'store_location': 'Ubicación de tienda',
  'store_performance': 'Rendimiento de Tienda',
  'store_number': 'Número de tienda',
  'store_number_required': 'Se requiere el número de tienda',
  'warranty_amount': 'Monto de garantía',
  'warranty_amount_greater': 'El monto de la garantía debe ser mayor a 0',
  'warranty_amount_required': 'Se requiere el monto de la garantía',
  'warranty_sku_required': 'Se requiere el SKU de la garantía',
  'error_message': 'Mensaje de error',
  'maximum_number_of_photos_reached':
    'Se ha alcanzado el número máximo de fotos',
  'add_more_photos': 'Agregar más fotos',
  'if_you_have_it_please_provide_a_photo_or_copy_of_your_receipt_from_the_merchant':
    'Si lo tienes, por favor proporciona una foto o copia de tu recibo del comerciante.',
  'please_note_these_are_all_the_products_potentially_covered_by_your_reguard_plan':
    'Por favor, ten en cuenta que estos son todos los productos potencialmente cubiertos por tu plan Reguard.',
  'your_receipt': 'Tu recibo',
  'full_receipt_clear_captured': 'Recibo completo, capturado claramente',
  'file_uppercase': 'ARCHIVO',
  'drop_the_file_here': 'Arrastra el archivo aquí',
  'supported_format_doc_jpg_png_pdf': 'Formato admitido: Doc, JPG, PNG, PDF',
  'anomalies': 'Anomalías',
  'create_order': 'Crear pedido',
  'plan': 'Plan de protección',
  'plans_quantity': 'Cantidad de Programas',
  'filter': 'Filtrar',
  'download_as_csv': 'Descargar desde CSV',
  'gross_revenue': 'Ingresos Brutos',
  'holding': 'posesión',
  'of_plans_sold': 'total # de planes vendidos',
  'are_you_sure_delete_contracts':
    '¿Estás seguro de que quieres eliminar estos contratos?',
  'contract_anomaly_deletion_status_success':
    '¡Contratos eliminados exitosamente!',
  'contract_anomaly_deletion_status_error': '¡Error al eliminar los contratos!',
  'error_while_filtering_merchants': '¡Error al filtrar comerciantes!',
  'sales': 'Ventas',
  'sales_sub_header': 'Con el tiempo, por comerciante',
  'fpp_sales': 'Ventas FPP',
  'eligible_orders': 'Órdenes de FPP Elegibles (#)',
  'total_sales': 'Ventas Totales',
  'merchant_total_sales': 'Ventas Totales del Comerciante',
  'attachment_rate': 'FPP Tasa de Adjuntar',
  'attachment_rate_description': '(% de Pedidos Elegibles)',
  'existing_claim': 'Reclamo Existente',
  'cancellation_claim': 'Reclamo de Cancelación',
  'other': 'Otro',
  'new_claim': 'Nuevo Reclamo',
  'total_calls': 'Total de Llamadas',
  'total_emails': 'Total de Correos Electrónicos',
  'handle_time': 'Tiempo Promedio de Manejo',
  'speed_of_answer': 'Velocidad Promedio de Respuesta',
  'emails': 'correos electrónicos',
  'avg_answer_speed': 'Velocidad Promedio de Respuesta',
  'avg_call_duration': 'Duración Promedio de Llamada',
  'active_claims': 'Reclamos Activos',
  'acceptance_ratio': 'Ratio de Aceptación',
  'oem_handoff': 'Traspasos a OEM',
  'claims_severity': 'Gravedad Promedio de Reclamaciones',
  'claims_filed_online': 'Reclamos Presentados en Línea',
  'interaction_reasons': 'Razones de Interacción',
  'error_filtering_by_merchant': 'Error al filtrar el informe por comerciante',
  'error_filtering_report_by_date': 'Error al filtrar el informe por fecha.',
  'error_retrieving_report_data': 'Error al obtener los datos del informe',
  'units_sold': 'Unidades Vendidas',
  'reguard_gross_revenue': 'Ingresos Brutos de Reguard',
  'ashley_fpp_sales': 'Ventas FPP de Ashley',
  'sign_in_to_account': 'Iniciar sesión en tu cuenta',
  'email_address': 'Dirección de correo electrónico',
  'not_valid_email_address': 'Dirección de correo electrónico no válida.',
  'forgot_pass': '¿Olvidaste tu contraseña?',
  'not_valid_password': 'Contraseña no válida.',
  'select_a_tab': 'Seleccionar una pestaña',
  'click_an_edit_button':
    'Haga clic en un botón EDITAR para comenzar a realizar cambios.',
  'results': 'resultados',
  'if_matching_account':
    'Si se encontró una cuenta coincidente, se envió un correo electrónico a <1>{{checkEmail}}</1> para permitirte restablecer tu contraseña.',
  'servicing': 'Mantenimiento',
  'combined_report': 'Tasa de Adjuntos por Región / Canal / Mercado',
  'prior_week': 'Semana Anterior',
  'prior_month': 'Mes Anterior',
  'fpp_sales_per_week': 'Ventas por Semana (Unidades FPP Vendidas)',
  'cancellation': 'Cancelación',
  'claim_inquiry': 'Consulta de Reclamo',
  'furniture_purchased_prior_to_support':
    'Mueble Comprado Antes de la Asistencia',
  'resolution_type': 'Tipo de Resolución',
  'approved_resolutions': 'Resolución de Reclamaciones Pagadas Aprobadas',
  'cleaning_kits': 'Kits de Limpieza',
  'not_eligible_for_coverage': 'No Elegible para Cobertura',
  'repair': 'Reparación',
  'repair_with_extra_parts': 'Reparación con Piezas Adicionales',
  'full_replacement': 'Reemplazo Completo',
  'Ashley Service Technicians, OEM and Reimbursements': 'Ashley',
  'executive_overview': 'Resumen Ejecutivo',
  'executive_overview_description':
    'Ventas, Servicio al Cliente y Reclamaciones',
  'executive_performance': 'Rendimiento Ejecutivo',
  'executive_performance_description':
    'Rendimiento de Ventas de Reguard a lo Largo del Tiempo',
  'sales_description':
    'Evalúe las ventas en cualquier nivel de la organización',
  'no_results_found': 'No Se Encontraron Resultados',
  'accounts': 'Buscar y Gestionar Cuentas de Usuario',
  'dashboards_description':
    'Ver métricas y KPI en cada nivel de la plataforma Reguard',
  'management_description':
    'Realizar las tareas necesarias para mantener cuentas y contratos de Reguard',
  'crunching_numbers_title': 'Procesando los Números',
  'crunching_numbers_description':
    'Vuelve pronto para obtener números actualizados',
  'KINGSWERE FURNITURE LLC': 'Kingswere',
  'ROCKLEDGE FURNITURE LLC': 'Rockledge',
  'SOUTHWEST FURN OF WI LLC': 'Southwest',
  'STONELEDGE FURNITURE, LLC': 'Stoneledge',
  'reset_your_password': 'Restablecer tu contraseña',
  'enter_your_email_address': 'Ingresa tu dirección de correo electrónico',
  'page_not_found': 'Página no encontrada',
  'page_not_found_subtitle':
    'Lo siento, no pudimos encontrar la página que estás buscando.',
  'go_back_home': 'Volver a la página de inicio',
  'customer_address_information': 'Información de la dirección del cliente',
  'address_not_available': 'Dirección no disponible',
  'phone_number_saved': 'Tu número de teléfono ha sido guardado',
  'please_enter_primary_phone':
    'Por favor, ingrese su número de teléfono principal',
  'ipa_orders': 'IPA - Gestión de Anomalías',
  'ipa_order_details': 'Detalles del Pedido IPA',
  'ipa_add_order': 'Añadir Pedido IPA',
  'products_insert': 'inserción de productos',
  'import_contract': 'contrato de importación',
  'cancel_search': 'cancelar búsqueda',
  'addresses_insert': 'inserción de direcciones',
  'duplicate_search': 'búsqueda de duplicados',
  'check_pcmi_category': 'verificar categoría pcmi',
  'ingested': 'ingerido',
  'address_validation': 'validación de dirección',
  'manually_updated': 'actualizado manualmente',
  'first_occurrence': 'Primera Ocasión',
  'latest_occurrence': 'Última Ocasión',
  'customer': 'Cliente',
  'store': 'Tienda',
  'current_step': 'Paso Actual',
  'item': 'Artículo',
  'sku': 'Sku',
  'amount': 'Monto',
  'add_manual_occurrence': 'Agregar Ocasión Manual',
  'merchant_sales': 'Ventas de Comerciantes',
  'performance_overview_by_protection_plan_and_location':
    'Resumen del Rendimiento por Plan de Protección y Ubicación',
  'gross_revenue_stat': 'Ingresos Brutos',
  'store_id': 'ID de la Tienda',
  'location': 'Ubicación',
  'by_location_total_sales': 'Ventas Totales',
  'units_sold_#': 'Unidades Vendidas #',
  'protection_plan': 'Plan de Protección',
  'protection_plans_overview': 'Resumen de Planes de Protección',
  'pp_total_sales': 'Ventas Totales',
  '%_of_total': '% del Total',
  'by_quantity': 'Por Cantidad',
  'by_sales': 'Por Ventas',
  'top_performer': 'El Mejor en Redimiento',
  'top_individual_performer': 'Mejor Rendimiento Individual',
  'bottom_performer': 'El Menor en Rendimiento',
  'percent_sales': '% Ventas',
  't_total_sales': 'Ventas Totales',
  'total': 'Total',
  'warranty_total': 'Total de Garantía',
  'item_total': 'Total de Artículos',
  'activity': 'Actividad',
  'add_a_comment': 'Añadir un comentario',
  'comment': 'Comentario',
  'ipa': 'Procesador',
  'SUCCESS': 'éxito en',
  'FAILED': 'falló en',
  'CREATED': 'orden creada',
  'no_status': 'Sin Estado',
  'ago': 'hace',
  'manual_update': 'actualización manual',
  'commented:': 'comentado:',
  'sales_by_location': 'Ventas por Ubicación',
  'go': 'DALE!',
  'merchant_sales_description': 'Métricas y KPI para pedidos de comerciantes',
  'qty': 'Qty.',
  'warranty_sku': 'SKU de Garantía',
  'add_item': 'Agregar Artículo',
  'sale_date': 'Fecha de Venta',
  'activity_not_found': 'Actividad No Encontrada',
  'no_data_available': 'No hay datos disponible',
  'complete': 'Completo',
  'activate_orders': 'Activar Órdenes',
  'selected_orders': 'Órdenes Seleccionadas',
  'close_panel': 'Cerrar Panel',
  'download_orders': 'Descargar Órdenes',
  'selected_orders_by_integration': 'Órdenes Seleccionadas por Integración',
  'of': 'de',
  'my_policies': 'Mis Políticas',
  'my_policy': 'Mi Política',
  'lost_package': 'Paquete Perdido',
  'pending_policies': 'Pólizas Pendientes',
  'active_policies': 'Pólizas Activas',
  'expired_policies': 'Pólizas Expiradas',
  'shipping_status': 'Estado del Envío',
  'tracking': 'Seguimiento',
  'in_transit': 'En Tránsito',
  'delivered': 'Entregado',
  'policy_status': 'Estado de la Póliza',
  'order': 'Orden',
  'processing': 'Procesando',
  'switch_to': 'Cambiar a',
  'too_many_requests': '¡Demasiadas solicitudes para este usuario!',
  'please_wait_30_minutes':
    'Por favor, espere 30 minutos antes de intentarlo de nuevo.',
  'policy_details': 'Detalles de la Póliza',
  'please_note_a_policy':
    'Tenga en cuenta que una póliza se activa después de la entrega o al notificar una pérdida.',
  'expires_in': 'Expira en',
  'certificate_of_insurance': 'Certificado de Seguro',
  'coverage_details': 'Detalles de la Cobertura',
  'days': 'Días',
  'hours': 'Horas',
  'not_yet_assigned': 'Aún No Asignado',
  'event_details': `"Détails·de·l'Événement"`,
  'date_of_incident': `"Date·de·l'Incident"`,
  'loss_type': 'Type de Perte',
  'sheets': {
    overview: 'Resumen',
    performance_outliers: 'Anomalías de Rendimiento',
    protection_plans: 'Planes de Protección',
    sales_by_location: 'Ventas por Ubicación',
    filenames: {
      all_reports: 'Todos los Informes',
      top_performer: 'Mejor Rendimiento',
      bottom_performer: 'Peor Rendimiento',
      protection_plans: 'Planes de Protección',
      sales_by_location: 'Ventas por Ubicación',
    },
    headers: {
      name: 'Nombre',
      amount: 'Cantidad',
      last: 'Último',
      prev: 'Anterior',
      change: 'Cambio',
      percent: 'Porcentaje',
      group: 'Grupo',
      value: 'Valor',
      location: 'Ubicación',
      salesLocation: 'Ubicación de Ventas',
      protection_plan: 'Plan de Protección',
      protection_plan_id: 'ID del Plan',
      quantity: '# de Planes Vendidos',
      wholesale_value: 'Valor al Por Mayor',
      total_sales_ratio: '% de Planes Vendidos',
    },
  },
  'attachment_rate_stat': 'Tasa de adjuntos',
  'cancellation_rate_stat': 'Tasa de cancelación',
  'balance_of_share_stat': 'Balance de participación',
  'written_sales_stat': 'Ventas escritas',
  'written_revenue_stat': 'Ingresos escritos',
  'invoiced_sales_stat': 'Ventas facturadas',
  'invoiced_revenue_stat': 'Ingresos facturados',
  'merchant_plan_dashboard': 'Tablero de plan del comerciante',
  'receipt': 'Recibo',
  'cancelled_header': 'Cancelado',
  'attach_header': 'Adjunto',
  'bos_header': 'BoS',
  'sales_summary': 'Resumen de Ventas',
  'rsa_sales_summary': 'Resumen de Ventas RSA',
  'sales_summary_by_plan': 'Resumen de Ventas por Plan',
  'mark_as_delivered': 'Marcar como entregado',
  'select_the_delivery_date': 'Seleccione la fecha de entrega',
  'pending_activation': 'Pendiente de Activación',
  'contact_support': 'Contactar Soporte',
  'get_in_touch': 'Ponerse en Contacto',
  'contact_support_text': 'Por favor, contáctenos respecto al pedido #',
  'phone_label': 'Teléfono',
  'masquerading': 'Suplantación',
  'contracts_description': '',
  'users_description': '',
};
