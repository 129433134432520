export const buttonSelectors = {
  backBtn: 'backBtn',
  backToProfileBtn: 'backToProfileBtn',
  cancelBtn: 'cancelBtn',
  continueBtn: 'continueButton',
  deleteBtn: 'deleteBtn',
  fileClaimBtn: 'fileClaimBtn',
  loginButton: 'loginButton',
  passwordBtn: 'passwordBtn',
  profileBtn: 'profileBtn',
  resendCodeBtn: 'resendCodeBtn',
  saveAndCloseBtn: 'saveAndCloseBtn',
  saveChangesBtn: 'saveChangesBtn',
  saveDraftBtn: 'saveDraftBtn',
  submitBtn: 'submitBtn',
  uploaderBtn: 'uploaderBtn',
  viewTermsBtn: 'viewTermsBtn',
};

export const mainPageSelectors = {
  activePlansText: 'activePlans',
  mainPage: 'mainPage',
};

export const navigationLinksSelectors = {
  logoutLink: 'logoutLink',
};
