import { Endpoint, endpointConstructor } from '../index.js';

export interface OrderEndpoints {
  postOrders: Endpoint;
  searchOrders: Endpoint;
}

const orderEndpoints = (): OrderEndpoints => {
  const { endpoint } = endpointConstructor('/orders');
  return {
    postOrders: endpoint('POST'),
    searchOrders: endpoint('POST', '/search'),
  };
};

export default orderEndpoints;
