import type { MouseEventHandler } from 'react';
import { ReguardMobileMenu } from '@cortex/myaccount/views/shared/ReguardMobileMenu';
import { SafeShipMobileMenu } from '@cortex/myaccount/views/shared/SafeShipMobileMenu';

interface MobileMenuProps {
  onLogout: MouseEventHandler;
  onDismiss: MouseEventHandler;
  isMenuVisible: boolean;
}

export const MobileMenu = (props: MobileMenuProps) => {
  return (
    <div className="fixed top-0 left-0 overflow-hidden z-10 right-0">
      <div
        className={`w-screen ${
          props.isMenuVisible ? 'h-screen' : 'h-0'
        } bg-reguard-indigo slide-mobile-menu`}
      >
        <ReguardMobileMenu
          onDismiss={props.onDismiss}
          onLogout={props.onLogout}
        />
        <SafeShipMobileMenu
          onDismiss={props.onDismiss}
          onLogout={props.onLogout}
        />
      </div>
    </div>
  );
};
