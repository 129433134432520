import { Endpoint, endpointConstructor } from '../index.js';

export interface ReportEndpoints {
  getKPI: Endpoint;
  getAllReports: Endpoint;
  getMerchantReportedKpi: Endpoint;
  getAttachmentRate: Endpoint;
  getAzureADToken: Endpoint;
  getEligibleSales: Endpoint;
  getFppSales: Endpoint;
  getReportsClaimsData: Endpoint;
  getReportsContractsData: Endpoint;
  getReportsSalesData: Endpoint;
  getReportsUnknownSalesData: Endpoint;
  getTotalSales: Endpoint;
  getHasAvailableImportData: Endpoint;
  filterReportsByMerchant: Endpoint;
}

const reportEndpoints = () => {
  const { endpoint } = endpointConstructor('/reports');
  return {
    getKPI: endpoint('POST', '/kpi'),
    getMerchantReportedKpi: endpoint('POST', '/get-merchant-reported-kpi'),
    getAllReports: endpoint('POST', '/get-reports'),
    getAttachmentRate: endpoint('POST', '/attachment-rate'),
    getAzureADToken: endpoint('GET', '/token'),
    getEligibleSales: endpoint('POST', '/eligible-sales'),
    getFppSales: endpoint('POST', '/fpp-sales'),
    getReportsClaimsData: endpoint('GET', '/claims'),
    getReportsContractsData: endpoint('GET', '/contracts'),
    getReportsSalesData: endpoint('GET', '/sales'),
    getReportsUnknownSalesData: endpoint('GET', '/unknown-sales'),
    getTotalSales: endpoint('POST', '/total-sales'),
    getHasAvailableImportData: endpoint('POST', '/has-available-import-data'),
    filterReportsByMerchant: endpoint('POST', '/filter-by-merchant'),
  };
};

export default reportEndpoints;
