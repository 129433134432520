import { Endpoint, endpointConstructor } from '../index.js';

export interface AccountsEndpoints {
  getAccounts: Endpoint;
  getAccount: Endpoint;
  searchAccounts: Endpoint;
  updateAccount: Endpoint;
}

const accountsEndpoints = (): AccountsEndpoints => {
  const { endpoint } = endpointConstructor('/accounts');
  return {
    getAccounts: endpoint('GET', '/'),
    getAccount: endpoint('GET', '/:id'),
    searchAccounts: endpoint('POST', '/search'),
    updateAccount: endpoint('PUT', '/:id'),
  };
};

export default accountsEndpoints;
