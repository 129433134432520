export const navigation = {
  page: {
    title: 'Welcome to Reguard Admin',
  },
  dashboards: {
    name: 'Dashboards',
    description: 'View metrics and kpi at every level of the Reguard platform',
  },

  management: {
    name: 'Management',
    description:
      'Perform the tasks necessary to maintain Reguard accounts and contracts',
  },

  merchants: {
    name: 'Merchants',
    description: 'Manage your contracts and more',
    contracts_list: 'Your Contracts',
    contracts_list_description: 'View all your contracts and their details',
    contract_create: 'Create a Contract',
    contract_create_description:
      'Create a new contract that will be processed by the reguard pipeline',
  },
};
