import accountsEndpoints, {
  AccountsEndpoints,
} from './src/accounts.endpoints.js';
import authEndpoints, { AuthEndpoints } from './src/auth.endpoints.js';
import botEndpoints, { BotEndpoints } from './src/bot.endpoints.js';
import captureEndpoints, { CaptureEndpoints } from './src/capture.endpoints.js';
import claimEndpoints, { ClaimEndpoints } from './src/claim.endpoints.js';
import contractEndpoints, {
  ContractEndpoints,
} from './src/contract.endpoints.js';
import contractAnomaliesEndpoints, {
  ContractAnomaliesEndpoints,
} from './src/contractAnomalies.js';
import domainEndpoints, { DomainEndpoints } from './src/domain.endpoints.js';
import healthEndpoints, { HealthEndpoints } from './src/health.endpoints.js';
import localesEndpoints, { LocalesEndpoints } from './src/locales.endpoints.js';
import merchantEndpoints, {
  MerchantEndpoints,
} from './src/merchant.endpoints.js';
import orderEndpoints, { OrderEndpoints } from './src/order.endpoints.js';
import plansEndpoints, { PlansEndpoints } from './src/plans.endpoints.js';
import productEndpoints, { ProductEndpoints } from './src/product.endpoints.js';
import purchaseEndpoints, {
  PurchaseEndpoints,
} from './src/purchase.endpoints.js';
import reportEndpoints, { ReportEndpoints } from './src/report.endpoints.js';
import testEndpoints, { TestEndpoints } from './src/test.endpoints.js';
import trainingEndpoints, {
  TrainingEndpoints,
} from './src/training.endpoints.js';
import userEndpoints, { UserEndpoints } from './src/user.endpoints.js';
import webhookEndpoints, { WebhookEndpoints } from './src/webhook.endpoints.js';

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export interface Endpoint {
  path: string;
  method: HttpMethod;
}

export interface RouterService {
  authEndpoints: () => AuthEndpoints;
  localesEndpoints: () => LocalesEndpoints;
  claimEndpoints: () => ClaimEndpoints;
  contractEndpoints: () => ContractEndpoints;
  healthEndpoints: () => HealthEndpoints;
  plansEndpoints: () => PlansEndpoints;
  purchaseEndpoints: () => PurchaseEndpoints;
  userEndpoints: () => UserEndpoints;
  webhookEndpoints: () => WebhookEndpoints;
  reportEndpoints: () => ReportEndpoints;
  domainEndpoints: () => DomainEndpoints;
  botEndpoints: () => BotEndpoints;
  trainingEndpoints: () => TrainingEndpoints;
  merchantEndpoints: () => MerchantEndpoints;
  captureEndpoints: () => CaptureEndpoints;
  testEndpoints: () => TestEndpoints;
  orderEndpoints: () => OrderEndpoints;
  productEndpoints: () => ProductEndpoints;
  accountsEndpoints: () => AccountsEndpoints;
  contractAnomaliesEndpoints: () => ContractAnomaliesEndpoints;
}

const initEndpoints = (): RouterService => ({
  authEndpoints,
  localesEndpoints,
  claimEndpoints,
  contractEndpoints,
  healthEndpoints,
  plansEndpoints,
  purchaseEndpoints,
  webhookEndpoints,
  reportEndpoints,
  userEndpoints,
  domainEndpoints,
  botEndpoints,
  trainingEndpoints,
  merchantEndpoints,
  captureEndpoints,
  testEndpoints,
  orderEndpoints,
  productEndpoints,
  accountsEndpoints,
  contractAnomaliesEndpoints,
});

export const endpointConstructor = (baseUrl: string) => {
  return {
    endpoint: (method: HttpMethod, urn = '') =>
      <Endpoint>{
        path: baseUrl + urn,
        method,
      },
  };
};

export type {
  AuthEndpoints,
  LocalesEndpoints,
  ClaimEndpoints,
  ContractEndpoints,
  HealthEndpoints,
  PlansEndpoints,
  PurchaseEndpoints,
  UserEndpoints,
  WebhookEndpoints,
  DomainEndpoints,
  BotEndpoints,
  TrainingEndpoints,
  CaptureEndpoints,
  TestEndpoints,
  OrderEndpoints,
  ProductEndpoints,
  AccountsEndpoints,
  ContractAnomaliesEndpoints,
};

export default initEndpoints;
