import flagsmith from 'flagsmith';
import { FlagsmithProvider as FlagsmithProviderComponent } from 'flagsmith/react';
import { IFlags } from 'flagsmith/types';

interface RawFlag {
  id: number;
  feature: {
    id: number;
    name: string;
    created_date: string;
    description: string | null;
    initial_value: string | null;
    default_enabled: boolean;
    type: string;
  };
  feature_state_value: string | null;
  environment: number;
  identity: string | null;
  feature_segment: string | null;
  enabled: boolean;
}

interface FlagsmithProviderProps {
  rawFlags: RawFlag[];
  environmentID: string;
  api: string;
  children: React.ReactElement | React.ReactElement[];
  identity?: string;
}

export function FlagsmithProvider({
  environmentID,
  api,
  rawFlags,
  children,
  identity,
}: FlagsmithProviderProps) {
  const defaultFlags = {} as IFlags<string>;

  // map json format to correct flags object
  rawFlags.forEach(flag => {
    defaultFlags[flag.feature.name] = {
      id: flag.id,
      enabled: flag.enabled || false,
      value: flag.feature_state_value || '',
    };
  });

  return (
    <FlagsmithProviderComponent
      flagsmith={flagsmith}
      options={{
        identity,
        defaultFlags,
        environmentID,
        api,
      }}
    >
      {children}
    </FlagsmithProviderComponent>
  );
}
