import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { buttonSelectors } from './tests/dataCySelectors';
import { SmallDialog, SmallDialogButtonMapType } from './SmallDialog';

interface SaveClaimModalProps {
  onClose: () => void;
  handleSave: () => void;
  isSavingStep?: boolean;
}

export const SaveClaimModal: FC<SaveClaimModalProps> = ({
  onClose,
  handleSave,
  isSavingStep,
}) => {
  const { t } = useTranslation();
  return (
    <SmallDialog
      title={t('want_to_save_draft')}
      subtitle={t('save_your_draft_and_complete_later')}
      onClose={onClose}
      buttonsConfig={[
        {
          type: SmallDialogButtonMapType.SAVE_DRAFT,
          handleClick: handleSave,
          isFetching: isSavingStep,
        },
        {
          type: SmallDialogButtonMapType.CANCEL,
          handleClick: onClose,
        },
      ]}
      data_cy={buttonSelectors.saveDraftBtn}
    />
  );
};
