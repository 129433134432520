import type { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ROUTES from '@cortex/myaccount/common/constants/routes';
import { useMyUser } from '@cortex/myaccount/network/api/users';
import SafeShipTextLogoLight from '@cortex/myaccount/views/assets/SafeShipTextLogoLight.svg';

interface MobileMenuProps {
  onLogout: MouseEventHandler;
  onDismiss: MouseEventHandler;
}

export const SafeShipMobileMenu = ({
  onLogout,
  onDismiss,
}: MobileMenuProps) => {
  const { t } = useTranslation();
  const { data: userData } = useMyUser();
  const hasShippingInsurance = userData?.planCount?.SI > 0;

  if (!hasShippingInsurance) return null;

  return (
    <div className="my-10">
      <header className="flex justify-between items-center px-6 p-4">
        <div>
          <div className="w-20">
            <SafeShipTextLogoLight />
          </div>
        </div>
      </header>

      <div className="py-2 px-8 flex flex-col justify-start gap-6 text-lg">
        <div className="w-full px-2 border-reguard-pearl border-b-2">
          <Link
            to={ROUTES.MY_SAFESHIP_POLICIES}
            className="text-reguard-pearl"
            onClick={onDismiss}
          >
            {t('my_policies')}
          </Link>
        </div>

        <div className="w-full px-2 border-reguard-pearl border-b-2">
          <Link
            to={ROUTES.MY_SAFESHIP_CLAIMS}
            className="text-reguard-pearl"
            onClick={onDismiss}
          >
            {t('my_claims')}
          </Link>
        </div>

        <div className="w-full px-2 border-reguard-pearl border-b-2 my-20">
          <Link to="" className="text-reguard-pearl" onClick={onLogout}>
            {t('log_out')}
          </Link>
        </div>
      </div>
    </div>
  );
};
