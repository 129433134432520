import { createContext, useContext, useState } from 'react';

import { FileClaimStep } from '../models/FileClaimForm';

type state = [
  FileClaimStep,
  React.Dispatch<React.SetStateAction<FileClaimStep>>,
];

export const FileClaimStepsContext = createContext([] as unknown as state);

const useClaimSteps = () => {
  const [formStep, setFormStep] = useState<FileClaimStep>(
    FileClaimStep.STEP_ONE,
  );

  return [formStep, setFormStep] as const;
};

export const FileClaimStepsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [formStep, setFormStep] = useClaimSteps();

  return (
    <FileClaimStepsContext.Provider value={[formStep, setFormStep]}>
      {children}
    </FileClaimStepsContext.Provider>
  );
};

export const useFileClaimSteps = () => {
  const ctx = useContext(FileClaimStepsContext);

  if (!ctx) {
    throw new Error(
      'Must be used inside a FileClaimStepsContext.Provider instance',
    );
  }

  return ctx;
};
