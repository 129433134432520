import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import ROUTES from '../../common/constants/routes';
import { toI18nKey } from '../../common/main';
import { useMyUser } from '../../network/api/users';
import SafeshipLogo from '../../ui/components/assets/SafeShipLogoLight.svg';
import ChevronUp from '../assets/ChevronUp.svg';
import ReguardLogo from '../assets/Logo.svg';

const YEAR = new Date().getFullYear();

const REGUARD_LINKS = [
  {
    name: 'Home',
    path: ROUTES.HOME,
  },
  {
    name: 'Account',
    path: ROUTES.ACCOUNT,
  },
  {
    name: 'My Plans',
    path: ROUTES.MY_PLANS,
  },
  {
    name: 'My Claims',
    path: ROUTES.MY_CLAIMS,
  },
];

const SAFESHIP_LINKS = [
  {
    name: 'My Policies',
    path: ROUTES.MY_SAFESHIP_POLICIES,
  },
  {
    name: 'My Claims',
    path: ROUTES.MY_SAFESHIP_CLAIMS,
  },
];

export function Footer() {
  const { t } = useTranslation();
  const { data: userData } = useMyUser();
  const [hasScroll, setScrollExists] = useState(true);

  const hasShippingInsurance = userData?.planCount?.SI > 0;

  useEffect(() => {
    const body = document.body;
    const config = { attributes: true, childList: true, subtree: true };
    const callback: MutationCallback = mutationList => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList') {
          if (
            document.getElementsByClassName('main-page')[0]?.scrollHeight <
            window.screen.height
          ) {
            setScrollExists(false);
          } else {
            setScrollExists(true);
          }
        }
      }
    };
    const observer = new MutationObserver(callback);
    observer.observe(body, config);
    return () => observer.disconnect();
  }, []);

  return (
    <footer className="main-footer">
      {/* top bar */}
      <div
        className={`${
          hasScroll ? 'flex' : 'hidden sm:flex'
        } flex-col items-center bg-reguard-indigo`}
      >
        <div className="bg-reguard-indigo flex w-full max-w-[90rem] justify-between items-center bg-reguard-gray-bg px-16 py-4 sm:py-4 lg:pl-[6.25rem] lg:pr-[6.063rem]">
          <div className="w-20 hidden sm:block">
            <ReguardLogo />
          </div>

          {hasShippingInsurance && (
            <div className="w-20 relative right-0 lg:right-64 hidden sm:block">
              <SafeshipLogo />
            </div>
          )}

          {/* mobile */}
          <button
            className="flex sm:hidden flex-col flex-1 justify-center items-center h-full b3 text-white text-center pb-[0.438rem] pt-[0.688rem]"
            onClick={() =>
              window &&
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
              })
            }
          >
            <div className="w-5 h-5">
              <ChevronUp />
            </div>
            Top of Page
          </button>
        </div>
      </div>

      {/* nav section */}
      <div className="flex flex-col items-center bg-white">
        <div className="flex w-full max-w-[90rem] justify-between items-center bg-reguard-gray-bg px-16 py-4 sm:pt-8 sm:pb-16 lg:pl-[6.25rem] lg:pr-[6.063rem]">
          {/* Reguard Specific Links */}
          <div
            className="hidden sm:grid grid-flow-col-dense sm:grid-cols-[repeat(3,_max-content)]
              sm:grid-rows-2 sm:gap-y-8 sm:gap-x-[5.75rem]"
          >
            {REGUARD_LINKS.slice(0, REGUARD_LINKS.length - 1).map(
              ({ name, path }) => (
                <NavLink
                  key={path}
                  to={path}
                  className={({ isActive }) =>
                    isActive
                      ? 'text-reguard-green semibold'
                      : 'text-reguard-indigo semibold hover:underline'
                  }
                >
                  {t(toI18nKey(name))}
                </NavLink>
              ),
            )}

            <a
              className="text-reguard-indigo semibold hover:underline"
              href="https://www.reguardprotection.com/terms-of-use"
              target="_blank"
              rel="noreferrer"
            >
              {t('terms_of_use')}
            </a>
            <NavLink
              key={REGUARD_LINKS[REGUARD_LINKS.length - 1].path}
              to={REGUARD_LINKS[REGUARD_LINKS.length - 1].path}
              className={({ isActive }) =>
                isActive
                  ? 'text-reguard-green semibold'
                  : 'text-reguard-indigo semibold hover:underline'
              }
            >
              {t(toI18nKey(REGUARD_LINKS[REGUARD_LINKS.length - 1].name))}
            </NavLink>

            <a
              className="text-reguard-indigo semibold hover:underline"
              href="https://www.reguardprotection.com/how-it-works#FAQ"
              target="_blank"
              rel="noreferrer"
            >
              {t('help')}
            </a>
          </div>

          {/* SafeShip Specific Links */}
          {hasShippingInsurance && (
            <div
              className="hidden sm:grid lg:grid-cols-[repeat(3,_max-content)]
              sm:grid-rows-2 sm:gap-y-8 sm:gap-x-[5.75rem]"
            >
              {SAFESHIP_LINKS.slice(0, REGUARD_LINKS.length - 1).map(
                ({ name, path }) => (
                  <NavLink
                    key={path}
                    to={path}
                    className={({ isActive }) =>
                      isActive
                        ? 'text-reguard-green semibold'
                        : 'text-reguard-indigo semibold hover:underline'
                    }
                  >
                    {t(toI18nKey(name))}
                  </NavLink>
                ),
              )}
            </div>
          )}
        </div>

        <div className="items-center">
          <div
            className={`px-12 pt-5 pb-5  ${
              hasScroll ? 'sm:pt-32 sm:pb-[6.625rem]' : 'sm:pt-16 sm:pb-16'
            }`}
          >
            <p className="c2 text-center">
              <span>{YEAR}</span>{' '}
              {t(
                'reguard_all_rights_reserved_reguard_the_reguard_logo_the_tag_design_are_trademarks_of_reguard_and_its_affiliated_companies',
              )}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
