import type { ComponentPropsWithoutRef } from 'react';

import LoaderEllipsisSVG from './assets/LoaderEllipsis.svg';

export interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  variant?: 'primary' | 'secondary';
  isfetching?: boolean;
  size?: 'small' | 'regular';
  kind?:
    | 'dark'
    | 'violet'
    | 'orange'
    | 'green'
    | 'grey'
    | 'white'
    | 'red'
    | 'indigo'
    | 'purple'
    | 'empty';
}

const baseStyle: any =
  'b1 h-12 p-3 w-full max-w-[17.938rem] text-white inline-flex cursor-pointer justify-center items-center border-[1px] rounded-lg border-solid';

const variantStyles: Record<'primary' | 'secondary', string> = {
  primary: 'border-reguard-wintergreen bg-reguard-wintergreen shadow-button',
  secondary: '!text-reguard-indigo-tint border-reguard-indigo-tint bg-white',
};

const kindStyles: any = {
  indigo: 'bg-reguard-indigo border-reguard-indigo text-white',
  dark: 'bg-reguard-indigo-tint border-reguard-indigo-tint text-white',
  violet:
    'bg-reguard-violet text-white border-reguard-violet shadow-button hover:text-white hover:bg-reguard-violet-shade hover:border-reguard-violet-shade focus:bg-reguard-violet-tint disabled:bg-reguard-inactive1 disabled:border-reguard-inactive1 disabled:text-reguard-inactive2 disabled:cursor-default',
  orange:
    'bg-white text-reguard-indigo border-reguard-orange hover:text-white hover:bg-reguard-orange hover:border-reguard-orange focus:bg-[#FFF5ED] disabled:bg-white disabled:border-[#95A1B4] disabled:text-reguard-inactive1 disabled:cursor-default',
  green:
    'bg-reguard-wintergreen text-white border-reguard-wintergreen shadow-button hover:text-white hover:bg-reguard-wintergreen-shade hover:border-reguard-violet-shade focus:bg-reguard-violet-tint disabled:bg-reguard-inactive1 disabled:border-reguard-inactive1 disabled:text-reguard-inactive2 disabled:cursor-default',
  grey: 'bg-reguard-inactive1 border-reguard-inactive1 shadow-button',
  white:
    'bg-white text-reguard-indigo-tint border-reguard-indigo-tint hover:text-white hover:bg-reguard-indigo-tint hover:border-reguard-indigo-tint focus:bg-[#EDEFF2] focus:text-reguard-indigo-tint disabled:bg-white disabled:border-reguard-inactive1 disabled:text-reguard-inactive1 disabled:cursor-default',
  red: 'text-white bg-reguard-error hover:bg-reguard-error hover:border-reguard-error border-reguard-error active:bg-reguard-error active:border-reguard-error',
  purple:
    'bg-safeship-purple border-safeship-purple hover:bg-safeship-dark-purple hover:border-safeship-dark-purple text-white shadow-button hover:text-white disabled:bg-reguard-inactive1 disabled:border-reguard-inactive1 disabled:text-reguard-inactive2 disabled:cursor-default',
};

const stateStyles: Record<
  'primary' | 'secondary',
  { hover: string; active: string; disabled: string; focus: string }
> = {
  primary: {
    hover: 'hover:bg-reguard-wintergreen-shade hover:border-reguard-violet',
    active:
      'active:bg-reguard-wintergreen-shade active:border-reguard-wintergreen-shade',
    disabled:
      'disabled:bg-reguard-inactive1 disabled:border-reguard-inactive1 disabled:text-reguard-inactive2 disabled:cursor-default',
    focus:
      'focus:bg-reguard-violet-tint focus:text-white focus:border-reguard-violet focus-visible:border-reguard-violet focus:outline-reguard-violet focus-visible:outline-reguard-violet',
  },
  secondary: {
    hover:
      'hover:bg-reguard-indigo-tint hover:border-reguard-violet hover:!text-white',
    active: 'active:bg-reguard-pearl-shade active:border-reguard-indigo-tint',
    disabled:
      'disabled:bg-reguard-inactive1 disabled:border-reguard-inactive1 disabled:!text-reguard-inactive2 disabled:cursor-default',
    focus:
      'focus:bg-[#E6E7EE] focus:border-reguard-violet focus-visible:border-reguard-violet focus:outline-reguard-violet focus-visible:outline-reguard-violet',
  },
};

export function Button({
  isfetching = false,
  variant = 'primary',
  className,
  size = 'regular',
  kind = 'empty',
  ...props
}: ButtonProps) {
  const btnChildren = isfetching ? (
    <div className="flex flex-row justify-center w-24">
      <LoaderEllipsisSVG />
    </div>
  ) : (
    props.children
  );

  const stateStyle: any =
    isfetching || props.disabled
      ? stateStyles[variant].disabled
      : `${stateStyles[variant].hover} ${stateStyles[variant].active} ${stateStyles[variant].disabled} ${stateStyles[variant].focus}`;

  return (
    <button
      className={`tw-cst-pf ${baseStyle} ${
        isfetching
          ? 'border-reguard-inactive1 bg-reguard-inactive1'
          : variantStyles[variant]
      } ${stateStyle} ${size === 'small' ? 'max-w-[12.5rem]' : ''} ${
        kind ? kindStyles[kind] : ''
      } ${className || ''}`}
      {...props}
    >
      {btnChildren}
    </button>
  );
}
