import { Endpoint, endpointConstructor } from '../index.js';

export interface DomainEndpoints {
  getDomainValues: Endpoint;
}

const domainEndpoints = () => {
  const { endpoint } = endpointConstructor('/domain');
  return {
    getDomainValues: endpoint('GET', '/:domainType'),
  };
};

export default domainEndpoints;
