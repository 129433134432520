import { Endpoint, endpointConstructor } from '../index.js';

export interface ContractEndpoints {
  getContracts: Endpoint;
  patchProcessingStatus: Endpoint;
}

const contractEndpoints = () => {
  const { endpoint } = endpointConstructor('/contract');
  return {
    getContracts: endpoint('GET'),
    patchProcessingStatus: endpoint('PATCH', '/:id/processing-status'),
  };
};

export default contractEndpoints;
