import type { ComponentPropsWithoutRef, FC } from 'react';

import LeftArrowSVG from './assets/FacLeftArrow.svg';
import RightArrowSVG from './assets/FacRightArrow.svg';
import { buttonSelectors } from './tests/dataCySelectors';

export interface FileClaimArrowProps
  extends ComponentPropsWithoutRef<'button'> {
  svgClassName?: string;
  className?: string;
  onClick?: () => void;
  direction: 'left' | 'right';
}

export const FileClaimArrow: FC<FileClaimArrowProps> = ({
  svgClassName,
  className,
  onClick,
  direction,
  ...props
}: FileClaimArrowProps) => (
  <div
    className={`tw-cst-pf w-4 flex flex-col justify-center items-center ${className}`}
  >
    <button className="tw-cst-pf bg-transparent" onClick={onClick} {...props}>
      {direction === 'left' && (
        <div
          className={`w-4 h-4 ${svgClassName || ''}`}
          data-testid="arrow-left-btn"
        >
          <LeftArrowSVG data-cy={buttonSelectors.arrowLeftBtn} />
        </div>
      )}
      {direction === 'right' && (
        <div
          className={`w-4 h-4 ${svgClassName || ''} custom-class`}
          data-testid="arrow-right-btn"
        >
          <RightArrowSVG data-cy={buttonSelectors.arrowRightBtn} />
        </div>
      )}
    </button>
  </div>
);
