import { createContext, ReactNode, useContext, useState } from 'react';

import { buildDefaultSelectedOptions } from './setup-defaults';
import {
  setupActionsHandler,
  setupBadgeHandler,
  setupChangeHandler,
  setupRemoveHandler,
  setupSubmitHandler,
} from './setup-handlers';
import { Actions, BadgeItem, FilterConfig, SelectedOption } from './types';

// THE PROVIDER COMPONENT

interface FilterTagsProviderProps {
  children: ReactNode;
  actions: Actions;
  configs: FilterConfig[];
  onSubmit: (selectedOptions: SelectedOption[]) => void;
}

export const FilterTagsProvider = ({
  actions,
  children,
  configs,
  onSubmit,
}: FilterTagsProviderProps) => {
  const [open, setOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<SelectedOption[]>(
    buildDefaultSelectedOptions(configs),
  );

  const actionsHandler = setupActionsHandler(actions, setOpen);
  const badgeHandler = setupBadgeHandler(selectedOptions);
  const submitHandler = setupSubmitHandler(onSubmit, setOpen, selectedOptions);
  const changeHandler = setupChangeHandler(selectedOptions, setSelectedOptions);
  const removeHandler = setupRemoveHandler(
    onSubmit,
    selectedOptions,
    setSelectedOptions,
  );

  return (
    <Context.Provider
      value={{
        actions,
        configs,
        modalState: {
          hasButton: false,
          open,
          setOpen,
        },
        changeHandler,
        removeHandler,
        submitHandler,
        badgeHandler,
        actionsHandler,
        selectedOptions,
      }}
    >
      {children}
    </Context.Provider>
  );
};

// AND FINALLY THE CONTEXT HOOK

interface FilterTagsContext {
  actions: Actions;
  configs: FilterConfig[];
  modalState: {
    hasButton?: boolean;
    open: boolean;
    setOpen: (open: boolean) => void;
  };
  changeHandler: (selectedOption: SelectedOption) => void;
  removeHandler: (selectedOption: SelectedOption) => void;
  submitHandler: () => void;
  badgeHandler: () => BadgeItem[];
  actionsHandler: () => Actions;
  selectedOptions: SelectedOption[];
}

const Context = createContext({} as FilterTagsContext);

export function useFilterTagsContext() {
  return useContext(Context);
}
