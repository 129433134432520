import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Address } from '@cortex/myaccount/ui/components/models/Address';

import endpoints from '../../endpoints';
import type { User } from '../../ui/components';
import { useClient } from './client';

const {
  create,
  requestEmailChange,
  requestPhoneNumberChange,
  changeEmail,
  changePhoneNumber,
  setPassword,
  changePassword,
  updateRole,
  updateContractAddress,
  updateContractPhone,
  updateCustomerAddress,
  addCustomerAddress,
  getCustomerAddresses,
  requestVerifyEmail,
  verifyEmail,
  updatePrefLanguage,
} = endpoints().userEndpoints();

// todo separate auth api from user api
const {
  loginLocal,
  logout,
  sendAuthEmail,
  getAuthenticatedUser,
  checkUserBySalesId,
} = endpoints().authEndpoints();

const defaultMutationOptions = (options = {}) => ({
  onError: (_err: string, _variables: any, recover: unknown) =>
    typeof recover === 'function' ? recover() : null,
  ...options,
});

function useRequestEmailChange(options = {}) {
  const client = useClient();

  return useMutation(
    data =>
      client(requestEmailChange.path, {
        method: requestEmailChange.method,
        data,
      }),
    {
      ...defaultMutationOptions(options),
    },
  );
}

function useRequestPhoneChange(options = {}) {
  const client = useClient();

  return useMutation(
    data =>
      client(requestPhoneNumberChange.path, {
        method: requestPhoneNumberChange.method,
        data,
      }),
    {
      ...defaultMutationOptions(options),
    },
  );
}

// todo either updateUserEmail or changeUserEmail
function useUpdateUserEmail(options = {}) {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    data => client(changeEmail.path, { method: changeEmail.method, data }),
    {
      ...defaultMutationOptions(options),
      onSuccess: () => queryClient.invalidateQueries('myUser'),
    },
  );
}

function useUpdateLanguage(options = {}) {
  const client = useClient();
  const queryClient = useQueryClient();
  return useMutation(
    data =>
      client(updatePrefLanguage.path, {
        method: updatePrefLanguage.method,
        data,
      }),
    {
      ...defaultMutationOptions(options),
      onSuccess: () => queryClient.invalidateQueries('myUser'),
    },
  );
}

function useUpdatePhoneNumber(options = {}) {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    data =>
      client(changePhoneNumber.path, {
        method: changePhoneNumber.method,
        data,
      }),
    {
      ...defaultMutationOptions(options),
      onSuccess: () => queryClient.invalidateQueries('myUser'),
    },
  );
}

function useRequestVerifyEmail(options = {}) {
  const client = useClient();

  return useMutation(
    data =>
      client(requestVerifyEmail.path, {
        method: requestVerifyEmail.method,
        data,
      }),
    {
      ...defaultMutationOptions(options),
    },
  );
}

function useVerifyEmail(options = {}) {
  const client = useClient();

  return useMutation(
    data => client(verifyEmail.path, { method: verifyEmail.method, data }),
    {
      ...defaultMutationOptions(options),
    },
  );
}

function useCreateUser(options = {}) {
  const client = useClient();

  return useMutation(
    data => client(create.path, { method: create.method, data }),
    {
      ...defaultMutationOptions(options),
    },
  );
}

function useSetUserPassword(options = {}) {
  const client = useClient();

  return useMutation(
    data => client(setPassword.path, { method: setPassword.method, data }),
    {
      ...defaultMutationOptions(options),
    },
  );
}

function useChangeUserPassword(options = {}) {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    data =>
      client(changePassword.path, { method: changePassword.method, data }),
    {
      ...defaultMutationOptions(options),
      onSuccess: () => queryClient.invalidateQueries('myUser'),
    },
  );
}

function useLogin(options = {}) {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    params =>
      client(loginLocal.path, { method: loginLocal.method, data: params }),
    {
      ...defaultMutationOptions(options),
      onSuccess: () => {
        queryClient.invalidateQueries('myUser');
      },
    },
  );
}

function useLogout(options = {}) {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(() => client(logout.path, { method: logout.method }), {
    ...defaultMutationOptions(options),
    onSuccess: () => queryClient.resetQueries('myUser'),
  });
}

function useUpdateRole(options = {}) {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    data => client(updateRole.path, { method: updateRole.method, data }),
    {
      ...defaultMutationOptions(options),
      onSuccess: () => queryClient.invalidateQueries('allUsers'),
    },
  );
}

function useInviteUser(options = {}) {
  const client = useClient();

  return useMutation(
    data => client(sendAuthEmail.path, { method: sendAuthEmail.method, data }),
    {
      ...defaultMutationOptions(options),
    },
  );
}

function useMyUser(options = {}) {
  const client = useClient();

  const result = useQuery<User, Error>({
    queryKey: 'myUser',
    queryFn: () => client(getAuthenticatedUser.path, {}).then(data => data),
    retry: false,
    ...options,
  });

  return { ...result, data: (result.data as User) ?? {} };
}

function useAllUserAddresses(options = {}) {
  const client = useClient();
  const result = useQuery<Address[], Error>(
    ['userAddresses', { ...options }],
    () => client(getCustomerAddresses.path, {}),
  );

  return { ...result, data: result.data ?? [] };
}

function useUpdateUserContractAddress(options = {}) {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    data =>
      client(updateContractAddress.path, {
        method: updateContractAddress.method,
        data,
      }),
    {
      ...defaultMutationOptions(options),
      onSuccess: () => queryClient.invalidateQueries('userAddresses'),
    },
  );
}

function useUpdateUserContractPhone(options = {}) {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    data =>
      client(updateContractPhone.path, {
        method: updateContractPhone.method,
        data,
      }),
    {
      ...defaultMutationOptions(options),
      onSuccess: () => queryClient.invalidateQueries('phoneNumber'),
    },
  );
}

// TODO: Remove this and all related code for it prior to PR
function useUpdateUserAddress(options = {}) {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    data =>
      client(updateCustomerAddress.path, {
        method: updateCustomerAddress.method,
        data,
      }),
    {
      ...defaultMutationOptions(options),
      onSuccess: () => queryClient.invalidateQueries('userAddresses'),
    },
  );
}

// TODO: Remove this and all related code for it prior to PR
function useAddUserAddress(options = {}) {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    data =>
      client(addCustomerAddress.path, {
        method: addCustomerAddress.method,
        data,
      }),
    {
      ...defaultMutationOptions(options),
      onSuccess: () => queryClient.invalidateQueries('userAddresses'),
    },
  );
}

function useCheckUserBySalesId(options = {}) {
  const client = useClient();
  return useMutation(
    data =>
      client(checkUserBySalesId.path, {
        method: checkUserBySalesId.method,
        data,
      }),
    {
      ...defaultMutationOptions(options),
    },
  );
}

export {
  useCreateUser,
  useInviteUser,
  useLogin,
  useLogout,
  useMyUser,
  useSetUserPassword,
  useUpdateRole,
  useRequestEmailChange,
  useRequestPhoneChange,
  useUpdateUserEmail,
  useUpdatePhoneNumber,
  useChangeUserPassword,
  useAllUserAddresses,
  useUpdateUserContractAddress,
  useUpdateUserContractPhone,
  useUpdateUserAddress,
  useAddUserAddress,
  useCheckUserBySalesId,
  useRequestVerifyEmail,
  useVerifyEmail,
  useUpdateLanguage,
};
