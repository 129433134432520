import type { FC } from 'react';

import FormStepFourSVG from './assets/FormStepFour.svg';
import FormStepOneSVG from './assets/FormStepOne.svg';
import FormStepThreeSVG from './assets/FormStepThree.svg';
import FormStepTwoSVG from './assets/FormStepTwo.svg';
import { FileClaimArrow, FileClaimFormProgressBar, FileClaimStep } from '.';

interface FileClaimHeaderProps {
  stepName?: string;
  formStep: FileClaimStep;
  goBack: () => void;
  isChatBot?: boolean;
  shouldContinue?: boolean;
  disableArrows?: boolean;
  onContinue?: () => void;
  onSubmit?: () => void;
}

const handleSteps = (step: FileClaimStep) => {
  switch (step) {
    case FileClaimStep.STEP_ONE:
      return <FormStepOneSVG />;
    case FileClaimStep.STEP_TWO:
      return <FormStepTwoSVG />;
    case FileClaimStep.STEP_THREE:
      return <FormStepThreeSVG />;
    case FileClaimStep.STEP_FOUR:
      return <FormStepFourSVG />;
    default:
      return null;
  }
};

export const FileClaimHeader: FC<FileClaimHeaderProps> = ({
  formStep,
  goBack,
  stepName,
  shouldContinue,
  isChatBot,
  disableArrows,
  onContinue,
}) => {
  return formStep < FileClaimStep.STEP_FIVE ? (
    <>
      <div
        className={`flex flex-row justify-between bg-white px-6 items-center mt-8 py-2 ${
          !isChatBot &&
          'w-full sm:mx-auto sm:py-6 sm:px-12 md:mt-0 md:py-6 md:px-12'
        }`}
      >
        <div className="flex">
          {formStep > FileClaimStep.STEP_ONE && !disableArrows && (
            <FileClaimArrow
              direction="left"
              type="button"
              onClick={() => goBack()}
            />
          )}
        </div>

        <div className="items-center">
          <div className="text-reguard-indigo text-lg semibold cqsm:mb-4 cqsm:text-2xl cqmd:mb-4 cqmd:text-2xl sm:mb-4 sm:text-2xl md:mb-4 md:text-2xl">
            {stepName}
          </div>

          <div className="justify-center flex">
            <div className="grid grid-cols-1fr text-reguard-violet w-20">
              <div className="col-start-1 mx-1">{handleSteps(formStep)}</div>
              <div className="col-end-3">of 4</div>
            </div>
          </div>
        </div>

        <div className="flex">
          {!disableArrows && (
            <FileClaimArrow
              disabled={!shouldContinue}
              direction="right"
              type="button"
              onClick={onContinue}
            />
          )}
        </div>
      </div>
      <FileClaimFormProgressBar step={formStep} />
    </>
  ) : (
    <div
      className={`hidden sm:flex w-full items-center relative justify-center bg-white h-[6.875rem] sm:mb-10 sm:mt-8 md:mt-0 ${
        isChatBot && 'cqmd:hidden md:hidden'
      }`}
    >
      <div className="flex absolute bg-red top-0 bottom-0 left-[8.3%]">
        <FileClaimArrow direction="left" onClick={() => goBack()} />
      </div>
      <div className="flex text-reguard-indigo text-lg semibold cqsm:mb-4 cqsm:text-2xl cqmd:mb-4 cqmd:text-2xl  sm:text-2xl md:text-2xl">
        {stepName}
      </div>
    </div>
  );
};
