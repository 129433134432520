export const navigation = {
  page: {
    title: 'Bienvenido a Reguard Admin',
  },
  dashboards: {
    name: 'Tableros',
    description:
      'Ver métricas y KPI en todos los niveles de la plataforma Reguard',
  },

  management: {
    name: 'Gestión',
    description:
      'Realice las tareas necesarias para mantener las cuentas y contratos de Reguard',
  },

  merchants: {
    name: 'Comerciantes',
    description: 'Administre sus contratos y más',
    contracts_list: 'Sus Contratos',
    contracts_list_description: 'Ver todos sus contratos y sus detalles',
    contract_create: 'Crear un Contrato',
    contract_create_description:
      'Cree un nuevo contrato que será procesado por el pipeline de reguard',
  },
};
