import { Endpoint, endpointConstructor } from '../index.js';

export interface WebhookEndpoints {
  contract: Endpoint;
  claim: Endpoint;
}

const webhookEndpoints = (): WebhookEndpoints => {
  const { endpoint } = endpointConstructor('/webhook');
  return {
    contract: endpoint('POST', '/contract'),
    claim: endpoint('POST', '/claim'),
  };
};

export default webhookEndpoints;
