import {
  FileClaimFields,
  StepFourInitialState,
  StepOneInitialState,
  StepThreeInitialState,
  StepTwoInitialState,
} from '../constants/facFieldsInitialValues';
import { FileClaimStep } from '../models/FileClaimForm';

const MAPPED_INITIAL_STATE = {
  [FileClaimStep.STEP_ONE]: StepOneInitialState,
  [FileClaimStep.STEP_TWO]: StepTwoInitialState,
  [FileClaimStep.STEP_THREE]: StepThreeInitialState,
  [FileClaimStep.STEP_FOUR]: StepFourInitialState,
};

export const checkClaimFormFilled = (
  values: FileClaimFields,
  formStep: FileClaimStep,
) => {
  let filled = true;

  if (MAPPED_INITIAL_STATE[formStep]) {
    for (const key of Object.keys(MAPPED_INITIAL_STATE[formStep])) {
      if (Array.isArray(values[key]) && values[key].length === 0) {
        filled = false;
        break;
      }

      if (!values[key]) {
        filled = false;
        break;
      }
    }
  }

  return filled;
};

export const checkStepValid = (errors, formStep) => {
  if (MAPPED_INITIAL_STATE[formStep]) {
    for (const key of Object.keys(MAPPED_INITIAL_STATE[formStep])) {
      if (errors[key]) {
        return false;
      }
    }
  }

  return true;
};
