import { endpointConstructor } from '../index.js';

export type TrainingEndpoints = ReturnType<typeof trainingEndpoints>;

const trainingEndpoints = () => {
  const { endpoint } = endpointConstructor('/training');
  return {
    getAuthenticatedUser: endpoint('GET', '/user'),
    createUser: endpoint('POST', '/user'),
  };
};

export default trainingEndpoints;
