import { endpointConstructor } from '../index.js';

export type BotEndpoints = ReturnType<typeof botEndpoints>;

const botEndpoints = () => {
  const { endpoint } = endpointConstructor('/bot');
  return {
    getUserByCustomerEmail: endpoint('GET', '/customer'),
    findUser: endpoint('GET', '/find'),
    sendEmailCode: endpoint('POST', '/email/code'),
    sendPhoneCode: endpoint('POST', '/phone/code'),
    verifyEmailCode: endpoint('PATCH', '/email/verify'),
    verifyPhoneCode: endpoint('PATCH', '/phone/verify'),
    getContractById: endpoint('GET', '/contract/:contractId'),
    getUserAddresses: endpoint('GET', '/customer/addresses'),
    getToken: endpoint('GET', '/token'),
    getUserPurchases: endpoint('GET', '/purchases/:customerId'),
    removeDraftClaim: endpoint('DELETE', '/claim/:id'),
    getClaimById: endpoint('GET', '/claim/:id'),
  };
};

export default botEndpoints;
