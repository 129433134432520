import { endpointConstructor } from '../index.js';

export type LocalesEndpoints = ReturnType<typeof localesEndpoints>;

const localesEndpoints = () => {
  const { endpoint } = endpointConstructor('/locales');
  return {
    getTranslationFile: endpoint('GET', '/translation-file'),
    getTranslationData: endpoint('GET', '/translation'),
    getTranslationLocaleData: endpoint('GET', '/translation/:lang'),
  };
};

export default localesEndpoints;
