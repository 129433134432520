import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import ROUTES from '@cortex/myaccount/common/constants/routes';
import { useMyUser } from '@cortex/myaccount/network/api/users';

import HamburgerIcon from '../../assets/Hamburger.svg';
import ReguardLogoDark from '../../assets/Logo-black.svg';

interface ReguardHeaderProps {
  toggleMobileMenu: (show: boolean) => void;
}

export const ReguardHeader = ({ toggleMobileMenu }: ReguardHeaderProps) => {
  const { t } = useTranslation();
  const { data: userData } = useMyUser();
  const hasShippingInsurance = userData?.planCount?.SI > 0;

  return (
    <div className={`w-full md:mx-5 ${!hasShippingInsurance && 'mx-6'}`}>
      <div className="flex w-full items-center bg-reguard-gray-bg py-7 flex-row-reverse md:flex-row">
        <Link to={ROUTES.HOME}>
          <div className="hidden md:block min-w-[100px] lg:min-w-[120px]">
            <ReguardLogoDark />
          </div>
        </Link>

        <button
          className="md:hidden cursor-pointer"
          onClick={() => toggleMobileMenu(true)}
        >
          <div className="w-4 h-4">
            <HamburgerIcon />
          </div>
        </button>
      </div>

      <div className="flex w-full items-center bg-reguard-gray-bg pb-10">
        {/* navbar */}
        <div className="text-base gap-12 hidden md:flex">
          <NavLink
            to={ROUTES.HOME}
            className={({ isActive }) =>
              isActive
                ? 'text-reguard-green semibold'
                : 'text-reguard-indigo hover:underline semibold font-normal'
            }
          >
            {t('home')}
          </NavLink>

          <NavLink
            to={ROUTES.MY_PLANS}
            className={({ isActive }) =>
              isActive
                ? 'text-reguard-green semibold'
                : 'text-reguard-indigo hover:underline semibold font-normal'
            }
          >
            {t('my_plans')}
          </NavLink>

          <NavLink
            to={ROUTES.MY_CLAIMS}
            className={({ isActive }) =>
              isActive
                ? 'text-reguard-green semibold'
                : 'text-reguard-indigo semibold hover:underline font-normal'
            }
          >
            {t('my_claims')}
          </NavLink>
        </div>
      </div>
    </div>
  );
};
