import { Endpoint, endpointConstructor } from '../index.js';

export interface PurchaseEndpoints {
  getPurchases: Endpoint;
}

const purchaseEndpoints = () => {
  const { endpoint } = endpointConstructor('/purchase');

  return {
    getPurchases: endpoint('GET', '/:kind'),
  };
};

export default purchaseEndpoints;
