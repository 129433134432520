import { Endpoint, endpointConstructor } from '../index.js';

export interface HealthEndpoints {
  healthCheck: Endpoint;
}

const healthEndpoints = () => {
  const { endpoint } = endpointConstructor('/health');
  return {
    healthCheck: endpoint('GET'),
  };
};

export default healthEndpoints;
