export function hasEmptyValues(formValues: any): boolean {
  const values = Object.values(formValues);

  for (const value of values) {
    if (typeof value === 'string' && value.trim() === '') {
      return true;
    } else if (Array.isArray(value)) {
      for (const item of value) {
        const subValues = Object.values(item);
        for (const subValue of subValues) {
          if (typeof subValue === 'string' && subValue.trim() === '') {
            return true;
          }
        }
      }
    }
  }

  return false;
}

export const NOLEADING_SPACES = /^[a-zA-Z0-9][\sa-zA-Z0-9]*/;
export const WS_TEXT = 'no_leading_spaces_allowed';

export const toI18nKey = (str = '') =>
  str.toLocaleLowerCase().split(' ').join('_');
