import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileClaimStep } from './models/FileClaimForm';
import { buttonSelectors } from './tests/dataCySelectors';
import { Button } from './Button';
import { SaveClaimModal } from './SaveClaimModal';

interface FileClaimBottom {
  requestError?: string;
  formStep: FileClaimStep;
  shouldContinue?: boolean;
  isSubmitting?: boolean;
  isChatBot?: boolean;
  handleSave: () => void | Promise<any>;
  onSubmit: () => void;
}

export const FileClaimBottom: FC<FileClaimBottom> = ({
  requestError,
  formStep,
  shouldContinue,
  isSubmitting,
  isChatBot,
  handleSave,
  onSubmit,
}) => {
  const [isSaveModalVisible, showSaveModal] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      {isSaveModalVisible && (
        <SaveClaimModal
          onClose={() => showSaveModal(false)}
          handleSave={async () => {
            try {
              await handleSave();
            } catch (e: any) {
              showSaveModal(false);
            }
          }}
          isSavingStep={isSubmitting}
        />
      )}
      <div className="flex flex-column justify-center mb-[5.25rem] sm:mb-[6.25rem]">
        <div className="flex flex-col w-full items-center">
          {formStep < FileClaimStep.STEP_FIVE ? (
            <Button
              disabled={!shouldContinue}
              data-cy={buttonSelectors.continueBtn}
              onClick={onSubmit}
              type="button"
              className={`!max-w-[100%] mt-12 mb-8 ${
                !isChatBot && 'sm:!max-w-[17.938rem] sm:mt-20 sm:mb-6'
              } ${!shouldContinue ? 'pointer-events-none' : ''}`}
            >
              {t('continue')}
            </Button>
          ) : (
            <Button
              isfetching={isSubmitting}
              disabled={!shouldContinue}
              onClick={onSubmit}
              data-cy={buttonSelectors.submitBtn}
              type="button"
              className={`!max-w-[100%] ${
                !isChatBot && 'sm:!max-w-[17.938rem]'
              } ${!shouldContinue ? 'pointer-events-none' : ''}`}
            >
              {t('submit')}
            </Button>
          )}
          {formStep < FileClaimStep.STEP_FIVE && (
            <Button
              isfetching={isSubmitting}
              disabled={!shouldContinue}
              className={`!max-w-[100%] ${
                !isChatBot && 'sm:!max-w-[17.938rem]'
              }`}
              variant="secondary"
              type="button"
              data-cy={buttonSelectors.saveAndCloseBtn}
              onClick={
                isChatBot ? () => handleSave() : () => showSaveModal(true)
              }
            >
              {t('save_and_close')}
            </Button>
          )}
          {requestError && (
            <div className={`w-[100%] ${!isChatBot && 'sm:w-[17.813rem]'}`}>
              <div className="error-text c1 mt-2 text-reguard-error">
                {t(requestError)}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
