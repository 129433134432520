import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';

import type { RootState } from '../..';

interface AuthState {
  rememberMe: boolean;
}

const initialState: AuthState = {
  rememberMe: false,
};

export const authSlice: Slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setRememberMe: (state, action: PayloadAction<boolean>) => {
      state.rememberMe = action.payload;
    },
  },
});

export const { setRememberMe } = authSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
export default authSlice.reducer;
