import { lazy, Suspense, useCallback, useEffect } from 'react';
import { useIsFetching } from 'react-query';
import {
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
} from 'react-router-dom';
import i18n, { i18nInit } from '@cortex/locales/i18n/i18n';
import { LoadingSpinner } from '@cortex/shared';
import * as Sentry from '@sentry/react';

import ROUTES from './common/constants/routes';
import { getPreferredLanguage } from './common/locales/locales-utils';
import { useLogout, useMyUser } from './network/api/users';
import {
  LoaderGlobal,
  Toast,
  Unsubscribe,
  useGoogleAnalytics,
} from './ui/components';
import { Footer } from './views/shared/Footer';
import { MainHeader } from './views/shared/MainHeader';
import config from './config';
import { PublicRouter } from './publicRouter';
import { Router } from './router';
const ErrorPage = lazy(() => import('./ui/components/ErrorPage'));
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

i18nInit();

const LoggedRoutes = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Router />
    </Suspense>
  );
};

function App() {
  const { data: userData, isFetching, isLoading, error } = useMyUser();

  const navigate = useNavigate();
  const isGlobalFetching = useIsFetching();
  const { mutate: logout } = useLogout();
  const location = useLocation();
  const match = useMatch('set-password/:token');
  const isLogged = Boolean(userData?.email) && !match;

  const isFirstLoading = isFetching && isLoading;
  useGoogleAnalytics(config.ga.trackingCode);
  const isNotFound = location.pathname !== ROUTES.NOT_FOUND;
  const changeLanguage = async (language: string) => {
    await i18n.changeLanguage(language);
  };
  const language = getPreferredLanguage(userData, localStorage);

  useEffect(() => {
    if (language) {
      changeLanguage(language);
    }
  }, [language]); // eslint-disable-line

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  const logoutHandler = () => {
    logout({});
  };

  const PublicRoutes = useCallback(() => {
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <PublicRouter />
      </Suspense>
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);

  const handleLoggedUserRoutes = () => {
    if (isFirstLoading) return null;

    if (location.pathname === ROUTES.UNSUBSCRIBE) {
      return (
        <SentryRoutes>
          <Route path={ROUTES.UNSUBSCRIBE} element={<Unsubscribe />} />
        </SentryRoutes>
      );
    }

    if (isLogged) {
      return (
        <>
          {isNotFound && (
            <MainHeader userName={userData.name} onLogout={logoutHandler} />
          )}
          <LoggedRoutes />
          {isNotFound && <Footer />}
        </>
      );
    }

    return <PublicRoutes />;
  };

  return (
    <>
      <LoaderGlobal isLoading={isGlobalFetching > 0} />
      {error ? (
        <ErrorPage callToAction={() => navigate('/')} ctaLabel="Back to Home" />
      ) : (
        handleLoggedUserRoutes()
      )}
      <Toast />
    </>
  );
}

export default App;
