import { createContext, ReactNode, useContext } from 'react';
import { PaginationResponse } from '@cortex/shared/models';

import {
  MenuDropdownconfig,
  MenuDropdownProps,
} from './components/actionable/menu-dropdown/menu-dropdown';
import { FilterItem, TableFilterProps } from './components/filterable/filter';
import { LinkButtonProps, LinkButtonsProps } from './components/linkable/links';
import { PaginationProps } from './components/pagable/pagination/pagination';
import { SearchInputProps } from './components/searchable/search-input';
import { SelectableRowProps } from './components/selectable/selectable-row';
import { TableColumn } from './components/table-body-rows/table-body-rows';
import { useFeatures } from './features/use-features';

export interface ChangeParams {
  trigger?: string;

  page: number;
  action: string;
  searchTerm?: string;
  selectedItems: number[];
  selectedData: unknown[];
  refresh: boolean;
  filters: string[];
  reset: (params: Partial<ChangeParams>) => void;
}

export interface DataTableContextConfig {
  onChange?: (
    params: ChangeParams,
  ) => Promise<PaginationResponse<unknown>> | void;

  columns?: TableColumn[];
  filters?: FilterItem[];
  actions?: MenuDropdownconfig;
  links?: LinkButtonProps[];

  // available features
  searchable?: boolean;
  refreshable?: boolean;
  selectable?: boolean;
  actionable?: boolean;
  filterable?: boolean;
  pagable?: boolean;
  linkable?: boolean;
}

export interface DataTableContextParams extends DataTableContextConfig {
  result?: PaginationResponse<unknown>;

  search?: SearchInputProps;
  selection?: SelectableRowProps;
  filter?: TableFilterProps;
  action?: MenuDropdownProps;
  pagination?: PaginationProps;
  link?: LinkButtonsProps;
}

export interface DataTableContextProviderProps {
  children: ReactNode;
  config: Partial<DataTableContextConfig>;
}

const DataTableContext = createContext<DataTableContextParams>(
  {} as DataTableContextConfig,
);

export function DataTableProvider({
  children,
  config,
}: DataTableContextProviderProps) {
  const { result, features } = useFeatures(config);

  return (
    <DataTableContext.Provider
      value={{
        result,
        ...config,
        ...features,
      }}
    >
      {children}
    </DataTableContext.Provider>
  );
}

export function useDataTableContext() {
  return useContext(DataTableContext);
}
