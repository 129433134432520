import rawFlags from './views/assets/flagsmith.json';

const config = {
  app: {
    port: import.meta.env.VITE_APP_PORT || 3000,
  },
  api: {
    url: import.meta.env.VITE_API_URL || 'http://localhost:3500',
  },
  ga: {
    trackingCode: import.meta.env.VITE_CUSTOMER_GA_TRACKING_CODE || '',
  },
  sentry: {
    version: import.meta.env.VITE_BUILD_VERSION || '',
    dsn: import.meta.env.VITE_SENTRY_CUSTOMER_DSN || '',
    environment: import.meta.env.VITE_SENTRY_ENV || '',
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || 0.1,
  },
  cookieBot: {
    domainId: import.meta.env.VITE_COOKIEBOT_DOMAIN_ID || '',
  },

  flagsmith: {
    rawFlags,
    environmentID: import.meta.env.VITE_FLAGSMITH_ENVIRONMENT || '',
    api:
      import.meta.env.VITE_FLAGSMITH_API ||
      'https://flagsmith.reguardprotection.com/api/v1/',
  },
};

export default config;
