import type { FC } from 'react';

import { CustomerClaimStatus } from '../../common/constants/claims';

interface ClaimStatusIndicatorProps {
  status: CustomerClaimStatus;
}
const colorMap: any = {
  [CustomerClaimStatus.Draft]: {
    bg: 'bg-reguard-orange',
    text: 'text-reguard-orange',
  },
  [CustomerClaimStatus.UnderReview]: {
    bg: 'bg-reguard-violet',
    text: 'text-reguard-violet',
  },
  [CustomerClaimStatus.InProgress]: {
    bg: 'bg-reguard-wintergreen-shade',
    text: 'text-reguard-wintergreen-shade',
  },
  [CustomerClaimStatus.Approved]: {
    bg: 'bg-reguard-wintergreen-shade',
    text: 'text-reguard-wintergreen-shade',
  },
  [CustomerClaimStatus.Closed]: {
    bg: 'bg-reguard-indigo-tint',
    text: 'text-reguard-indigo-tint',
  },
};
export const ClaimStatusIndicator: FC<ClaimStatusIndicatorProps> = (
  props: ClaimStatusIndicatorProps,
) => (
  <div className="flex gap-2 items-center c1 md:b2b">
    <div
      className={`w-2 h-2 rounded-full ${colorMap[props.status]?.bg}`}
      data-testid="background-div"
    ></div>
    <span className={`${colorMap[props.status]?.text} font-normal`}>
      {props.status}
    </span>
  </div>
);
