import { useLocation } from 'react-router-dom';

import ROUTES from '../common/constants/routes';
import SafeShipLogoDark from '../ui/components/assets/SafeShipLogoDark.svg';
import SafeShipLogoLight from '../ui/components/assets/SafeShipLogoLight.svg';
import ReguardLogoLight from '../views/assets/Logo.svg';
import ReguardLogoDark from '../views/assets/Logo-black.svg';

export function useCompanyContext() {
  const location = useLocation();

  const isSafeShip = location.pathname.indexOf(ROUTES.SAFESHIP) > -1;

  return {
    LogoLight: isSafeShip ? SafeShipLogoLight : ReguardLogoLight,
    LogoDark: isSafeShip ? SafeShipLogoDark : ReguardLogoDark,
    menu_items: {
      plans: {
        label: isSafeShip ? 'my_policies' : 'my_plans',
        url: isSafeShip ? ROUTES.SAFESHIP : ROUTES.MY_PLANS,
      },
    },
    kind: isSafeShip ? 'SI' : 'FPP',
    isSafeShip,
  };
}
