import { LanguageCodes } from '../constants/locales.js';

export const getPreferredLanguage = (userData, localStorage) => {
  if (userData?.languagePref) {
    return userData.languagePref;
  }

  if (localStorage?.getItem('language')) {
    return localStorage.getItem('language');
  }

  if (localStorage?.getItem('i18nextLng')) {
    return localStorage.getItem('i18nextLng');
  }

  return LanguageCodes.EN;
};
