import {
  ComponentPropsWithoutRef,
  forwardRef,
  LegacyRef,
  ReactNode,
} from 'react';

export interface TextAreaProps extends ComponentPropsWithoutRef<'textarea'> {
  error?: string;
  valid?: boolean;
  className?: string;
  label?: ReactNode;
  labelHtmlFor?: string;
  name?: string;
  placeholder?: string;
  infoText?: string;
  infoTextVisible?: boolean;
  containerClassName?: string;
  onChange?: (e: any) => void;
  value?: any;
}

export const TextArea = (
  {
    error,
    valid,
    label,
    labelHtmlFor,
    className,
    containerClassName,
    infoText,
    infoTextVisible,
    value,
    ...props
  }: TextAreaProps,
  ref: LegacyRef<HTMLTextAreaElement> | undefined,
) => {
  const getBorderColor = () =>
    (error && 'border-reguard-error') ||
    (valid && 'border-reguard-wintergreen') ||
    'border-reguard-slate';

  return (
    <div className="sm:mb-4">
      <label
        className={`flex justify-center select-none gap-2 flex-col-reverse ${containerClassName}`}
        htmlFor={labelHtmlFor}
      >
        <textarea
          className={`tw-cst-pf h-12 text-reguard-indigo px-4 border-2 ${getBorderColor()} focus-visible:border-reguard-violet focus:outline-none rounded w-full flex-auto
            hover:bg-reguard-pearl-tint ${
              valid
                ? 'focus-visible:border-reguard-wintergreen'
                : 'focus-visible:border-reguard-violet'
            } disabled:bg-reguard-inactive1 disabled:text-reguard-inactive2
            ${
              (error && 'placeholder:text-reguard-error') ||
              'placeholder:border-reguard-indigo'
            } hover:border-reguard-violet focus:border-reguard-violet ${className}`}
          value={value}
          {...props}
          ref={ref}
        />
        <span className={`b2 ${error ? 'text-reguard-error' : ''}`}>
          {label}
        </span>
      </label>
      {infoTextVisible && (
        <div
          className={`text-sm text-reguard-violet semibold mt-2 sm:absolute ${
            error ? 'sm:top-[6rem]' : ''
          }`}
        >
          {infoText}
        </div>
      )}
      {error && (
        <div className="text-sm text-reguard-error semibold mt-1 sm:absolute">
          {error}
        </div>
      )}
    </div>
  );
};

export const TextAreaForwardRef = forwardRef(TextArea);
