import { useTranslation } from 'react-i18next';

import LogoBlackSVG from './assets/LogoBlack.svg';

export const Unsubscribe = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col h-screen w-full bg-reguard-pearl-tint items-center justify-center">
      <span className="w-[19.375rem] h-[5rem] mb-24">
        <LogoBlackSVG data-testid="logo-black" />
      </span>
      <div className="text-3xl leading-[1.875rem] text-center max-w-[25rem] mb-24">
        <div className="pb-4">
          <span className="s1b">{t('unsubscribe_successful')}.</span>
        </div>
        <span className="s1">{t('successfully_unsubscribed')}</span>
      </div>
    </div>
  );
};
