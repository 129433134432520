import { Endpoint, endpointConstructor } from '../index.js';

export interface CaptureEndpoints {
  postOrders: Endpoint;
  getOrders: Endpoint;
}

const captureEndpoints = (): CaptureEndpoints => {
  const { endpoint } = endpointConstructor('/capture');
  return {
    postOrders: endpoint('POST', '/orders'),
    getOrders: endpoint('GET', '/orders'),
  };
};

export default captureEndpoints;
