import { en } from './en';
import { es } from './es';

export const common = {
  ns: 'common',
  resources: {
    en,
    es,
  },
};
