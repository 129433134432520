import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { NavItemType } from '@cortex/shared/models';
import { Disclosure } from '@headlessui/react';

import { MenuCategory } from './components/menu-category';
import { MobileMenuButton } from './components/mobile-menu-button';
import { MobileNav } from './components/mobile-nav';
import { Profile } from './components/profile';
import logo from './logo.svg';

import './navbar.css';

export interface NavContextValue {
  children: ReactNode;
  logoutHandler: () => void;
  navItemConfig: NavItemType[];
}

const NavContext = createContext({
  logoutHandler: () => {},
  navItemConfig: [
    {
      name: '',
      path: '',
      categories: [],
    },
  ] as NavItemType[],
});

export function NavProvider({
  children,
  navItemConfig,
  logoutHandler,
}: NavContextValue) {
  return (
    <NavContext.Provider value={{ navItemConfig, logoutHandler }}>
      {children}
    </NavContext.Provider>
  );
}

export function useNav() {
  return useContext(NavContext);
}

export const Navbar = ({
  children,
  removeChildrenStyles,
  hidden,
  overlap,
}: {
  children: ReactNode;
  removeChildrenStyles?: boolean;
  hidden?: boolean;
  overlap?: boolean;
}) => {
  const { navItemConfig, logoutHandler } = useNav();

  const [navItems, setNavItems] = useState(navItemConfig);
  const [isHidden, setIsHidden] = useState(hidden);
  const [selectedMenuCategory, setSelectedMenuCategory] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const categories = navItems[0].categories;

  useEffect(() => {
    setNavItems(navItemConfig);
  }, [navItemConfig]);

  return (
    <div
      className="min-h-full cursor-default"
      onMouseEnter={() => hidden && setIsHidden(false)}
      onMouseLeave={() => {
        if (hidden) {
          setIsHidden(true);
          setPopoverOpen(false);
        }
      }}
      onClick={() => setPopoverOpen(false)}
      role="button"
      tabIndex={0}
      onKeyDown={e => {
        if (e.key === 'Escape') {
          setPopoverOpen(false);
        }
      }}
    >
      <Disclosure
        as="nav"
        className={`bg-reguard-emerald border-b border-b-reguard-emerald-dark fixed top-0 right-0 left-0 z-10
          ${isHidden ? 'top-[-60px]' : ''}
        `}
      >
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="relative flex h-[70px] md:h-16 justify-between">
            <MobileMenuButton setOpen={setOpen} />

            <div className="flex items-center justify-center sm:items-stretch sm:justify-start gap-5">
              <div className="flex flex-shrink-0 items-center">
                <Link to="/">
                  <img
                    className="h-5 w-auto ml-6 md:ml-0"
                    src={logo}
                    alt="Reguard Admin"
                  />
                </Link>
              </div>

              <div className="hidden sm:flex items-center">
                <MenuCategory
                  categories={categories}
                  popoverOpen={popoverOpen}
                  setPopoverOpen={setPopoverOpen}
                  selectedMenuCategory={selectedMenuCategory}
                  setSelectedMenuCategory={setSelectedMenuCategory}
                />
              </div>
            </div>
            <Profile logoutHandler={logoutHandler} />
          </div>
        </div>

        <MobileNav
          categories={categories}
          open={open}
          setOpen={setOpen}
          logoutHandler={logoutHandler}
        />
      </Disclosure>
      <main className={overlap ? '' : 'mt-[85px]'}>
        <div
          className={`${
            !removeChildrenStyles &&
            'mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-8'
          }`}
        >
          {children}
        </div>
      </main>
    </div>
  );
};
