import { endpointConstructor } from '../index.js';

export type ProductEndpoints = ReturnType<typeof productEndpoints>;

const productEndpoints = () => {
  const { endpoint } = endpointConstructor('/product');
  return {
    getProductsForMerchant: endpoint('GET', '/merchant'),
  };
};

export default productEndpoints;
