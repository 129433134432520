import type { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ROUTES from '@cortex/myaccount/common/constants/routes';
import { useMyUser } from '@cortex/myaccount/network/api/users';
import Logo from '@cortex/myaccount/views/assets/Logo.svg';

interface MobileMenuProps {
  onLogout: MouseEventHandler;
  onDismiss: MouseEventHandler;
}

export const ReguardMobileMenu = ({ onLogout, onDismiss }: MobileMenuProps) => {
  const { t } = useTranslation();
  const { data: userData } = useMyUser();
  const hasShippingInsurance = userData?.planCount?.SI > 0;

  return (
    <>
      <header className="flex justify-between items-center px-6 p-4">
        <div>
          <div className="w-20">
            <Logo />
          </div>
        </div>

        <button
          onClick={onDismiss}
          className="text-2xl text-reguard-pearl font-extralight "
        >
          ×
        </button>
      </header>

      <div className="py-2 px-8 flex flex-col justify-start gap-6 text-lg">
        <div className="w-full px-2 border-reguard-pearl border-b-2">
          <Link
            to={ROUTES.HOME}
            className="text-reguard-pearl"
            onClick={onDismiss}
          >
            {t('home')}
          </Link>
        </div>

        <div className="w-full px-2 border-reguard-pearl border-b-2">
          <Link
            to={ROUTES.MY_PLANS}
            className="text-reguard-pearl"
            onClick={onDismiss}
          >
            {t('my_plans')}
          </Link>
        </div>

        <div className="w-full px-2 border-reguard-pearl border-b-2">
          <Link
            to={ROUTES.MY_CLAIMS}
            className="text-reguard-pearl"
            onClick={onDismiss}
          >
            {t('my_claims')}
          </Link>
        </div>

        <div className="w-full px-2 border-reguard-pearl border-b-2">
          <Link
            to={ROUTES.ACCOUNT}
            className="text-reguard-pearl"
            onClick={onDismiss}
          >
            {t('account_settings')}
          </Link>
        </div>

        {!hasShippingInsurance && (
          <div className="w-full px-2 border-reguard-pearl border-b-2">
            <Link to="" className="text-reguard-pearl" onClick={onLogout}>
              {t('log_out')}
            </Link>
          </div>
        )}
      </div>
    </>
  );
};
