import type { FC } from 'react';

import { FileClaimStep } from '.';

interface FileClaimFormProgressBarProps {
  step: FileClaimStep;
}
export const FileClaimFormProgressBar: FC<FileClaimFormProgressBarProps> = (
  props: FileClaimFormProgressBarProps,
) => {
  const { step } = props;
  const innerBarStyle = () => {
    switch (step) {
      case FileClaimStep.STEP_ONE:
        return 'bg-reguard-violet w-1/5';
      case FileClaimStep.STEP_TWO:
        return 'bg-reguard-violet w-2/5';
      case FileClaimStep.STEP_THREE:
        return 'bg-reguard-violet w-3/5';
      case FileClaimStep.STEP_FOUR:
        return 'bg-reguard-violet w-4/5';
      default:
        return 'bg-reguard-inactive1 w-full';
    }
  };
  return (
    <div className="mt-4 mb-8 sm:mt-8 sm:mb-12 md:mt-8 md:mb-12">
      <div className="bg-reguard-pearl-shade w-full rounded-full h-2">
        <div className={`rounded-full h-full ${innerBarStyle()}`}></div>
      </div>
    </div>
  );
};
