import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import DarkCloseSVG from './assets/DarkClose.svg';
import { RoundedButton, RoundedButtonProps } from './RoundedButton';
import { ScreenOverlay } from './ScreenOverlay';

export enum SmallDialogButtonMapType {
  DELETE,
  CANCEL,
  SAVE_DRAFT,
  DISCARD_UPDATES,
  REMOVE,
}

const buttonsMap: Record<
  SmallDialogButtonMapType,
  { kind: RoundedButtonProps['kind']; title: string }
> = {
  [SmallDialogButtonMapType.DELETE]: {
    title: 'Delete',
    kind: 'red',
  },
  [SmallDialogButtonMapType.CANCEL]: {
    title: 'Cancel',
    kind: 'white',
  },
  [SmallDialogButtonMapType.SAVE_DRAFT]: {
    title: 'Save as draft',
    kind: 'indigo',
  },
  [SmallDialogButtonMapType.DISCARD_UPDATES]: {
    title: 'Discard updates',
    kind: 'white',
  },
  [SmallDialogButtonMapType.REMOVE]: {
    title: 'Remove',
    kind: 'red',
  },
};

interface SmallDialogProps {
  title: string;
  subtitle: string;
  buttonsConfig: {
    handleClick: () => void;
    type: SmallDialogButtonMapType;
    isFetching?: boolean;
    disabled?: boolean;
  }[];
  onClose?: () => void;
  data_cy?: string;
}

export const SmallDialog: FC<SmallDialogProps> = ({
  buttonsConfig,
  title,
  subtitle,
  onClose,
  data_cy,
}) => {
  const { t } = useTranslation();

  return (
    <ScreenOverlay className="flex items-center justify-center">
      <div className="w-[20rem] h-[18.063rem] p-[2rem] bg-white rounded-lg overflow-hidden relative">
        {onClose ? (
          <div className="absolute top-0 bottom-0 right-0">
            <button type="button" className="p-4 bg-white" onClick={onClose}>
              <div className="first: fill-reguard-slate w-4 h-4">
                <DarkCloseSVG />
              </div>
            </button>
          </div>
        ) : null}
        {/* Body */}
        <div className="flex flex-col items-center justify-between">
          <div className="s1b text-center mb-4">{title}</div>
          <span className="b1b mb-[1.375rem] text-center">{subtitle}</span>
          {buttonsConfig.map((button, index) => (
            <RoundedButton
              key={button.type}
              className={`sm:static sm:mt-0 mt-2 sm:bottom-0 !max-w-[12.5rem] w-full ${
                index > 0 ? 'sm:mt-2' : ''
              }`}
              onClick={button.handleClick}
              kind={buttonsMap[button.type].kind}
              isFetching={button.isFetching}
              disabled={button.disabled}
              type="button"
              data-cy={data_cy}
            >
              {t(buttonsMap[button.type].title)}
            </RoundedButton>
          ))}
        </div>
      </div>
    </ScreenOverlay>
  );
};
