import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import ROUTES from '@cortex/myaccount/common/constants/routes';
import { useMyUser } from '@cortex/myaccount/network/api/users';

import SafeShipTextLogoDark from '../../assets/SafeShipTextLogoDark.svg';

export const SafeShipHeader = () => {
  const { t } = useTranslation();
  const { data: userData } = useMyUser();
  const hasShippingInsurance = userData?.planCount?.SI > 0;

  if (!hasShippingInsurance) return null;

  return (
    <div className="relative md:left-16">
      <div className="flex w-full items-center bg-reguard-gray-bg px-3 lg:px-6 py-7 sm:px-16 lg:pl-[6.25rem] lg:pr-[6.063rem]">
        <Link to="/public">
          <div className="hidden md:block min-w-[100px] lg:min-w-[120px]">
            <SafeShipTextLogoDark />
          </div>
        </Link>
      </div>

      <div className="flex w-full max-w-[90rem] items-center bg-reguard-gray-bg pb-10 relative left-4">
        <div className="text-base gap-12 hidden md:flex">
          <NavLink
            to={ROUTES.MY_SAFESHIP_POLICIES}
            className={({ isActive }) =>
              isActive
                ? 'text-reguard-green semibold'
                : 'text-reguard-indigo hover:underline semibold font-normal'
            }
          >
            {t('my_policies')}
          </NavLink>

          <NavLink
            to={ROUTES.MY_SAFESHIP_CLAIMS}
            className={({ isActive }) =>
              isActive
                ? 'text-reguard-green semibold'
                : 'text-reguard-indigo semibold hover:underline font-normal'
            }
          >
            {t('my_claims')}
          </NavLink>
        </div>
      </div>
    </div>
  );
};
