import React from 'react';
import _ from 'lodash';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { ValueType } from '../../../models';

interface DataItem {
  name: string;
  value: number;
}

interface PropsType {
  isHidden?: boolean;
  width?: number | string;
  height?: number | string;
  dataKey: string;
  valueType: ValueType;
  data?: Array<any>; // eslint-disable-line
}

const defaultData: DataItem[] = [
  {
    name: 'Page A',
    value: 4000,
  },
  {
    name: 'Page B',
    value: 3000,
  },
  {
    name: 'Page C',
    value: 2000,
  },
  {
    name: 'Page D',
    value: 2780,
  },
  {
    name: 'Page E',
    value: 1890,
  },
  {
    name: 'Page F',
    value: 2390,
  },
  {
    name: 'Page G',
    value: 3490,
  },
];

const colors = _.reverse([
  '#3d193f',
  '#5f1d43',
  '#7f201e',
  '#bc302b',
  '#e97260',
  '#fa878e',
  '#f6afb1',
  '#7d1966',
  '#952857',
  '#d63e55',
  '#f9ad72',
  '#fed29b',
  '#693543',
  '#964659',
  '#b16b5e',
  '#e09286',
  '#8f5759',
  '#fef3e2',
  '#7e5f3b',
  '#a08a64',
  '#c8ae79',
  '#fffefd',
  '#93341f',
  '#b9532b',
  '#dd7a3c',
  '#fdd053',
  '#fefead',
  '#1e2a53',
  '#324024',
  '#5b6628',
  '#868823',
  '#bcab2a',
  '#2a555e',
  '#3a784d',
  '#649d47',
  '#9ab951',
  '#d2d064',
  '#339a79',
  '#23c39b',
  '#82eda2',
  '#c7fff9',
  '#004988',
  '#007da8',
  '#1f8dd3',
  '#05b5cc',
  '#00c9d5',
  '#402187',
  '#3d52b9',
  '#529be2',
  '#7fb2f4',
  '#8ad5fa',
  '#6e32a0',
  '#895bb3',
  '#ab79d8',
  '#d59df5',
  '#f5c6e9',
  '#9f4390',
  '#ce5a96',
  '#190931',
  '#3b3a61',
  '#5e5684',
  '#727fa9',
  '#86a0c0',
  '#b8cedf',
]);

export function StackedBarChart({
  width = 500,
  height = 300,
  dataKey = 'name',
  data = defaultData,
  isHidden = false,
}: PropsType) {
  if (isHidden) return null;

  let keys: string[] = [];

  data.forEach(i => {
    keys.push(
      ...Object.keys(_.pickBy(i, (v: unknown, key: string) => key !== dataKey)),
    );
  });

  keys = _.uniq(keys);

  return (
    <ResponsiveContainer width={width} height={height}>
      <BarChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={dataKey} />
        <YAxis />
        <Tooltip />
        {keys.map((i, index) => (
          <Bar
            key={index}
            dataKey={i}
            stackId={dataKey}
            fill={colors[index]}
          ></Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}
