export enum CustomerClaimStatus {
  Draft = 'Draft',
  UnderReview = 'Under Review',
  Approved = 'Approved',
  InProgress = 'In Progress',
  Closed = 'Closed',
  Pending = 'Pending',
}

export enum CustomerClaimSolution {
  Part = 'Part',
  Repair = 'Repair',
  Reimbursement = 'Reimbursement',
  Replacement = 'Replacement',
  CleaningKit = 'Cleaning kit',
  Void = 'Claim voided',
  Denied = 'Claim denied',
  NoResponse = 'No response',
  Resolved = 'Claim resolved',
}

export enum CustomerClaimProductCondition {
  StructuralDamage = 'SD',
  SurfaceDamage = 'SF',
  CosmeticDamage = 'CD',
  Intermittent = 'IT',
  Accumulation = 'AC',
  PreviousModifications = 'MD',
}

export const ClaimProductConditionsValues: {
  value: CustomerClaimProductCondition;
  label: string;
}[] = [
  {
    value: CustomerClaimProductCondition.StructuralDamage,
    label: 'Product has structural damage',
  },
  {
    value: CustomerClaimProductCondition.SurfaceDamage,
    label: 'Product has surface damage',
  },
  {
    value: CustomerClaimProductCondition.CosmeticDamage,
    label: 'Product has only cosmetic damage',
  },
  {
    value: CustomerClaimProductCondition.Intermittent,
    label: 'Product only works sometimes because of damage',
  },
  {
    value: CustomerClaimProductCondition.Accumulation,
    label: 'Product has damage from accumulation',
  },
  {
    value: CustomerClaimProductCondition.PreviousModifications,
    label: 'Product has damage because of previous modification',
  },
];

export enum ClaimFileType {
  Image = 'image',
  Receipt = 'receipt',
}
