import { FC, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Tooltip,
} from 'chart.js';

import InfoSVG from './assets/Info.svg';
import InfoDarkSVG from './assets/InfoDark.svg';
import ArrowUp from './assets/ThinArrowUp.svg';
import { useScreenSize } from './hooks/screen-size';
import { Card } from './Card';
import { SimpleSelectForwardRef } from './SimpleSelect';

interface MetricsCardProps {
  trend: 'down' | 'up';
  labels: string[];
  data: { label: string; data: number[] }[];
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

export const options = {
  responsive: true,
  categoryPercentage: 1,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      display: false,
      grid: {
        display: false,
      },
    },
  },
};

const Info = ({
  text = 'Number of Protection Plans sold',
}: {
  text?: string;
}) => {
  const [hover, setHover] = useState(false);
  const { isMobile } = useScreenSize();

  return (
    <div className="relative group h-fit z-auto ml-2">
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="relative"
        onClick={() => isMobile && setHover(!hover)}
        onMouseOver={() => !isMobile && setHover(true)}
        onMouseOut={() => !isMobile && setHover(false)}
      >
        {hover ? <InfoDarkSVG /> : <InfoSVG />}
      </div>
      <div
        className={`${
          isMobile && hover ? '' : 'hidden'
        } w-full group-hover:md:flex opacity-100 group-hover:opacity-100 transition-all duration-400`}
      >
        <div className="absolute top-0 left-8 flex items-end w-[12.5rem]">
          <div className="bg-reguard-pearl w-fit max-w-72 text-reguard-onyx b3b py-2 px-4 rounded text-base">
            {text}
          </div>
        </div>
      </div>
    </div>
  );
};

const geBentColor = (trend: 'up' | 'down') => {
  if (trend === 'up') {
    return 'reguard-wintergreen-shade';
  }

  return 'reguard-orange';
};

export const MetricsCard: FC<MetricsCardProps> = ({
  trend = 'down',
  labels,
  data,
}) => {
  const [filter, setFilter] = useState({
    label: 'Trend of recent 90 days',
    value: '90 days',
  });
  const trendColor = geBentColor(trend);
  const datasets = data.map((d: { label: string; data: number[] }) => {
    if (filter.value === '30 days') {
      return { ...d, backgroundColor: ['#C5CCD6', '#C5CCD6', '#01BD94'] };
    }

    if (filter.value === '60 days') {
      return { ...d, backgroundColor: ['#C5CCD6', '#01BD94', '#01BD94'] };
    }

    return { ...d, backgroundColor: ['#01BD94', '#01BD94', '#01BD94'] };
  });

  return (
    <Card className="w-[31.25rem] flex flex-col p-6">
      <div className="flex justify-between">
        <div className="flex flex-col flex-1">
          <div className="s2 text-reguard-indigo-tint semibold">
            <div className="flex relative">
              <span className="font-normal font-proximaNovaLt">Plans sold</span>

              <Info />
            </div>{' '}
            99,999
          </div>

          <SimpleSelectForwardRef
            className="border-none b1 mt-2 shrink-0 max-w-[16rem]"
            onChange={opt => setFilter(opt)}
            value={filter}
            options={[
              { label: 'Trend of recent 30 days', value: '30 days' },
              { label: 'Trend of recent 60 days', value: '60 days' },
              { label: 'Trend of recent 90 days', value: '90 days' },
            ]}
          />
        </div>

        <div className="flex h-auto">
          <span
            className={`fill-${trendColor} mr-2 ${
              trend === 'down' ? 'rotate-180' : ''
            }`}
          >
            <ArrowUp />
          </span>
          <div className={`b1 text-${trendColor}`}>5%</div>
        </div>
      </div>

      <div className="flex flex-col flex-1 mt-10">
        <Bar
          className="flex flex-1"
          height={109}
          options={options}
          data={{ labels, datasets }}
        />
      </div>
    </Card>
  );
};
