import { endpointConstructor } from '../index.js';

export type MerchantEndpoints = ReturnType<typeof merchantEndpoints>;

const merchantEndpoints = () => {
  const { endpoint } = endpointConstructor('/merchants');
  return {
    setTrainingPortalEnabled: endpoint('POST', '/training-portal'),
    getAllMerchants: endpoint('GET', '/all'),
    getStores: endpoint('GET', '/stores'),
  };
};

export default merchantEndpoints;
