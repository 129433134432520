import { initReactI18next } from 'react-i18next';
import i18n, { i18n as i18nInstance, Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { LanguageCodes } from '../contants';
import { common } from '../resources/common';

export async function i18nInit(): Promise<i18nInstance> {
  await i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        [LanguageCodes.EN]: {
          common: common.resources.en,
        },

        [LanguageCodes.ES]: {
          common: common.resources.es,
        },
      },

      defaultNS: 'common',
      fallbackNS: 'common',
      fallbackLng: LanguageCodes.EN,
      supportedLngs: [LanguageCodes.EN, LanguageCodes.ES],
      keySeparator: '.',

      // logs to the console in dev mode when a key is missing
      saveMissing: true,
      missingKeyHandler,

      interpolation: {
        escapeValue: false,
      },
    });

  return i18n;
}

// Adds a bundle of translations to the i18n instance
export function i18nAddBundles(ns: string, resources: Resource) {
  Object.keys(resources).forEach(lng => {
    i18n.addResourceBundle(lng, ns, resources[lng], true, true);
  });
}

function missingKeyHandler(
  lngs: readonly string[],
  ns: string,
  key: string,
  fallbackValue: string,
  _updateMissing: boolean, // eslint-disable-line @typescript-eslint/no-unused-vars
  _options: object, // eslint-disable-line @typescript-eslint/no-unused-vars
) {
  if (process.env.NODE_ENV !== 'development') return;
  console.warn('missingKeyHandler', {
    lngs,
    ns,
    key,
    fallbackValue,
  });
}

export default i18n;
