import * as Sentry from '@sentry/react';
import _ from 'lodash';

import { getPreferredLanguage } from '../../common/main';
import config from '../../config';
import endpoints from '../../endpoints';

const { getAuthenticatedUser } = endpoints().authEndpoints();

const apiURL = config.api.url;

interface ClientProperties {
  data?: Record<string, unknown>;
  method?: string;
  token?: string;
  headers?: Record<string, unknown>;
  body?: string;
  customConfig?: Record<string, unknown>;
}

export enum httpCodes {
  'Unauthorized' = 401,
}

export type Client = (
  endpoint: string,
  config: ClientProperties,
) => Promise<any>;

async function client(
  endpoint: string,
  {
    data,
    method,
    token,
    headers: customHeaders,
    customConfig,
  }: ClientProperties,
) {
  const language = getPreferredLanguage({}, localStorage);

  const config = {
    method,
    body: data ? JSON.stringify(data) : undefined,
    credentials: 'include',
    headers: _.omitBy(
      {
        'Content-Type': data ? 'application/json' : undefined,
        'Authorization': token ? `Bearer ${token}` : undefined,
        'x-selected-language': language,
        ...customHeaders,
      },
      _.isNil,
    ),
    ...customConfig,
  } as RequestInit;

  // Let's log and redirect the user in the rare instance that the API URL or endpoint is missing
  // This can lead to a "Failed to Fetch" error on Sentry.
  if (!apiURL || !endpoint) {
    Sentry.withScope(function (scope) {
      scope.setTag('client-error-url', apiURL);
      scope.setTag('client-error-endpoint', endpoint);
      scope.setTag('client-error-data', JSON.stringify(data));
      scope.setLevel('error');
    });

    window.location.href = '/';
    return;
  }

  return window.fetch(`${apiURL}${endpoint}`, config).then(async response => {
    const endPointData = await response.json();

    if (response.ok || endpoint === getAuthenticatedUser.path) {
      return endPointData;
    }

    if (response.status === httpCodes.Unauthorized) {
      window.location.href = '/';
      return;
    }

    Sentry.withScope(function (scope) {
      scope.setTag('client-error-data', JSON.stringify(data));
      scope.setTag('client-error-endpoint-data', JSON.stringify(endPointData));
      scope.setLevel('error');
    });

    return Promise.reject(endPointData);
  });
}

function useClient() {
  return (endpoint: string, config: ClientProperties) =>
    client(endpoint, config);
}

export { client, useClient };
