import type { ImageType } from 'react-images-uploading';
import type { SimpleSelectOption } from '@cortex/myaccount/common/types/types';

export type ImageListExtended = ImageType[] &
  {
    file: {
      name: string;
    };
  }[];

export type FileClaimDamageDomain = SimpleSelectOption;

export interface StepOneFields {
  dateOfDamage: string;
  locationOfDamage: FileClaimDamageDomain | null;
  damageArea: FileClaimDamageDomain | null;
  causeOfDamage: FileClaimDamageDomain | null;
  kind: string;
}

export interface StepTwoFields {
  descriptionOfDamage: string;
  productCondition: string;
  kind: string;
}

export interface StepThreeFields {
  claimPhotos: ImageListExtended;
  claimReceipt: File;
  kind: string;
}

export interface StepFourFields {
  address: string;
  phoneNumber: string;
  kind: string;
}

export type FileClaimFields = StepOneFields &
  StepTwoFields &
  StepThreeFields &
  StepFourFields;

export const StepOneInitialState = {
  dateOfDamage: '',
  locationOfDamage: null,
  damageArea: null,
  causeOfDamage: null,
  kind: 'FPP',
};

export const StepTwoInitialState = {
  descriptionOfDamage: '',
  productCondition: '',
  kind: 'FPP',
};

export const StepThreeInitialState = {
  claimPhotos: [] as ImageListExtended,
  kind: 'FPP',
};

export const StepFourInitialState = {
  address: '',
  phoneNumber: '',
  kind: 'FPP',
};
