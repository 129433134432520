import dayjs from 'dayjs';
import type { TFunction } from 'i18next';
import * as yup from 'yup';

import { FileClaimStep } from '../models/FileClaimForm';

const validationSchema = (t: TFunction<'translation', undefined>) => {
  const facStepOneSchema = yup.object().shape(
    {
      dateOfDamage: yup
        .string()
        .required(t('date_of_damage_is_required') as string)
        .when('dateOfDamage', {
          is: (value: string) => value?.length,
          then: yup
            .string()
            .test(
              'required',
              t('date_of_damage_cannot_be_in_the_future') as string,
              value => {
                return dayjs(value).isBefore(new Date());
              },
            ),
        }),
      locationOfDamage: yup
        .object({
          label: yup.string(),
          value: yup.string(),
        })
        .nullable()
        .required(t('location_of_damage_is_required') as string),
      damageArea: yup
        .object({
          label: yup.string(),
          value: yup.string(),
        })
        .nullable()
        .required(t('damage_area_is_required') as string),
      causeOfDamage: yup
        .object({
          label: yup.string(),
          value: yup
            .string()
            .required(t('cause_of_damage_is_required') as string),
        })
        .nullable()
        .required(t('cause_of_damage_is_required') as string),
    },
    [['dateOfDamage', 'dateOfDamage']],
  );

  const facStepTwoSchema = yup.object().shape({
    descriptionOfDamage: yup
      .string()
      .required(t('description_of_damage_is_required') as string)
      .min(25)
      .max(5000)
      .nullable(),
    productCondition: yup
      .string()
      .required(t('product_condition_required') as string),
  });

  const facStepThreeSchema = yup.object().shape({
    claimPhotos: yup
      .array()
      .of(
        yup.object().shape({
          dataURL: yup.string(),
          file: yup.object().nullable(),
          name: yup.string(),
        }),
      )
      .required(t('claim_photo_is_required') as string)
      .min(5, t('please_upload_a_minimum_of_5_images') as string),
  });

  const facStepFourSchema = yup.object().shape({
    address: yup.string().required(t('address_is_required') as string),
    phoneNumber: yup.string().required(t('phone_number_is_required') as string),
  });

  return {
    [FileClaimStep.STEP_ONE]: facStepOneSchema,
    [FileClaimStep.STEP_TWO]: facStepTwoSchema,
    [FileClaimStep.STEP_THREE]: facStepThreeSchema,
    [FileClaimStep.STEP_FOUR]: facStepFourSchema,
  };
};

export default validationSchema;
