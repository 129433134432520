const SAFESHIP_ROUTES = {
  SAFESHIP: '/safeship',
  MY_SAFESHIP_CLAIMS: '/safeship/claims',
  MY_SAFESHIP_POLICIES: '/safeship/policies',
};

const ROUTES = {
  HOME: '/',
  ACCOUNT: '/profile',
  MY_PLANS: '/plans',
  MY_CLAIMS: '/claims',
  HELP: '/help',
  FORGOT: '/forgot-password',
  SET_EMAIL: '/set-email',
  SET_PASSWORD: '/set-password',
  NOT_FOUND: '/not-found',
  VERIFY_INFORMATION: '/verify-information',
  VERIFY_INFORMATION_FAILED: '/verify-fail',
  UNSUBSCRIBE: '/unsubscribe',
  ...SAFESHIP_ROUTES,
};

export default ROUTES;
