/* @typescript-eslint/no-non-null-assertion */
import { ComponentPropsWithoutRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { useBlockScroll } from './hooks/block-scroll';

const el = document.createElement('div');

export const ScreenOverlay = (
  props: ComponentPropsWithoutRef<'div'> & { isChatbot?: boolean },
) => {
  // lock scroll behind overlay
  useBlockScroll();

  useEffect(() => {
    const modalRoot = document.getElementById('overlay-container');
    el.className = `screen-overlay relative ${props.className || ''} ${
      props.isChatbot ? 'bac-transparent' : 'bg-reguard-indigo'
    }`;
    modalRoot && modalRoot.appendChild(el);
    return () => {
      const existingElement = document.querySelector(
        '#overlay-container .screen-overlay',
      );
      existingElement && modalRoot && modalRoot.removeChild(existingElement);
    };
  }, [props.className, props.isChatbot]);

  return ReactDOM.createPortal(props.children, el);
};
