import { Link, useLocation } from 'react-router-dom';

import { canRouteBack } from '../../constants/back-enabled-routes';
import { useCompanyContext } from '../../context/company-context';
import BackButton from '../assets/BackButton.svg';
import HelpButton from '../assets/HelpButton.svg';

export function Header(props: { url?: string }) {
  const location = useLocation();
  const { pathname: routeName } = location;
  const goBack = canRouteBack(routeName);
  const { LogoLight } = useCompanyContext();

  return (
    <div className="lg:max-w-[37.5rem] lg:mx-auto lg:pt-[8.5rem] ">
      <header className="flex justify-between items-center p-4 bg-reguard-indigo rounded-t-lg lg:max-h-[3.6rem] ">
        <div className="min-w-[72px] min-h-1">
          {goBack && <BackBtn url={props.url} />}
        </div>
        <span className="w-28">
          <LogoLight />
        </span>
        <a
          className="tw-cst-pf"
          href="https://www.reguardprotection.com/how-it-works#FAQ"
          target="_blank"
          rel="noreferrer"
        >
          <div className="tw-cst-pf HelpButton w-6 h-6">
            <HelpButton />
          </div>
        </a>
      </header>
    </div>
  );
}

function BackBtn(props: { url?: string }) {
  const url = props.url || '/';
  return (
    <Link to={url}>
      <div className="w-2.5 h-2.5 mb-2 cursor-pointer">
        <BackButton />
      </div>
    </Link>
  );
}
