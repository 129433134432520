import { useCallback, useEffect, useState } from 'react';
import throttle from 'lodash.throttle';

const useRenderOnResize = () => {
  const [value, setValue] = useState(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const forceRender = useCallback(
    throttle(() => setValue(prev => prev + 1), 100),
    [],
  );

  useEffect(() => {
    window.addEventListener('resize', forceRender);
    return () => window.removeEventListener('resize', forceRender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return null;
};

export default useRenderOnResize;
