import useRenderOnResize from './useRenderOnResize';

const useMatchBreakpoint = (
  breakpoint: string,
  limit?: 'min' | 'max',
): boolean => {
  useRenderOnResize();
  return window.matchMedia(`(${limit || 'min'}-width: ${breakpoint})`).matches;
};

export default useMatchBreakpoint;
