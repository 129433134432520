import React from 'react';

const authContext = React.createContext({});

export const useAuth = () => {
  const [authed, setAuthed] = React.useState(false);

  return {
    authed,
    setAuthed,
    login() {
      return new Promise<void>(res => {
        setAuthed(true);
        res();
      });
    },
    logout() {
      return new Promise<void>(res => {
        setAuthed(false);
        res();
      });
    },
  };
};

export default function AuthConsumer() {
  return React.useContext(authContext);
}
