import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
const notify = ({ title, options }) => {
  toast(title, {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    ...options,
  });
};

const Toast = () => {
  return <ToastContainer />;
};

export { notify, Toast };
