import { ChangeEvent, createContext, useEffect, useState } from 'react';
import type { RangeKeyDict } from 'react-date-range';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  getDateBySelectedInterval,
  getSelectionContents,
} from '../../../../utils/dates';
import { dateFromUrl } from '../../../../utils/dates';
import { intervals } from './config';
import { getMaxDate, getRangeOrMaxDate } from './date-manager';
import { setNewUrl } from './set-new-url';

export const Context = createContext({} as any); // eslint-disable-line

export const useDateRangeSelector = () => {
  // HOOKS
  const { range = '' } = useParams();

  // STATE
  const { interval: initInterval } = dateFromUrl(range);
  const [interval, setInterval] = useState<string>(initInterval || 'week');

  const toggleDateState = useState<boolean>(false);
  const toggleDateSelectorState = useState<boolean>(false);

  const [month, setMonth] = useState<number>(dayjs().month());
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const maxRange = getRangeOrMaxDate(range, interval);
  const [date, setDate] = useState([getSelectionContents(interval, maxRange)]);
  const [selectedDate, setSelectedDate] = useState<Date>(maxRange);
  const [dateRange, setDateRange] = useState<RangeKeyDict>(
    getDateBySelectedInterval(interval, selectedDate),
  );

  // EFFECTS
  useEffect(() => {
    setDateRange(getDateBySelectedInterval(interval, selectedDate));
    setNewUrl(interval, selectedDate);
  }, []); // eslint-disable-line

  const handleSetInterval = async (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
  ) => {
    const newInterval = event.target.value;

    const selected = intervals.find((i: { name: string; id: string }) => {
      return i.id === newInterval;
    });

    const currentInterval = selected?.id || 'week';

    setInterval(currentInterval);
    setMonth(dayjs().month());
    setYear(new Date().getFullYear());
    setDate([
      getSelectionContents(currentInterval, getMaxDate(currentInterval)),
    ]);
    setSelectedDate(getMaxDate(currentInterval));
    setDateRange(getDateBySelectedInterval(currentInterval, selectedDate));
  };

  // RESULTS
  return {
    controller: {
      interval,
      intervals,
      date,
      setDate,
      dateRange,
      setDateRange,
      setInterval,
      toggleDateState,
      toggleDateSelectorState,
      month,
      setMonth,
      year,
      setYear,
      handleSetInterval,
      selectedDate,
      setSelectedDate,
    },
  };
};
