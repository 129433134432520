import { components, DropdownIndicatorProps } from 'react-select';

import ArrowDownSVG from './assets/ArrowDown.svg';
import CheckCircleSVG from './assets/CheckCircle.svg';
import ErrorSignSVG from './assets/ErrorSign.svg';

const ReactSelectIndicator = ({
  error,
  valid,
  errorExclamationIconVisible,
  ...props
}: DropdownIndicatorProps & {
  error?: boolean;
  valid?: boolean;
  errorExclamationIconVisible?: boolean;
}) => {
  const handleIcon = () => {
    if (error && errorExclamationIconVisible) {
      return <ErrorSignSVG data-testid="error-sign-icon" />;
    }

    if (valid) {
      return <CheckCircleSVG data-testid="check-circle-icon" />;
    }

    return (
      <div
        data-testid="arrow-down-icon"
        className={`w-[1.125rem] h-[0.563rem] ${
          error ? 'fill-reguard-error' : 'fill-reguard-indigo'
        }`}
      >
        <ArrowDownSVG />
      </div>
    );
  };

  return (
    <components.DropdownIndicator {...props}>
      {handleIcon()}
    </components.DropdownIndicator>
  );
};

export default ReactSelectIndicator;
