import { Endpoint, endpointConstructor } from '../index.js';

export interface ContractAnomaliesEndpoints {
  getContractAnomalies: Endpoint;
  deleteContractAnomalies: Endpoint;
}

const contractAnomaliesEndpoints = (): ContractAnomaliesEndpoints => {
  const { endpoint } = endpointConstructor('/anomalies');
  return {
    getContractAnomalies: endpoint('GET'),
    deleteContractAnomalies: endpoint('DELETE', '/remove'),
  };
};

export default contractAnomaliesEndpoints;
