// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { useEffect } from 'react';

export type GTAGPageViewEvent = (options: {
  page_location: string;
  client_id?: string;
  page_title?: string;
}) => void;

export type GTAGLoginEvent = (options: {
  method?: string;
  user_id: string;
  user_email: string;
}) => void;

export interface PasswordLessLogin {
  realm: 'sms' | 'email';
  username?: string;
  verificationCode?: string;
}

export interface ContractLogin {
  username?: string;
  firstName?: string;
  lastName?: string;
}

enum GTAGChatBotClaimCategory {
  NEW_CLAIM = 'NEW_CLAIM',
  DRAFT = 'DRAFT',
}

// ChatBot Types
export type GTAGChatBotLoginEvent = (
  options: PasswordLessLogin | ContractLogin,
) => void;

export type GTAGChatBotSubmitClaimEvent = (options: {
  claimId: string;
  draftClaimId?: string;
  pcmiClaimId: string;
}) => void;

export type GTAGChatBotSaveClaimEvent = (options: {
  address: string;
  claimId: string;
  step: string;
  draftClaimId?: string;
}) => void;

export type GTAGChatBotFileAClaimEvent = GTAGFileAClaimCTAEvent;

// End of ChatBot Types

export type GTAGCustomEvent = (args: {
  name: string;
  options?: Record<string, any>;
}) => void;

export type GTAGFileAClaimCTAEvent = (args: {
  productSKU: string;
  name?: string;
}) => void;

export type UseGoogleAnalytics = (measurementId?: string) => {
  pageViewEvent: GTAGPageViewEvent;
  loginEvent: GTAGLoginEvent;
  customEvent: GTAGCustomEvent;
  fileAClaimCTAEvent: GTAGFileAClaimCTAEvent;

  // ChatBot Types
  chatBotLoginEvent: GTAGChatBotLoginEvent;
  chatBotSubmitClaimEvent: GTAGChatBotSubmitClaimEvent;
  chatBotSaveClaimEvent: GTAGChatBotSaveClaimEvent;
  chatBotFileAClaimEvent: GTAGChatBotFileAClaimEvent;
};

export const useGoogleAnalytics: UseGoogleAnalytics = (
  measurementId: string | undefined,
) => {
  useEffect(() => {
    if (!measurementId) {
      return;
    }

    const scriptId = 'gtag-script';

    if (document.getElementById(scriptId)) return;

    const { head } = document;
    const script = document.createElement('script');
    script.id = scriptId;
    script.type = 'text/javascript';
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    head.insertBefore(script, head.firstChild);

    window?.gtag('js', new Date());

    window?.gtag('config', measurementId);

    return () => {
      document.head.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.gtag]);

  const customEvent: GTAGCustomEvent = ({ name, options }) => {
    if (!window.gtag) {
      console.warn('GTAG is not loaded');
    }

    window?.gtag('event', name, options);
  };

  // Customer Portal Events
  const pageViewEvent: GTAGPageViewEvent = options => {
    customEvent({ name: 'page_view', options });
  };

  const loginEvent: GTAGLoginEvent = options => {
    customEvent({ name: 'login', options });
  };

  const fileAClaimCTAEvent: GTAGFileAClaimCTAEvent = ({ productSKU, name }) => {
    customEvent({
      name: 'file_a_claim',
      options: { item_id: productSKU, item_name: name },
    });
  };
  // End of Customer Portal Events

  // ChatBot Events
  const chatBotLoginEvent: GTAGChatBotLoginEvent = (
    options: Record<string, any>,
  ) => {
    customEvent({ name: 'login_chatBot', options });
  };

  const chatBotSubmitClaimEvent: GTAGChatBotSubmitClaimEvent = (options: {
    claimId: string;
    pcmiClaimId: string;
    draftClaimId?: string;
  }) => {
    customEvent({
      name: 'submit_claim_chatBot',
      options: {
        item_id: options.claimId,
        item_category: options.draftClaimId
          ? GTAGChatBotClaimCategory.DRAFT
          : GTAGChatBotClaimCategory.NEW_CLAIM,
        pcmi_id: options.pcmiClaimId,
      },
    });
  };

  const chatBotSaveClaimEvent: GTAGChatBotSaveClaimEvent = (options: {
    address: string;
    claimId: string;
    step: string;
    draftClaimId?: string;
  }) => {
    customEvent({
      name: 'save_claim_chatBot',
      options: {
        item_id: options.claimId,
        item_category: options.draftClaimId
          ? GTAGChatBotClaimCategory.DRAFT
          : GTAGChatBotClaimCategory.NEW_CLAIM,
        step: options.step,
        address: options.address,
      },
    });
  };

  const chatBotFileAClaimEvent: GTAGChatBotFileAClaimEvent = (options: {
    productSKU: string;
    name?: string;
  }) => {
    customEvent({
      name: 'file_a_claim_chatBot',
      options: {
        item_id: options.productSKU,
        item_name: options.name,
      },
    });
  };

  // Enf of the ChatBot Events

  return {
    pageViewEvent,
    loginEvent,
    customEvent,
    fileAClaimCTAEvent,
    chatBotLoginEvent,
    chatBotSubmitClaimEvent,
    chatBotSaveClaimEvent,
    chatBotFileAClaimEvent,
  };
};
