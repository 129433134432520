import { combineReducers, configureStore } from '@reduxjs/toolkit';

import authReducer from './slices/auth/authSlice';

const reducer = combineReducers({
  auth: authReducer,
});

export const store = configureStore({
  reducer: reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
