import { useEffect } from 'react';

export const useBlockScroll = () => {
  const [bodyElement] = document.getElementsByTagName('body');
  bodyElement.classList.add('overflow-hidden');
  useEffect(() => {
    return () => {
      bodyElement.classList.remove('overflow-hidden');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
